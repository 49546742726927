<template>
    <Popover as="template" v-slot="{ open }">
        <header :class="[
        open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
        'bg-white shadow-sm lg:static lg:overflow-y-visible border-blue-500 border-b-2',
    ]">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="
                        relative
                        flex
                        justify-between
                        xl:grid xl:grid-cols-12
                        lg:gap-8
                    ">
                    <div class="
                            flex
                            md:absolute md:left-0 md:inset-y-0
                            lg:static
                            xl:col-span-2
                        ">
                        <div class="flex items-center justify-center">
                            <router-link :to="{ path: '/' }" class="flex items-center">
                                <img class="w-3/5 my-2" src="@/assets/images/joticle-logo.png" alt="Workflow" />
                            </router-link>
                        </div>
                    </div>
                    <div
                        class="flex flex-col items-center justify-center align-middle min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                        <div class="
                                flex
                                items-center
                                justify-center
                                px-6
                                py-4
                                w-full        
                                md:max-w-3xl md:mx-auto
                                lg:max-w-none lg:mx-0
                                xl:px-0
                            ">
                            <div class="w-full">
                                <label for="search" class="sr-only">Search</label>
                                <div class="relative">
                                    <div class="
                                            pointer-events-none
                                            absolute
                                            inset-y-0
                                            left-0
                                            pl-3
                                            flex
                                            items-center
                                        ">
                                        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input id="searchClone" class="
                                            block
                                            w-full
                                            bg-white
                                            border border-gray-300
                                            rounded-md
                                            py-2
                                            pl-10
                                            pr-3
                                            text-sm
                                            placeholder-gray-500
                                            focus:outline-none
                                            focus:text-gray-900
                                            focus:placeholder-gray-400
                                            focus:ring-1
                                            focus:ring-blue-500
                                            focus:border-blue-500
                                            sm:text-sm
                                        " placeholder="Search..." />
                                    <div id="searchInput"></div>
                                    <div id="search-results-main">
                                        <div class="space-y-6">
                                            <div>
                                                <div class="text-center font-bold mb-2" id="searchResultsPodsHeading">Learning Pods</div>
                                                <div id="searchResultsPods"></div>
                                            </div>

                                            <div>
                                                <div class="text-center font-bold mb-2" id="searchResultsJotsHeading">Jots</div>
                                                <div id="searchResultsJots"></div>
                                            </div>

                                            <div
                                                id="searchPagination"
                                                class="flex align-center justify-center"
                                            ></div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="
                            flex
                            items-center
                            md:absolute md:right-0 md:inset-y-0
                            lg:hidden
                        ">
                        <!-- Mobile menu button -->
                        <PopoverButton class="
                                -mx-2
                                rounded-md
                                p-2
                                inline-flex
                                items-center
                                justify-center
                                text-gray-400
                                hover:bg-gray-100 hover:text-gray-500
                                focus:outline-none
                                focus:ring-2
                                focus:ring-inset
                                focus:ring-blue-500
                            ">
                            <span class="sr-only">Open menu</span>
                            <MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                            <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
                        </PopoverButton>
                    </div>
                    <div class="
                            hidden
                            lg:flex lg:items-center lg:justify-end
                            xl:col-span-4
                        " v-if="this.isReady">
                        <template v-if="user">
                            <router-link :to="{ path: '/jot/create' }" class="
                                    text-sm
                                    font-medium
                                    text-gray-900
                                    hover:underline
                                ">
                                Create a Jot
                            </router-link>
                            <a href="#" class="
                                    ml-5
                                    flex-shrink-0
                                    bg-white
                                    rounded-full
                                    p-1
                                    text-gray-400
                                    hover:text-gray-500
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-blue-500
                                ">
                                <span class="sr-only">View notifications</span>
                                <BellIcon class="h-6 w-6" aria-hidden="true" />
                            </a>

                            <!-- Profile dropdown -->
                            <Menu as="div" class="flex-shrink-0 relative ml-5">
                                <div>
                                    <MenuButton class="
                                            bg-white
                                            rounded-full
                                            flex
                                            focus:outline-none
                                            focus:ring-2
                                            focus:ring-offset-2
                                            focus:ring-blue-500
                                        ">
                                        <span class="sr-only">Open user menu</span>
                                        <img class="h-8 w-8 rounded-full" :src="user.avatar" alt="" />
                                    </MenuButton>
                                </div>
                                <transition enter-active-class="transition ease-out duration-100"
                                    enter-from-class="transform opacity-0 scale-95"
                                    enter-to-class="transform opacity-100 scale-100"
                                    leave-active-class="transition ease-in duration-75"
                                    leave-from-class="transform opacity-100 scale-100"
                                    leave-to-class="transform opacity-0 scale-95">
                                    <MenuItems class="
                                            origin-top-right
                                            absolute
                                            z-10
                                            right-0
                                            mt-2
                                            w-48
                                            rounded-md
                                            shadow-lg
                                            bg-white
                                            ring-1 ring-black ring-opacity-5
                                            py-1
                                            focus:outline-none
                                        ">
                                        <MenuItem v-for="item in userNavigation" :key="item.name" v-slot="{ active }">
                                        <a :href="item.href" :class="[
        active ? 'bg-gray-100' : '',
        'block py-2 px-4 text-sm text-gray-700',
    ]">{{ item.name }}</a>
                                        </MenuItem>

                                        <MenuItem v-slot="{ active }">
                                        <a href="#" :class="[
        active ? 'bg-gray-100' : '',
        'block py-2 px-4 text-sm text-gray-700',
    ]" v-on:click="logout()">
                                            Sign Out
                                        </a>
                                        </MenuItem>
                                    </MenuItems>
                                </transition>
                            </Menu>
                        </template>

                        <template v-if="!user">
                            <router-link :to="{ path: '/register' }" class="
                                    text-sm
                                    font-medium
                                    text-gray-900
                                    hover:underline
                                    pr-4
                                ">
                                Create a Jot
                            </router-link>
                            <router-link :to="{ path: '/register' }" class="
                                    text-sm
                                    font-medium
                                    text-gray-900
                                    hover:underline
                                ">
                                Create Account
                            </router-link>
                            <router-link :to="{ path: '/login' }" class="
                                    ml-6
                                    inline-flex
                                    items-center
                                    px-4
                                    py-2
                                    border border-transparent
                                    text-sm
                                    font-medium
                                    rounded-md
                                    shadow-sm
                                    text-white
                                    bg-blue-600
                                    hover:bg-blue-700
                                    focus:outline-none
                                    focus:ring-2
                                    focus:ring-offset-2
                                    focus:ring-blue-500
                                ">
                                Login
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>

            <PopoverPanel as="nav" class="lg:hidden" aria-label="Global">
                <div class="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                    <router-link v-for="item in navigation" :key="item.name"
                        :to="{ path: item.href, query: { tag: item.query } }"
                        :aria-current="item.current ? 'page' : undefined" :class="[
        item.current
            ? 'bg-gray-100 text-gray-900'
            : 'hover:bg-gray-50',
        'block rounded-md py-2 px-3 text-base font-medium',
    ]">
                        {{ item.name }}
                    </router-link>
                    <router-link v-if="!user" :to="{ path: '/login' }"
                        class="block rounded-md py-2 px-3 text-base font-medium">
                        Login
                    </router-link>
                </div>
                <div class="border-t border-gray-200 pt-4 pb-3" v-if="user">
                    <div class="max-w-3xl mx-auto px-4 flex items-center sm:px-6">
                        <div class="flex-shrink-0">
                            <img class="h-10 w-10 rounded-full" :src="user.avatar" alt="" />
                        </div>
                        <div class="ml-3">
                            <div class="text-base font-medium text-gray-800">
                                {{ user.name }}
                            </div>
                            <div class="text-sm font-medium text-gray-500">
                                {{ user.email }}
                            </div>
                        </div>
                    </div>
                    <div class="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
                        <a v-for="item in userNavigation" :key="item.name" :href="item.href" class="
                                block
                                rounded-md
                                py-2
                                px-3
                                text-base
                                font-medium
                                text-gray-500
                                hover:bg-gray-50 hover:text-gray-900
                            ">{{ item.name }}</a>
                        <a href="#" :class="[
        active ? 'bg-gray-100' : '',
        'block rounded-md py-2 px-3 text-base font-medium text-gray-500 hover:bg-gray-50 hover:text-gray-900',
    ]" v-on:click="logout()">
                            Sign Out
                        </a>
                    </div>
                </div>
            </PopoverPanel>
        </header>
    </Popover>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
} from "@headlessui/vue";
import { SearchIcon } from "@heroicons/vue/solid";
import {
    BellIcon,
    MenuIcon,
    XIcon,
    FireIcon,
    HomeIcon,
    TrendingUpIcon,
    PlusIcon,
    UserGroupIcon,
    DocumentTextIcon,
    HeartIcon,
} from "@heroicons/vue/outline";
import { GetLocalToken, Logout } from "@/services/auth.service";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
    // configure,
    searchBox,
    hits,
    pagination,
    index,
} from "instantsearch.js/es/widgets";
import { GetCurrentUser } from "@/services/user.service";
const $ = window.$;

@Options({
    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        Popover,
        PopoverButton,
        PopoverPanel,
        SearchIcon,
        MenuIcon,
        XIcon,
        BellIcon,
        FireIcon,
        HomeIcon,
        TrendingUpIcon,
        UserGroupIcon,
        PlusIcon,
        HeartIcon,
        DocumentTextIcon,
    },
    data() {
        const localToken = GetLocalToken();
        let navigation = [
            {
                name: "Home",
                href: "/",
                icon: HomeIcon,
                current: false,
            },

            // {
            //     name: "Popular",
            //     href: "/",
            //     query: "NEW",
            //     icon: FireIcon,
            //     current: false,
            // },
            {
                name: "Trending",
                href: "/",
                query: "TRENDING",
                icon: TrendingUpIcon,
                current: false,
            },
        ];
        if (localToken?.access_token) {
            navigation.splice(
                1,
                0,
                ...[
                    {
                        name: "Create Jot",
                        href: "/jot/create",
                        icon: PlusIcon,
                        current: false,
                    },
                    {
                        name: "My Jots",
                        href: "/my-jots",
                        icon: DocumentTextIcon,
                        current: false,
                    },
                ]
            );

            navigation.push(
                ...[
                    {
                        name: "Following",
                        href: "/following",
                        icon: UserGroupIcon,
                        current: false,
                    },
                    {
                        name: "Inspired Jots",
                        href: "/liked-jots",
                        icon: FireIcon,
                        current: false,
                    },
                ]
            );
        }
        return {
            user: null,
            active: false,
            userNavigation: [{ name: "Your Profile", href: "/profile" }],
            navigation,
            search: null,
            searchClone: null,
            searchInput: null,
            isReady: null,
        };
    },
    created() {
        this.fetchUser();
    },
    watch: {
        "$route.name": {
            handler: function (newValue) {
                this.routeHandler(newValue);
            },
        },
    },
    mounted() {
        this.searchResults = $("#search-results-main");
        this.searchClone = $("#searchClone");
        this.searchInput = $("#searchInput");

        this.searchClone.on("keyup", (ev) => {
            const keyword = ev.target.value;
            //if (keyword.length > 1) {
                this.searchClone.blur();
                this.$router.push("/search?key=" + ev.target.value);
                this.searchClone.val("");
            //}
        });

        this.searchInput.on("keyup", (ev) => {
            const keyword = ev.target.value;
            if (keyword.length <= 0) {
                //this.searchResults.hide();
            }else{
                //this.searchResults.show();
            }
        });

        this.routeHandler(this.$route.name);
    },
    methods: {
        routeHandler(routeName) {
            if (routeName === "Search") {
                setTimeout(() => {
                    this.searchClone.hide();
                    this.searchResults.show();
                    this.searchInput.show().find("input").focus();
                    this.initSearch();
                });
            } else {
                this.searchClone.show();
                this.searchInput.hide();
                this.searchResults.hide();
            }
        },
        fetchUser() {
            GetCurrentUser()
                .then((resp) => {
                    this.user = resp.data.attributes;
                    this.isReady = true;
                })
                .catch(() => {
                    this.isReady = true;
                });
        },
        logout() {
            Logout();

            setTimeout(() => {
                window.location.reload();
            }, 500);
        },
        initSearch() {
            const searchClient = algoliasearch(
                process.env.VUE_APP_ALGOLIA_APP_ID,
                process.env.VUE_APP_ALGOLIA_APP_KEY
            );

            this.search = instantsearch({
                //indexName: "staging_pods",
                indexName: "pods",
                searchClient,
                initialUiState: {
                    staging_pods: {
                        query: this.$route.query.key,
                        page: 1,
                    },
                },
            });

            this.search.addWidgets([
                searchBox({
                    container: "#searchInput",
                    showLoadingIndicator: true,
                    autofocus: true,
                    placeholder: "Search...",
                }),

                hits({
                    container: "#searchResultsPods",
                    templates: {
                        item: `
                    <a href="/pod/{{id}}" class="cursor-pointer">
                        {{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}
                    </a>
                    `,
                    },
                }),

                pagination({
                    container: "#searchPagination",
                }),

                index({
                    //indexName: "staging_jots",
                    indexName: "jots",
                }).addWidgets([
                    hits({
                        container: "#searchResultsJots",
                        templates: {
                            item: `
                    <a href="/jot/{{id}}" class="cursor-pointer">
                        {{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}
                    </a>
                    `,
                        },
                    }),
                ]),
            ]);

            this.search.start();
        },
    },
})
export default class Header extends Vue { }
</script>

<style>
#search-results-main{
    position:absolute;
    background:#fff;
    width:100%;
    padding-top: 10px;
    padding-bottom: 12px;
}
#searchResultsPods,#searchResultsJots{
    max-height:200px;
    overflow-y:scroll;
}
#searchResultsJots .ais-Hits--empty, #searchResultsPods .ais-Hits--empty{
    padding: 1.5rem;
}
</style>