<template>
    <TransitionRoot as="template" appear :show="this.localShow">
        <div>
            <Dialog
                as="div"
                static
                class="fixed z-30 inset-0 overflow-y-auto"
                :show="this.localShow"
                @close="closeIt()"
            >
                <div
                    class="
                        flex
                        justify-center
                        items-center
                        pt-4
                        px-4
                        pb-4
                        h-screen
                        w-screen
                        text-center
                        block
                        p-0
                        backdrop-blur
                        align-middle
                    "
                >
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay
                            class="
                                fixed
                                inset-0
                                bg-gray-500 bg-opacity-75
                                transition-opacity
                            "
                        />
                    </TransitionChild>
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div
                            class="
                                flex
                                justify-center
                                flex-col
                                items-center
                                bg-gray-300
                                rounded-lg
                                h-4/6
                                px-4
                                pt-5
                                pb-4
                                text-center
                                align-center
                                overflow-hidden
                                shadow-xl
                                transform
                                w-5/6
                                transition-all
                                sm:my-8
                                sm:align-middle
                                sm:max-w-4xl
                                sm:w-full
                                sm:p-6
                                bg-cover
                            "
                            :style="{
                                'background-image': `url(${require('@/assets/images/achievement-background.svg')})`,
                            }"
                        >
                            <div class="absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    class="
                                        rounded-md
                                        text-white
                                        hover:text-gray-500
                                        focus:outline-none
                                    "
                                    @click="this.closeIt"
                                >
                                    <span class="sr-only">Close</span>
                                    <XIcon class="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                            <img
                                :src="achievement.attributes.image"
                                :alt="achievement.attributes.title"
                                class="w-250 h-250"
                            />

                            <h3 class="text-xl font-bold text-white mt-5">
                                {{ achievement.attributes.title }}
                            </h3>

                            <span class="text-white mt-5">
                                {{
                                    type === "profile"
                                        ? achievement.attributes.description
                                        : achievement.attributes
                                              .viewer_description
                                }}
                            </span>

                            <div class="absolute top-0 right-0 pt-4 pr-4">
                                <button
                                    type="button"
                                    class="
                                        bg-white
                                        rounded-md
                                        text-gray-400
                                        hover:text-gray-500
                                        focus:outline-none
                                    "
                                >
                                    <span class="sr-only">Close</span>
                                </button>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </div>
    </TransitionRoot>
</template>

<script>
import { Vue, Options } from "vue-class-component";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { ExclamationIcon, XIcon } from "@heroicons/vue/outline";

@Options({
    props: ["achievement", "type"],
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
        XIcon,
    },
    data() {
        return {
            localShow: true,
        };
    },
    methods: {
        closeIt() {
            this.$emit("closeModal");
        },
    },
})
export default class AchievementModal extends Vue {}
</script>
