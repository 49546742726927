import { HttpClient, IHTTPDataResponse } from "@/core/http-client";
import { IListItem } from "@/interfaces/list-item.interface";

export const GetListItems = () => {
    return new Promise((resolve, reject) => {
        HttpClient()
            .get<IListItem[]>("/api/v1/list-items")
            .then((resp) => {
                resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const GetListItemById = (id: number) => {
    return HttpClient().get<IListItem>("/api/v1/list-items/" + id, {
        params: {
            include: "jots,author,container,pod,section",
        },
    });
};
