export const isUserBlocked = (authorId: any) => {
    let blockedUsers = localStorage.getItem("blockedUsers");
    blockedUsers = blockedUsers ? JSON.parse(blockedUsers) : [];
    if (blockedUsers && blockedUsers.includes(authorId)) {
        return true;
    } else {
        return false;
    }
};

export const scrollToTop = () => {
    if (typeof window !== undefined) {
        window.scrollTo(0, 0);
    }
};
