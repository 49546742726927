<template>
    <div class="min-h-screen bg-gray-100">
        <ToStoreModal v-if="showModal" :show="showModal" :appLink="appLink" />
        <Header></Header>

        <div class="py-5">
            <div
                class="
                    max-w-3xl
                    mx-auto
                    sm:px-6
                    lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8
                "
            >
                <LeftSideBar></LeftSideBar>
                <main class="lg:col-span-9 xl:col-span-6">
                    <router-view />
                </main>

                <RightSideBar></RightSideBar>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import LeftSideBar from "@/components/LeftSideBar.vue";
import RightSideBar from "@/components/RightSideBar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ToStoreModal from "@/components/ToStoreModal.vue";

@Options({
    components: {
        LeftSideBar,
        RightSideBar,
        Header,
        Footer,
        ToStoreModal,
    },
    data() {
        return {
            // showModal: isMobile,
            //appLink: isIOS
            //  ? "https://apps.apple.com/gb/app/joticle/id1590214297"
            //: "https://play.google.com/store/apps/details?id=com.digitalawesome.joticle",
        };
    },
})
export default class FeedLayout extends Vue {}
</script>
