<template>
    <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Profile
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                    This information will be displayed publicly so be careful
                    what you share.
                </p>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2 space-y-4">
                <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                            >Name</label
                        >
                        <input
                            type="text"
                            class="
                                mt-1
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border-gray-300
                                rounded-md
                            "
                            v-model="this.formData.name"
                        />
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="email-address"
                            class="block text-sm font-medium text-gray-700"
                            >Email</label
                        >
                        <input
                            type="text"
                            class="
                                mt-1
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border-gray-300
                                rounded-md
                            "
                            v-model="this.formData.email"
                        />
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                            >Phone</label
                        >
                        <input
                            type="text"
                            class="
                                mt-1
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border-gray-300
                                rounded-md
                            "
                            v-model="this.formData.phone"
                        />
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="about"
                            class="block text-sm font-medium text-gray-700"
                        >
                            About
                        </label>
                        <div class="mt-1">
                            <textarea
                                rows="3"
                                class="
                                    shadow-sm
                                    focus:ring-indigo-500
                                    focus:border-indigo-500
                                    block
                                    w-full
                                    sm:text-sm
                                    border border-gray-300
                                    rounded-md
                                "
                                placeholder="Brief description for your profile."
                                v-model="this.formData.bio"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Facebook
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <span
                            class="
                                inline-flex
                                items-center
                                px-3
                                rounded-l-md
                                border border-r-0 border-gray-300
                                bg-gray-50
                                text-gray-500 text-sm
                            "
                        >
                            https://
                        </span>
                        <input
                            type="text"
                            class="
                                focus:ring-indigo-500 focus:border-indigo-500
                                flex-1
                                block
                                w-full
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                            "
                            placeholder="www.example.com"
                            v-model="this.formData.facebook"
                        />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        LinkedIn
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <span
                            class="
                                inline-flex
                                items-center
                                px-3
                                rounded-l-md
                                border border-r-0 border-gray-300
                                bg-gray-50
                                text-gray-500 text-sm
                            "
                        >
                            https://
                        </span>
                        <input
                            type="text"
                            class="
                                focus:ring-indigo-500 focus:border-indigo-500
                                flex-1
                                block
                                w-full
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                            "
                            placeholder="www.example.com"
                            v-model="this.formData.linkedin"
                        />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Instagram
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <span
                            class="
                                inline-flex
                                items-center
                                px-3
                                rounded-l-md
                                border border-r-0 border-gray-300
                                bg-gray-50
                                text-gray-500 text-sm
                            "
                        >
                            https://
                        </span>
                        <input
                            type="text"
                            class="
                                focus:ring-indigo-500 focus:border-indigo-500
                                flex-1
                                block
                                w-full
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                            "
                            placeholder="www.example.com"
                            v-model="this.formData.instagram"
                        />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Twitter
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <span
                            class="
                                inline-flex
                                items-center
                                px-3
                                rounded-l-md
                                border border-r-0 border-gray-300
                                bg-gray-50
                                text-gray-500 text-sm
                            "
                        >
                            https://
                        </span>
                        <input
                            type="text"
                            class="
                                focus:ring-indigo-500 focus:border-indigo-500
                                flex-1
                                block
                                w-full
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                            "
                            placeholder="www.example.com"
                            v-model="this.formData.twitter"
                        />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Pinterest
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <span
                            class="
                                inline-flex
                                items-center
                                px-3
                                rounded-l-md
                                border border-r-0 border-gray-300
                                bg-gray-50
                                text-gray-500 text-sm
                            "
                        >
                            https://
                        </span>
                        <input
                            type="text"
                            class="
                                focus:ring-indigo-500 focus:border-indigo-500
                                flex-1
                                block
                                w-full
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                            "
                            placeholder="www.example.com"
                            v-model="this.formData.pinterest"
                        />
                    </div>
                    
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Storefront
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <span
                            class="
                                inline-flex
                                items-center
                                px-3
                                rounded-l-md
                                border border-r-0 border-gray-300
                                bg-gray-50
                                text-gray-500 text-sm
                            "
                        >
                            https://
                        </span>
                        <input
                            type="text"
                            class="
                                focus:ring-indigo-500 focus:border-indigo-500
                                flex-1
                                block
                                w-full
                                rounded-none rounded-r-md
                                sm:text-sm
                                border-gray-300
                            "
                            placeholder="www.example.com"
                            v-model="this.formData.storefront_url"
                        />
                    </div>
                </div>
                <div class="col-span-6 sm:col-span-3">
                    <label
                        for="company-website"
                        class="block text-sm font-medium text-gray-700"
                    >
                        Jots Level
                    </label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <select id="options" v-model="this.formData.jot_level" class="
                        focus:ring-indigo-500 focus:border-indigo-500
                        flex-1
                        block
                        w-full
                        rounded-none rounded-r-md
                        sm:text-sm
                        border-gray-300
                    ">
                            <option v-for="option in $globalVariable.jotLevels" :key="option.id" :value="option.id">
                              {{ option.title }}
                            </option>
                          </select>
                    </div>
                    <small class="text-red-600">The jots will be displayed in the system according to your selected jot level.</small>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                            <input
                                type="checkbox"
                                class="
                                    focus:ring-indigo-500
                                    h-4
                                    w-4
                                    text-indigo-600
                                    border-gray-300
                                    rounded
                                "
                                v-model="this.formData.is_private"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label
                                for="comments"
                                class="font-medium text-gray-700"
                                >Private</label
                            >
                            <p class="text-gray-500">
                                Setting profile as private will affect privacy.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                            <input
                                type="checkbox"
                                class="
                                    focus:ring-indigo-500
                                    h-4
                                    w-4
                                    text-indigo-600
                                    border-gray-300
                                    rounded
                                "
                                v-model="this.formData.is_company"
                            />
                        </div>
                        <div class="ml-3 text-sm">
                            <label
                                for="candidates"
                                class="font-medium text-gray-700"
                                >Company</label
                            >
                            <p class="text-gray-500">
                                Setting profile as company will affect some
                                features.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <button
                        class="
                            w-2/4
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            text-sm
                            font-medium
                            rounded-md
                            shadow-sm
                            text-white
                            bg-blue-600
                            hover:bg-blue-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-offset-gray-100
                            focus:ring-blue-500
                        "
                        v-on:click="this.updateUserProfile"
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UpdateUserProfile } from "@/services/user.service";
import { Vue, Options, setup } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    components: {},
    props: ["user"],
    data() {
        return {
            formData: {
                name: this.user.attributes.name,
                email: this.user.attributes.email,
                phone: this.user.attributes.phone,
                bio: this.user.attributes.bio,
                facebook: this.user.attributes.facebook,
                linkedin: this.user.attributes.linkedin,
                instagram: this.user.attributes.instagram,
                twitter: this.user.attributes.twitter,
                pinterest: this.user.attributes.pinterest,
                storefront_url: this.user.attributes.storefront_url,
                is_private: this.user.attributes.is_private,
                is_company: this.user.attributes.is_company,
                jot_level: this.user.attributes.jot_level,
            },
        };
    },
    methods: {
        updateUserProfile() {
            UpdateUserProfile(this.formData)
                .then(() => {
                    this.$emit("onUserUpdated");
                    this.toast.success("Profile updated successfully.");
                })
                .catch((err) => {
                    this.toast.error(err.message);
                });
        },
    },
})
export default class ProfileForm extends Vue {
    toast = setup(() => useToast());
}
</script>

<style></style>
