<template>
    <div class="space-y-6" v-if="this.user">
        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div
                class="md:flex md:items-center md:justify-between md:space-x-5"
            >
                <div class="flex items-start space-x-5">
                    <div class="flex-shrink-0">
                        <div class="relative">
                            <img
                                class="h-16 w-16 rounded-full"
                                :src="this.user.attributes.avatar"
                                alt="User Profile"
                            />
                            <span
                                class="
                                    absolute
                                    inset-0
                                    shadow-inner
                                    rounded-full
                                "
                                aria-hidden="true"
                            />
                        </div>
                    </div>

                    <div class="pt-1.5">
                        <h1 class="text-2xl font-bold text-gray-900">
                            {{ this.user.attributes.name }}
                        </h1>
                        <p class="text-sm font-medium text-gray-500">
                            {{ this.user.attributes.bio || "-" }}
                        </p>
                    </div>
                </div>
                <div class="text-center">
                    <label
                        class="
                            float-left 
                            px-4
                            py-2
                            border border-transparent
                            text-sm
                            font-medium
                            rounded-md
                            shadow-sm
                            text-white
                            bg-blue-600
                            hover:bg-blue-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-offset-gray-100
                            focus:ring-blue-500'
                            cursor-pointer
                        "
                    >
                        Change Avatar
                        <input
                            type="file"
                            class="sr-only"
                            accept="image/*"
                            v-on:change="this.fileChange"
                        />
                    </label>
                </div>
            </div>
        </div>

        <Achievements
            v-if="achievements.length"
            :achievements="achievements"
            type="profile"
        />

        <ProfileForm
            :user="this.user"
            @onUserUpdated="this.fetchCurrentUser()"
        />
        <PasswordForm />
    </div>
</template>

<script>
import { Options, Vue, setup } from "vue-class-component";
import ProfileForm from "@/components/ProfileForm.vue";
import PasswordForm from "@/components/PasswordForm.vue";
import { GetLocalToken } from "@/services/auth.service";
import { useToast } from "vue-toastification";
import {
    GetCurrentUser,
    UpdateUserAvatar,
    GetUserAchievements,
} from "@/services/user.service";
import Achievements from "@/components/Achievements.vue";

@Options({
    components: { ProfileForm, PasswordForm, Achievements },
    data() {
        return {
            user: null,
            achievements: [],
        };
    },
    mounted() {
        const localToken = GetLocalToken();
        if (!localToken?.access_token) {
            this.$router.push("/");
        }
        console.info("Profile is ready");
        this.fetchCurrentUser();
        this.fetchUserAchievements();
    },
    methods: {
        fetchCurrentUser() {
            GetCurrentUser()
                .then((user) => {
                    this.user = user.data;
                })
                .catch((err) => {
                    console.error("🚀 ~ err", err);
                    this.$router.push("/");
                });
        },
        fetchUserAchievements() {
            GetUserAchievements()
                .then((res) => {
                    this.achievements = res.data;
                })
                .catch((err) => {
                    console.error("🚀 ~ err", err);
                });
        },
        fileChange(ev) {
            const fileList = ev.target?.files || [];
            if (fileList[0]) {
                UpdateUserAvatar(fileList[0])
                    .then(() => {
                        this.toast.success("Avatar updated successfully.");
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    })
                    .catch((err) => {
                        this.toast.error(
                            "Avatar should be a square image with maximum dimensions of 500x500px. " +
                                err.message
                        );
                    });
            }
        },
    },
    metaInfo() {
        return {
            title: "My Profile",
        };
    },
})
export default class Profile extends Vue {
    toast = setup(() => useToast());
}
</script>
