import { GetLocalToken } from "@/services/auth.service";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppLayout from "../views/App-Layout.vue";
import FeedLayout from "../views/Feed-Layout.vue";
import MarketingWebsiteLayout from "../views/Marketing-Website-Layout.vue";
import Feed from "../views/pages/Feed.vue";
import Pod from "../views/pages/Pod.vue";
import Glossary from "../views/pages/Glossary.vue";
import Section from "../views/pages/Section.vue";
import ListItem from "../views/pages/ListItem.vue";
import Jot from "../views/pages/jots/Jot.vue";
import JotForm from "../views/pages/jots/Form.vue";
import Search from "../views/pages/Search.vue";
import Profile from "../views/pages/Profile.vue";
import Following from "../views/pages/Following.vue";
import Author from "../views/pages/Author.vue";
import LikedJots from "../views/pages/jots/Liked.vue";
import MyJots from "../views/pages/jots/MyJots.vue";
import Subscription from "../views/pages/Subscription.vue";
import Container from "../views/pages/Container.vue";
import TermsAndConditions from "../views/pages/TermsAndConditions.vue";
import PrivacyPolicy from "../views/pages/PrivacyPolicy.vue";
import Home from "../views/pages/Home.vue";
import InvestorRelations from "../views/pages/InvestorRelations.vue";
import About from "../views/pages/About.vue";
import { scrollToTop } from "@/utils";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Marketing Website",
        component: MarketingWebsiteLayout,
        meta: {
            checkAuth: false,
        },
        children: [
            {
                path: "/",
                component: Home,
                name: "HomePage",
            },
            {
                path: "/investor-relations",
                component: InvestorRelations,
                name: "InvestorRelations",
            },
            {
                path: "/about",
                component: About,
                name: "About",
            },
        ],
    },
    {
        path: "/",
        name: "Home",
        component: FeedLayout,
        meta: {
            checkAuth: false,
        },
        children: [
            {
                path: "/feeds",
                component: Feed,
                name: "Feed",
            },
            {
                path: "/pod/:id",
                component: Pod,
                name: "Pod",
            },
            {
                path: "/pod/:id/glossary",
                component: Glossary,
                name: "Glossary",
            },
            {
                path: "/section/:id",
                component: Section,
                name: "Section",
            },
            {
                path: "/list-item/:id",
                component: ListItem,
                name: "ListItem",
            },
            {
                path: "/jot/:id",
                component: Jot,
                name: "Jot",
            },
            {
                path: "/jot/create",
                component: JotForm,
                name: "JotFormCreate",
            },
            {
                path: "/jot/edit/:id",
                component: JotForm,
                name: "JotFormEdit",
            },
            {
                path: "search",
                component: Search,
                name: "Search",
            },
            {
                path: "following",
                component: Following,
                name: "Following",
            },
            {
                path: "author/:id",
                component: Author,
                name: "Author",
            },
            {
                path: "liked-jots",
                component: LikedJots,
                name: "LikedJots",
            },
            {
                path: "my-jots",
                component: MyJots,
                name: "my-jots",
            },
            {
                path: "/container/:id",
                component: Container,
                name: "Container",
            },
        ],
    },
    {
        path: "/",
        name: "App",
        component: AppLayout,
        meta: {
            checkAuth: false,
        },
        children: [
            {
                path: "/profile",
                component: Profile,
                name: "Profile",
            },
            {
                path: "/subscription",
                component: Subscription,
                name: "subscription",
            },
            {
                path: "/terms-and-conditions",
                component: TermsAndConditions,
                name: "TermsAndConditions",
            },
            {
                path: "/privacy-policy",
                component: PrivacyPolicy,
                name: "PrivacyPolicy",
            },
        ],
    },

    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/LogIn.vue"),
        meta: {
            checkAuth: true,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("@/views/auth/Register.vue"),
        meta: {
            checkAuth: true,
        },
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/auth/ForgotPassword.vue"),
        meta: {
            checkAuth: true,
        },
    },
    {
        path: "/password/reset",
        name: "Resetpassword",
        component: () => import("@/views/auth/ResetPassword.vue"),
        meta: {
            checkAuth: true,
        },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    scrollToTop();
    if (to.matched.some((record) => record.meta.checkAuth)) {
        const localToken = GetLocalToken();
        if (localToken?.access_token) {
            next({ name: "Home" });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
