<template>
    <div v-if="this.glossary">
        <div class="p-5">
            <div class="text-xl mb-4 font-bold">
                {{ this.glossary.attributes.name }}
            </div>

            <div class="mb-6 htmlPreviewClick">
                <div v-html="this.glossary.attributes.content"></div>
            </div>
            <div class="" style="display: none;">
                <a
                    target="_blank"
                    :href="this.glossary.attributes.reference_url"
                    class="
                        w-full
                        px-4
                        py-3
                        border border-transparent
                        text-sm
                        font-medium
                        rounded-md
                        shadow-sm
                        text-white
                        bg-blue-600
                        hover:bg-blue-700
                        focus:outline-none
                        text-center
                        block
                    "
                >
                    View Reference
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { GetGlossaryTermById } from "@/services/glossary-term.service";
import SubHeader from "@/components/SubHeader.vue";

const $ = window.$;

@Options({
    components: {
        SubHeader,
    },
    props: ["podId", "glossaryId"],
    data() {
        return {
            localId: null,
            localGlossaryId: null,
            glossary: null,
        };
    },
    mounted() {
        this.localId = this.podId;
        this.localGlossaryId = this.glossaryId;

        if (this.localId && this.localGlossaryId) {
            this.fetchGlossary();
        }
    },
    methods: {
        fetchGlossary() {
            GetGlossaryTermById(this.localGlossaryId).then((resp) => {
                this.glossary = resp.data;

                setTimeout(() => {
                    $(".htmlPreviewClick").on("click", "a", function (ev) {
                        ev.preventDefault();
                        const href = ev.target.href;
                        window.location.href = href;
                    });
                }, 500);
            });
        },
    },
})
export default class GlossaryDetail extends Vue {}
</script>

<style></style>
