import { HttpClient, IPaginationPayload } from "@/core/http-client";
import { IJot } from "@/interfaces/jot.interface";
import { AxiosResponse } from "axios";

/**
 * API requests
 */

export const GetJots = (pagination: IPaginationPayload = {}) => {
    return new Promise<AxiosResponse<IJot[]>>((resolve, reject) => {
        HttpClient()
            .get<IJot[]>("/api/v1/jots", {
                params: {
                    include:
                        "list_item,section,container,pod,user,section,container",
                    ...pagination,
                },
            })
            .then((resp) => {
                // resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const GetJotById = (id: number) => {
    return HttpClient().get<IJot>("/api/v1/jots/" + id, {
        params: {
            include: "list_item,section,container,pod,user,section,container",
        },
    });
};

export const GetUserJots = (pagination: IPaginationPayload = {}) => {
    return new Promise<AxiosResponse<IJot[]>>((resolve, reject) => {
        HttpClient()
            .get<IJot[]>("/api/v1/users/jots", {
                params: {
                    include:
                        "list_item,section,container,pod,user,section,container",
                    ...pagination,
                },
            })
            .then((resp) => {
                // resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const GetBanners = (pagination: IPaginationPayload = {}) => {
    return new Promise<AxiosResponse<IJot[]>>((resolve, reject) => {
        HttpClient()
            .get<IJot[]>("/api/v1/banners", {
                params: {
                    include:
                        ""
                },
            })
            .then((resp) => {
                // resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const CreateJot = (payload: any) => {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
        if (key === "images") {
            payload.images.forEach((image: File) => {
                formData.append("images[]", image);
            });
        } else {
            formData.append(key, payload[key]);
        }
    });

    return HttpClient().post("/api/v1/jots", formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });
};

export const UpdateJot = (id: number, payload: any) => {
    const formData = new FormData();

    Object.keys(payload).forEach((key) => {
        if (key === "images") {
            payload.images.forEach((image: File) => {
                formData.append("images[]", image);
            });
        } else {
            formData.append(key, payload[key]);
        }
    });

    return HttpClient().post("/api/v1/jots/" + id, formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });
};

export const LikeJot = (id: number) => {
    return HttpClient().post(`/api/v1/jots/${id}/like`, {});
};

export const UnLikeJot = (id: number) => {
    return HttpClient().post(`/api/v1/jots/${id}/unlike`, {});
};

/**
 * Helper methods
 */
export const JotTypes = [
    {
        name: "Story",
        icon: require("@/assets/images/story.svg"),
    },
    // {
    //     name: "Audio",
    //     icon: require("@/assets/images/audio.svg"),
    // },
    {
        name: "Video",
        icon: require("@/assets/images/video.svg"),
    },
];

export const UploadMedia = (formData: any) => {
    
    return HttpClient().post("/api/v1/media/create", formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });
};