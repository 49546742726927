<template>
    <div v-if="this.pod">
        <div class="bg-white mb-10 px-4 shadow sm:p-6 sm:rounded-lg">
            <SubHeader :title="this.pod.attributes.name" class="mb-6" :imgSrc="this.pod.attributes.topic_logo_image" />

            <div class="grid md:grid-cols-6"></div>
            <div class="grid md:grid-cols-3 gap-x-4">
                <div class="mb-3 col-span-1">
                    <img :src="this.pod.attributes.topic_profile_image" :alt="this.pod.attributes.name"
                        class="w-full rounded-md" />
                    <button class="
                            w-full
                            px-4
                            py-3
                            mt-4
                            border border-transparent
                            text-sm
                            font-medium
                            rounded-md
                            shadow-sm
                            text-white
                            bg-blue-600
                            hover:bg-blue-700
                            focus:outline-none
                            text-center
                            disabled:bg-grey-200 disabled:opacity-50
                            flex
                            align-center
                            justify-center
                            gap-2
                        " v-on:click="this.onSubmit()" :disabled="this.submitting">
                        <span v-if="!this.pod.attributes.is_default">Set as Default Topic</span>
                        <span v-if="this.pod.attributes.is_default">Unset Default Topic</span>

                        <div class="animate-spin w-5" v-if="this.submitting">
                            <RefreshIcon class="flip" />
                        </div>
                    </button>
                </div>
                <div class="col-span-2">
                    <BreadCrumbs :crumbs="[
                        {
                            id: this.pod.relationships.container.id,
                            title: this.pod.relationships.container
                                .attributes.name,
                            url:
                                '/container/' +
                                this.pod.relationships.container.id,
                        },
                    ]" />
                    <div class="text-3xl font-bold">
                        {{ this.pod.attributes.name }}
                    </div>
                    <div class="mb-2">
                        {{ this.pod.attributes.description }}
                    </div>

                    <div class="mb-6">
                        <router-link :to="{ path: `/pod/${this.pod.id}/glossary` }" class="
                                w-full
                                px-4
                                py-2
                                border border-transparent
                                text-sm
                                font-medium
                                rounded-md
                                shadow-sm
                                text-white
                                bg-blue-600
                                hover:bg-blue-700
                                focus:outline-none
                                text-center
                                block
                            ">
                            View
                            {{
                                this.pod.relationships.container.attributes.name
                            }}
                            Glossary
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg" v-if="pod">
            <div class="mb-6">
                <div class="text-xl sm:p-2 text-center bg-blue text-white">Sections</div>
                <div class="divide-y divide-gray-400" v-if="pod">
                    <RecursiveSectionList :sections="pod?.relationships.sections" :level="0"
                        :container="pod?.relationships.container?.attributes?.name" />
                </div>
                <div v-if="pod.relationships.length == 0" class="text-center p-6">
                    No sections available.
                </div>
            </div>
        </div>

        <div class="mb-6">
            <div class="text-xl sm:p-6">
                Latest {{ pod.attributes.name }} Jots
            </div>
            <!-- Pagination controls -->
            <div class="ais-Pagination ais-Pagination--noRefinement flex justify-center mb-5" v-if="this.jots.length">
                <ul class="ais-Pagination-list flex space-x-2">
                    <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                        <span @click="fetchPage(1)" :disabled="pagination.page === 1"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &laquo;
                        </span>
                    </li>
                    <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                        <span @click="fetchPreviousPage" :disabled="pagination.page === 1"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &lsaquo;
                        </span>
                    </li>

                    <li v-for="page in paginationPages" :key="page"
                        class="ais-Pagination-item ais-Pagination-item--page"
                        :class="page === pagination.page ? 'ais-Pagination-item--selected' : ''">
                        <a v-if="page !== '...'" @click.prevent="fetchPage(page)"
                            class="ais-Pagination-link px-2 py-1 border rounded" href="#">
                            {{ page }}
                        </a>
                        <span v-else class="ais-Pagination-link px-2 py-1">...</span>
                    </li>

                    <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                        <span @click="fetchNextPage" :disabled="pagination.page === totalPages"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &rsaquo;
                        </span>
                    </li>
                    <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                        <span @click="fetchPage(totalPages)" :disabled="pagination.page === totalPages"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &raquo;
                        </span>
                    </li>
                </ul>
                <div class="hidden">Page {{ pagination.page }} of {{ totalPages }}</div>
            </div>

            <div class="my-8 justify-center flex" v-if="this.jots_loading">
                <div class="animate-spin w-9">
                    <RefreshIcon class="flip" />
                </div>
            </div>
            <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                <div v-for="jot in jots" :key="jot.id" class="bg-white mb-4 px-4 shadow p-6 rounded-lg m-px">
                    <article :aria-labelledby="'pod-title-' + jot.id">
                        <router-link :to="{ path: '/jot/' + jot.attributes.slug }">
                            <div>
                                <div class="
                                        aspect-w-4 aspect-h-3
                                        w-full
                                        bg-no-repeat bg-center bg-cover
                                        rounded-lg
                                    " :style="`background-image: url(${jot.attributes.topic_profile_image})`"></div>
                            </div>
                            <div class="mt-2" style="min-height: 100px;">
                                <div class="text-gray-400 text-xs">
                                    {{
                                        jot.relationships.container.attributes
                                            .name
                                    }}
                                </div>
                                <div class="text-gray-500 text-xs">
                                    {{ jot.relationships.pod.attributes.name }}
                                </div>
                                <div :id="'pod-title-' + jot.id" class="text-gray-900 font-light text-sm">
                                    {{ jot.attributes.name }}
                                </div>
                            </div>
                            <div class="flex items-end justify-end tooltip" v-if="jot?.attributes?.content_type">

                                <svg v-if="jot?.attributes?.content_type === 'video'" xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="20" viewBox="0 0 90 90">
                                    <path
                                        d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0zM62.251 46.633L37.789 60.756c-1.258 0.726-2.829-0.181-2.829-1.633V30.877c0-1.452 1.572-2.36 2.829-1.634l24.461 14.123C63.508 44.092 63.508 45.907 62.251 46.633z"
                                        fill="#000" />
                                </svg>
                                <svg v-if="jot?.attributes?.content_type === 'story'" xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="20" viewBox="0 0 80 80">
                                    <path
                                        d="M77.474 17.28L61.526 1.332C60.668 0.473 59.525 0 58.311 0H15.742c-2.508 0-4.548 2.04-4.548 4.548v80.904c0 2.508 2.04 4.548 4.548 4.548h58.516c2.508 0 4.549-2.04 4.549-4.548V20.496C78.807 19.281 78.333 18.138 77.474 17.28zM61.073 5.121l12.611 12.612H62.35c-0.704 0-1.276-0.573-1.276-1.277V5.121zM15.742 3h42.332v13.456c0 2.358 1.918 4.277 4.276 4.277h13.457v33.2H14.194V4.548C14.194 3.694 14.888 3 15.742 3zm58.516 80H15.742c-0.854 0-1.548-0.694-1.548-1.548V56.934h61.613v28.519C75.807 86.306 75.112 87 74.258 87z"
                                        fill="#000" />
                                    <path
                                        d="M34.604 62.687h-8.816c-0.829 0-1.5 0.672-1.5 1.5s0.671 1.5 1.5 1.5h2.908v15.164c0 0.828 0.671 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.908c0.829 0 1.5-0.672 1.5-1.5S35.432 62.687 34.604 62.687z"
                                        fill="#000" />
                                    <path
                                        d="M46.69 72.519l4.021-7.599c0.387-0.731 0.107-1.64-0.625-2.027c-0.729-0.385-1.639-0.107-2.027 0.625l-3.065 5.794l-3.066-5.794c-0.388-0.732-1.294-1.012-2.027-0.625c-0.732 0.388-1.012 1.296-0.624 2.027l4.02 7.599l-4.02 7.599c-0.388 0.731-0.108 1.64 0.624 2.027c0.224 0.118 0.464 0.174 0.7 0.174c0.538 0 1.058-0.29 1.327-0.799l3.066-5.794l3.065 5.794c0.27 0.509 0.789 0.799 1.327 0.799c0.237 0 0.477-0.056 0.7-0.174c0.732-0.388 1.012-1.296 0.625-2.027L46.69 72.519z"
                                        fill="#000" />
                                    <path
                                        d="M64.212 62.687h-8.815c-0.828 0-1.5 0.672-1.5 1.5s0.672 1.5 1.5 1.5h2.908v15.164c0 0.828 0.672 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.907c0.828 0 1.5-0.672 1.5-1.5S65.04 62.687 64.212 62.687z"
                                        fill="#000" />
                                </svg>

                                <svg v-if="jot?.attributes?.content_type === 'audio'" xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="20" viewBox="0 0 80 80">
                                    <path
                                        d="M64.293 90c-0.493 0-0.979-0.183-1.356-0.53L35.986 64.59H6.526c-1.105 0-2-0.896-2-2V27.41c0-1.105 0.896-2 2-2h29.46L62.937 0.53c0.585-0.539 1.433-0.679 2.158-0.362C65.823 0.486 66.293 1.205 66.293 2V88c0 0.794-0.47 1.514-1.198 1.832C64.837 89.946 64.565 90 64.293 90z"
                                        fill="#000" />
                                    <path
                                        d="M76.42 73.908c-0.467 0-0.937-0.163-1.315-0.494c-0.832-0.728-0.916-1.991-0.189-2.822C78.9 66.037 81.474 55.991 81.474 45S78.9 23.963 74.915 19.408c-0.727-0.832-0.643-2.095 0.189-2.822c0.83-0.728 2.095-0.643 2.822 0.188c4.655 5.323 7.547 16.138 7.547 28.225c0 12.087-2.892 22.903-7.547 28.225C77.532 73.677 76.977 73.908 76.42 73.908z"
                                        fill="#000" />
                                    <path
                                        d="M72.02 62.623c-0.321 0-0.647-0.077-0.949-0.241c-0.972-0.525-1.333-1.739-0.808-2.71c1.851-3.421 2.955-8.906 2.955-14.672c0-5.765-1.104-11.25-2.955-14.672c-0.525-0.972-0.164-2.186 0.808-2.711c0.969-0.526 2.186-0.165 2.71 0.808c2.185 4.039 3.438 10.08 3.438 16.575c0 6.495-1.253 12.536-3.438 16.574C73.419 62.243 72.731 62.623 72.02 62.623z"
                                        fill="#000" />
                                </svg>
                                <Tooltip :content="jot?.attributes?.content_type" />
                            </div>
                        </router-link>
                    </article>
                </div>
            </div>
            <!-- Pagination controls -->
            <div class="ais-Pagination ais-Pagination--noRefinement flex justify-center" v-if="this.jots.length">
                <ul class="ais-Pagination-list flex space-x-2">
                    <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                        <span @click="fetchPage(1)" :disabled="pagination.page === 1"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &laquo;
                        </span>
                    </li>
                    <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                        <span @click="fetchPreviousPage" :disabled="pagination.page === 1"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &lsaquo;
                        </span>
                    </li>

                    <li v-for="page in paginationPages" :key="page"
                        class="ais-Pagination-item ais-Pagination-item--page"
                        :class="page === pagination.page ? 'ais-Pagination-item--selected' : ''">
                        <a v-if="page !== '...'" @click.prevent="fetchPage(page)"
                            class="ais-Pagination-link px-2 py-1 border rounded" href="#">
                            {{ page }}
                        </a>
                        <span v-else class="ais-Pagination-link px-2 py-1">...</span>
                    </li>

                    <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                        <span @click="fetchNextPage" :disabled="pagination.page === totalPages"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &rsaquo;
                        </span>
                    </li>
                    <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                        :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                        <span @click="fetchPage(totalPages)" :disabled="pagination.page === totalPages"
                            class="ais-Pagination-link px-2 py-1 border rounded">
                            &raquo;
                        </span>
                    </li>
                </ul>
                <div class="hidden">Page {{ pagination.page }} of {{ totalPages }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetPodById } from "@/services/pod.service";
import { Options, setup, Vue } from "vue-class-component";
import SubHeader from "@/components/SubHeader.vue";
import RecursiveSectionList from "@/components/RecursiveSectionList.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import { flatMap } from "lodash";
import { GetJots } from "@/services/jot.service";
import { uniqBy } from "lodash";
import { RefreshIcon } from "@heroicons/vue/solid";
import { UpdateUserDefaultPod } from "@/services/user.service";
import { useToast } from "vue-toastification";
import { isUserBlocked } from "@/utils";
import Tooltip from "@/components/Tooltip.vue";
@Options({
    components: {
        SubHeader,
        RecursiveSectionList,
        BreadCrumbs,
        RefreshIcon,
        Tooltip,
    },
    data() {
        return {
            id: this.$route.params.id,
            pod: null,
            jots: [],
            jots_loading: false,
            submitting: false,
            is_default: false,
            pagination: {
                page: 1,
                size: 12,
                totalRecords: 0,
            },
        };
    },
    mounted() {
        this.fetchPod();
    },
    methods: {
        fetchPod() {
            GetPodById(this.id)
                .then((resp) => {
                    this.pod = resp.data;
                    //console.log("pod===>", resp.data);
                    const sections = this.pod.relationships.sections;
                    //console.log("Fetched pod in pod:", this.pod);
                    const childrenSections = flatMap(
                        sections,
                        (x) => x.relationships.children
                    );

                    const filteredSections = sections.filter((x) => {
                        return (
                            childrenSections.findIndex((y) => y.id == x.id) < 0
                        );
                    });

                    this.pod.relationships.sections = filteredSections;

                    if (!this.pod.attributes.topic_logo_image) {
                        this.pod.attributes.topic_logo_image = require("@/assets/images/logo-text.svg");
                    }

                    if (!this.pod.attributes.topic_profile_image) {
                        this.pod.attributes.topic_profile_image = require("@/assets/images/learning-pod.png");
                    }

                    // Store containerid and pod id to local storage for use  in create jot
                    const jotLocal = {
                        containerId: this.pod.relationships.container.id,
                        podId: this.pod.id,
                    };
                    localStorage.setItem("jotLocal", JSON.stringify(jotLocal));

                    // chain fetch jots method
                    this.fetchJots();
                })
                .catch(() => {
                    this.$router.go(-1);
                });
        },
        fetchJots() {
            this.jots = [];
            this.jots_loading = true;
            GetJots({
                page: this.pagination.page,
                size: this.pagination.size,
                dir: "desc",
                order_by: "created_at",
                pod_id: this.pod.id,
            }).then((resp) => {
                this.jots_loading = false;
                resp.data = resp.data.filter(
                    (jot) => !isUserBlocked(jot.relationships.author.id)
                );
                this.pagination.totalRecords = resp?.options?.pagination?.total_records;
                this.jots.push(
                    ...resp.data.map((jot) => {
                        let topic_profile_image = jot.attributes.images[0]?.url;
                        if (!topic_profile_image) {
                            if (jot.attributes.content_type === "story") {
                                topic_profile_image = require("@/assets/images/jot-text.png");
                            } else if (
                                jot.attributes.content_type === "video"
                            ) {
                                topic_profile_image = require("@/assets/images/jot-video.png");
                            } else if (
                                jot.attributes.content_type === "audio"
                            ) {
                                topic_profile_image = require("@/assets/images/jot-audio.png");
                            }
                        }
                        return {
                            ...jot,
                            attributes: {
                                ...jot.attributes,
                                topic_profile_image,
                            },
                        };
                    })
                );
                this.jots = uniqBy(this.jots, (x) => x.id);
            });
        },
        onSubmit() {
            if (this.submitting) {
                return;
            }

            this.submitting = true;
            let payload = { pod_id: this.pod.id };

            let response = UpdateUserDefaultPod(payload);

            response
                .then(() => {
                    this.toast.success("Default Topic updated successfully.");
                    this.submitting = false;
                    this.fetchPod();
                })
                .catch((err) => {
                    this.toast.error(err.message);
                    this.submitting = false;
                });
        },

        async fetchPage(pageNumber) {
            if (this.pagination.page != pageNumber) {
                this.pagination.page = pageNumber;
                await this.fetchJots();
            }

        },
        async fetchPreviousPage() {
            if (this.pagination.page > 1) {
                this.pagination.page--;
                await this.fetchJots();
            }
        },
        async fetchNextPage() {
            if (this.pagination.page < this.totalPages) {
                this.pagination.page++;
                await this.fetchJots();
            }
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(
                this.pagination.totalRecords / this.pagination.size
            );
        },
        paginationPages() {
            const range = [];
            const current = this.pagination.page;
            const total = this.totalPages;

            if (total <= 7) {
                for (let i = 1; i <= total; i++) {
                    range.push(i);
                }
            } else {
                if (current <= 4) {
                    range.push(1, 2, 3, 4, 5, '...', total);
                } else if (current >= total - 3) {
                    range.push(1, '...', total - 4, total - 3, total - 2, total - 1, total);
                } else {
                    range.push(1, '...', current - 1, current, current + 1, current + 2, current + 3, '...', total);
                }
            }
            return range;
        },
    },
    metaInfo() {
        return {
            title: this.pod?.attributes.name,
            meta: [
                {
                    name: "description",
                    content: this.pod?.attributes.description,
                },
            ],
        };
    },
})
export default class Pod extends Vue {
    toast = setup(() => useToast());
}
</script>

<style></style>
