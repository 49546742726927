<template>
    <div class="min-h-screen bg-white">
        <Header></Header>

        <main>
            <router-view />
        </main>

        <FooterDark />
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import LeftSideBar from "@/components/LeftSideBar.vue";
import RightSideBar from "@/components/RightSideBar.vue";
import Header from "@/components/Header.vue";
import FooterDark from "@/components/FooterDark.vue";

@Options({
    components: {
        LeftSideBar,
        RightSideBar,
        Header,
        FooterDark,
    },
})
export default class MarketingWebsiteLayout extends Vue {}
</script>
