<template>
    <div
        :class="type === 'profile' ? 'px-10' : ''"
        class="md:flex md:items-center gap-6 justify-content-center"
        v-if="achievements.length"
    >
        <div class="md:flex">
            <h1
                :class="type === 'profile' ? 'md:-mt-5' : ''"
                class="text-xl font-bold text-gray-900"
            >
                Achievements
            </h1>
        </div>
        <div :class="type === 'profile' ? '' : 'gap-3'" class="md:flex ml-10">
            <div
                :class="type === 'profile' ? 'px-4' : ''"
                class="flex flex-col cursor-pointer"
                v-for="achievement in achievements"
                :key="achievement.id"
                @click="showAchievementDetail(achievement)"
            >
                <img
                    :class="type === 'profile' ? 'w-20 m-auto' : 'w-8'"
                    :src="achievement.attributes.image"
                    :alt="achievement.attributes.title"
                />
                <span v-if="type === 'profile'" class="m-auto">{{
                    achievement.attributes.title
                }}</span>
            </div>
        </div>
        <AchievementModal
            v-if="showAchievementModal"
            @closeModal="showAchievementDetail"
            :achievement="activeAchievement"
            :type="type"
        />
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import AchievementModal from "@/components/AchievementModal.vue";

@Options({
    props: ["achievements", "type"],
    components: { AchievementModal },
    data() {
        return {
            showAchievementModal: false,
            activeAchievement: null,
        };
    },
    methods: {
        showAchievementDetail(achievement) {
            this.activeAchievement = achievement;
            this.showAchievementModal = !this.showAchievementModal;
        },
    },
})
export default class Achievements extends Vue {}
</script>
