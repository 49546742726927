import {
    HttpClient,
    IHTTPDataResponse,
    IPaginationPayload,
} from "@/core/http-client";
import { IUser } from "@/interfaces/auth.interface";

export const GetCurrentUser = () => {
    return new Promise((resolve, reject) => {
        HttpClient()
            .get<IHTTPDataResponse<IUser>>("/api/v1/user")
            .then((resp) => {
                localStorage.setItem("loggedInUser", JSON.stringify(resp));

                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const UpdateUserAvatar = (image: File) => {
    const formData = new FormData();
    formData.append("avatar", image);
    return HttpClient().post("/api/v1/users/avatar", formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });
};

export const GetJotsByAuthor = (
    id: number,
    pagination: IPaginationPayload = {}
) => {
    return HttpClient().get(`/api/v1/users/${id}/jots`, {
        params: {
            include: "list_item,section,container,pod,user,section,container",
            ...pagination,
        },
    });
};

export const GetLikedJots = (pagination: IPaginationPayload = {}) => {
    return HttpClient().get(`/api/v1/users/likes`, {
        params: {
            include: "list_item,section,container,pod,user,section,container",
            ...pagination,
        },
    });
};

export const GetUserById = (id: number) => {
    return HttpClient().get(`/api/v1/users/${id}`);
};

export const UpdateUserProfile = (payload: any) => {
    return HttpClient().post("/api/v1/users/profile", payload);
};

export const UpdateUserPassword = (payload: any) => {
    return HttpClient().post("/api/v1/users/password", payload);
};

export const UpdateUserDefaultPod = (payload: any) => {
    return HttpClient().post("/api/v1/users/pod", payload);
};

export const FollowUser = (id: number) => {
    return HttpClient().post(`/api/v1/users/${id}/follow`, {});
};

export const UnfollowUser = (id: number) => {
    return HttpClient().post(`/api/v1/users/${id}/unfollow`, {});
};

export const GetCurrentFollows = () => {
    return HttpClient().get("/api/v1/users/follows");
};

export const GetCurrentFollowers = () => {
    return HttpClient().get("/api/v1/users/followers");
};

export const GetUserAchievements = () => {
    return HttpClient().get("/api/v1/users/achievements");
};

export const GetUserAchievementsById = (id: number) => {
    return HttpClient().get(`/api/v1/users/achievements/${id}`);
};
