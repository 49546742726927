<template>
    <div class="relative">
        <div class="cursor-pointer">
            <img src="@/assets/images/share.svg" @click="showModal = true" />
        </div>
        <div v-if="showModal" class="absolute z-50">
            <div
                class="
                    bg-white
                    rounded-lg
                    overflow-hidden
                    shadow-xl
                    transform
                    transition-all
                    sm:max-w-lg sm:w-full
                "
            >
                <div class="px-4 py-5 sm:p-6 w-96">
                    <div class="flex justify-end">
                        <button
                            @click="showModal = false"
                            class="hover:text-gray-500 focus:outline-none"
                        >
                            <img class="w-6 h-6" src="@/assets/images/x.svg" />
                        </button>
                    </div>
                    <div class="mt-0">
                        <h1 class="font-bold text-2xl">Share with</h1>
                    </div>
                    <div class="my-4 flex justify-between">
                        <div class="flex flex-col items-center">
                            <button
                                v-on:click="copyLink()"
                                class="
                                    focus:outline-none
                                    bg-gray-900
                                    hover:bg-gray-800
                                    w-12
                                    h-12
                                    rounded-full
                                    flex
                                    justify-center
                                    items-center
                                    mb-2
                                "
                            >
                                <img
                                    class="w-4 h-4"
                                    src="@/assets/images/link.svg"
                                />
                            </button>
                            <span>Copy link </span>
                        </div>
                        <div class="flex flex-col items-center">
                            <button
                                v-on:click="shareFacebook()"
                                class="
                                    focus:outline-none
                                    bg-facebook
                                    hover:bg-opacity-95
                                    w-12
                                    h-12
                                    rounded-full
                                    flex
                                    justify-center
                                    items-center
                                    mb-2
                                "
                            >
                                <img
                                    class="w-4 h-4"
                                    src="@/assets/images/facebook-icon.svg"
                                />
                            </button>
                            <span>Facebook</span>
                        </div>
                        <div class="flex flex-col items-center">
                            <button
                                v-on:click="shareLinkedIn()"
                                class="
                                    focus:outline-none
                                    bg-linkedIn
                                    hover:bg-opacity-95
                                    w-12
                                    h-12
                                    rounded-full
                                    flex
                                    justify-center
                                    items-center
                                    mb-2
                                "
                            >
                                <span class="text-white font-bold text-xl"
                                    >in</span
                                >
                            </button>
                            <span>LinkedIn</span>
                        </div>
                        <div class="flex flex-col items-center">
                            <button
                                v-on:click="shareTwitter()"
                                class="
                                    focus:outline-none
                                    bg-twitter
                                    hover:bg-opacity-95
                                    w-12
                                    h-12
                                    rounded-full
                                    flex
                                    justify-center
                                    items-center
                                    mb-2
                                "
                            >
                                <img
                                    class="w-4 h-4"
                                    src="@/assets/images/twitter-icon.svg"
                                />
                            </button>
                            <span>Twitter</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Vue, Options, setup } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    components: {},
    props: {
        url: String,
        jotTitle:String
    },
    data() {
        return {
            showModal: false,
            shareUrl: "https://example.com", // the URL you want to share
        };
    },
    methods: {
        shareFacebook() {
            const url = encodeURIComponent(window.location.href);
            const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
            window.open(facebookShareUrl, "_blank");
        },
        shareTwitter() {
            const url = encodeURIComponent(window.location.href);
            const text = encodeURIComponent(this.jotTitle); 
            // const twitterShareUrl = `https://twitter.com/intent/tweet?url=${url}&text=%0a%23microlearning%20%23permissionless%20%23lifelonglearning`;
            const hashtags = "microlearning,permissionless,lifelonglearning";
            const twitterShareUrl =`https://twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}&url=${url}`;
            window.open(twitterShareUrl, "_blank");
        },
        shareLinkedIn() {
            const url = encodeURIComponent(window.location.href);
            const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
            window.open(linkedinShareUrl, "_blank");
        },
        copyLink() {
            const currentPageLink = window.location.href;
            navigator.clipboard.writeText(currentPageLink);
            this.toast.success("Copy to clipboard");
        },
    },
})
export default class ShareModal extends Vue {
    toast = setup(() => useToast());
}
</script>
