import {
    HttpClient,
    IHTTPDataResponse,
    IPaginationPayload,
} from "@/core/http-client";
import { IPod } from "@/interfaces/pod.interface";
import { AxiosResponse } from "axios";

export const GetPods = (pagination: IPaginationPayload = {}) => {
    return new Promise<AxiosResponse<IPod[]>>((resolve, reject) => {
        HttpClient()
            .get<IPod[]>("/api/v1/pods", {
                params: {
                    include: "container",
                    ...pagination,
                },
            })
            .then((resp) => {
                resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const GetPodById = (id: number ,page: number,size: number) => {
    return HttpClient().get<IPod>("/api/v1/pods/" + id, {
        params: {
            include: "sections,container",
            page:page,
            size:size
        },
    });
};
export const GetPodRightBar = (id: number ,page: number,size: number) => {
    return HttpClient().get<IPod>("/api/v1/pods/" + id, {
        params: {
            include: "",
            page:page,
            size:size
        },
    });
};

export const GetPodsByTag = (
    tag: string,
    pagination: IPaginationPayload = {}
) => {
    return new Promise<AxiosResponse<IPod[]>>((resolve, reject) => {
        HttpClient()
            .get<IPod[]>("/api/v1/pods/tag/" + tag, {
                params: {
                    include: "container",
                    ...pagination,
                },
            })
            .then((resp) => {
                resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};
