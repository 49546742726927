<template>
    <div>
        <div class="bg-white mb-10 px-4 shadow sm:p-6 sm:rounded-lg">
            <div v-if="this.pod">
                <SubHeader
                    :title="'Glossary'"
                    class="mb-6"
                    :imgSrc="this.pod.attributes.topic_logo_image"
                />

                <BreadCrumbs
                    :crumbs="[
                        {
                            id: this.pod.relationships.container.id,
                            title: this.pod.relationships.container.attributes
                                .name,
                            url:
                                '/container/' +
                                this.pod.relationships.container.id,
                        },
                        {
                            id: this.pod.id,
                            title: this.pod.attributes.name,
                            url: '/pod/' + this.pod.id,
                        },
                    ]"
                />
            </div>
            <div class="text-3xl mb-4 font-bold">Glossary</div>

            <div>
                <div id="searchbox"></div>
                <div id="refinement-list" class="my-4 hidden"></div>
                <div class="flex gap-2 justify-center my-4">
                    <div
                        v-for="character in this.filterCharacters"
                        :key="character"
                        :class="`cursor-pointer ${
                            this.selectedCharacter === character &&
                            'font-bold text-blue-500'
                        }`"
                        v-on:click="this.selectFilterCharacter(character)"
                    >
                        {{ character }}
                    </div>
                </div>

                <div class="my-8 justify-center flex" v-if="!this.isReady">
                    <div class="animate-spin w-9">
                        <RefreshIcon class="flip" />
                    </div>
                </div>

                <div id="noResults" style="display: none">No results</div>

                <div
                    id="hits"
                    :class="`my-4 ${!this.isReady && 'invisible'} `"
                ></div>
            </div>
            <div id="pagination"></div>
        </div>
        <Modal
            :show="this.modal.show"
            :podId="this.modal.podId"
            :glossaryId="this.modal.glossaryId"
            @onClose="this.modal.show = false"
        />
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
    configure,
    searchBox,
    hits,
    refinementList,
    pagination,
} from "instantsearch.js/es/widgets";
import SubHeader from "@/components/SubHeader.vue";
import Modal from "@/components/Modal.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import { GetPodById } from "@/services/pod.service";
import { RefreshIcon } from "@heroicons/vue/solid";

const $ = window.$;
@Options({
    components: {
        SubHeader,
        Modal,
        BreadCrumbs,
        RefreshIcon,
    },
    data() {
        return {
            id: this.$route.params.id,
            modal: {
                show: false,
            },
            pod: null,
            filterCharacters: [],
            selectedCharacter: null,
            isReady: true,
        };
    },
    mounted() {
        for (let i = 65; i <= 90; i++) {
            this.filterCharacters.push(String.fromCharCode(i).toUpperCase());
        }

        const searchClient = algoliasearch(
            process.env.VUE_APP_ALGOLIA_APP_ID,
            process.env.VUE_APP_ALGOLIA_APP_KEY
        );

        const search = instantsearch({
            indexName: "glossary_terms",
            searchClient,
        });

        search.addWidgets([
            configure({
                disjunctiveFacetsRefinements: {
                    pods: [this.id],
                },
            }),

            refinementList({
                container: "#refinement-list",
                attribute: "first_letter",
                operator: "and",
                sortBy: ["name:asc"],
                limit: 40,
            }),

            searchBox({
                container: "#searchbox",
                showLoadingIndicator: true,
                autofocus: true,
                placeholder: "Search glossary terms...",
            }),

            hits({
                container: "#hits",
                templates: {
                    item: `
                    <div class="cursor-pointer" data-toggle="glossary-modal" data-id="{{id}}">
                        {{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}
                    </div>
                    `,
                },
            }),

            pagination({
                container: "#pagination",
            }),
        ]);

        search.start();

        document.addEventListener("click", (ev) => {
            if (
                ev.target.attributes["data-toggle"]?.value === "glossary-modal"
            ) {
                this.modal = {
                    show: true,
                    podId: this.id,
                    glossaryId: ev.target.attributes["data-id"]?.value,
                };
            }
        });

        this.fetchPod();
    },
    methods: {
        selectFilterCharacter(character) {
            this.isReady = false;
            this.selectedCharacter = character;

            if ($(`#refinement-list ul li`).length === 1) {
                $(`#refinement-list ul li label`).click();
            }
            setTimeout(() => {
                const input = $(
                    `#refinement-list input[value='${this.selectedCharacter}']`
                );
                if (input.length) {
                    input.closest("label").click();
                    $("#noResults").hide();
                    $("#hits").show();
                } else {
                    $("#hits").hide();
                    $("#noResults").show();
                }

                this.isReady = true;
            }, 2000);
        },
        fetchPod() {
            GetPodById(this.id)
                .then((resp) => {
                    this.pod = resp.data;

                    if (!this.pod.attributes.topic_logo_image) {
                        this.pod.attributes.topic_logo_image = require("@/assets/images/logo-text.svg");
                    }

                    if (!this.pod.attributes.topic_profile_image) {
                        this.pod.attributes.topic_profile_image = require("@/assets/images/learning-pod.png");
                    }
                })
                .catch(() => {
                    this.$router.go(-1);
                });
        },
    },
    metaInfo() {
        return {
            title: this.pod?.attributes?.name + " Glossary",
        };
    },
})
export default class Glossary extends Vue {}
</script>

<style>
.ais-SearchBox-input {
    padding-left: 50px !important;
    border-radius: 4px !important;
    border-color: rgba(212, 212, 216, var(--tw-border-opacity)) !important;
    box-shadow: none !important;
}

.ais-SearchBox-input:focus {
    border-color: #2563eb !important;
}

.ais-RefinementList-list,
#pagination {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.ais-RefinementList-checkbox,
.ais-RefinementList-count {
    display: none !important;
}

.ais-RefinementList-item--selected .ais-RefinementList-labelText {
    font-weight: 800;
}

.ais-Hits-item:hover {
    background: #2563eb;
    color: white;
    font-weight: bold;
    font-size: medium;
}

.ais-Highlight-highlighted {
    background-color: rgba(65, 180, 73, 0.7);
    color: #ffffff;
    font-style: normal;
}
</style>
