<template>
    <div class="my-8 justify-center flex" v-if="this.loading">
        <div class="animate-spin w-9">
            <RefreshIcon class="flip" />
        </div>
    </div>


    <div v-if="this.container && !this.loading">
        <div class="bg-white mb-10 px-4 shadow sm:p-6 sm:rounded-lg">
            <SubHeader :title="this.container.attributes.name" class="mb-6"
                :imgSrc="this.container.attributes.topic_logo_image" />

            <div class="text-3xl mb-2 font-bold">
                {{ this.container.attributes.name }}
            </div>
        </div>
        <!-- Pagination controls -->
        <div class="ais-Pagination ais-Pagination--noRefinement flex justify-center" v-if="this.pods.length">
            <ul class="ais-Pagination-list flex space-x-2">
                <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                    <span @click="fetchPage(1)" :disabled="pagination.page === 1"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &laquo;
                    </span>
                </li>
                <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                    <span @click="fetchPreviousPage" :disabled="pagination.page === 1"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &lsaquo;
                    </span>
                </li>

                <li v-for="page in paginationPages" :key="page" class="ais-Pagination-item ais-Pagination-item--page"
                    :class="page === pagination.page ? 'ais-Pagination-item--selected' : ''">
                    <a v-if="page !== '...'" @click.prevent="fetchPage(page)"
                        class="ais-Pagination-link px-2 py-1 border rounded" href="#">
                        {{ page }}
                    </a>
                    <span v-else class="ais-Pagination-link px-2 py-1">...</span>
                </li>

                <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                    <span @click="fetchNextPage" :disabled="pagination.page === totalPages"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &rsaquo;
                    </span>
                </li>
                <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                    <span @click="fetchPage(totalPages)" :disabled="pagination.page === totalPages"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &raquo;
                    </span>
                </li>
            </ul>
            <div class="hidden">Page {{ pagination.page }} of {{ totalPages }}</div>
        </div>


        <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-2 mt-5" v-if="this.pods.length">
            <div v-for="pod in this.pods" :key="pod.id" class="bg-white mb-4 px-4 shadow p-6 rounded-lg">
                <router-link :to="{ path: '/pod/' + pod.attributes.slug }">
                    <article :aria-labelledby="'pod-title-' + pod.id">
                        <div>
                            <div class="
                                    w-full
                                    bg-no-repeat bg-center bg-cover
                                    rounded-lg
                                    aspect-w-4 aspect-h-3
                                " :style="`background-image: url(${pod.attributes.topic_profile_image})`"></div>
                        </div>
                        <div class="mt-2">
                            <div :id="'pod-title-' + pod.id" class="text-lg text-gray-900 font-light">
                                {{ pod.attributes.name }}
                            </div>
                        </div>
                    </article>
                </router-link>
            </div>
        </div>

        <!-- Pagination controls -->
        <div class="ais-Pagination ais-Pagination--noRefinement flex justify-center" v-if="this.pods.length">
            <ul class="ais-Pagination-list flex space-x-2">
                <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                    <span @click="fetchPage(1)" :disabled="pagination.page === 1"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &laquo;
                    </span>
                </li>
                <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                    <span @click="fetchPreviousPage" :disabled="pagination.page === 1"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &lsaquo;
                    </span>
                </li>

                <li v-for="page in paginationPages" :key="page" class="ais-Pagination-item ais-Pagination-item--page"
                    :class="page === pagination.page ? 'ais-Pagination-item--selected' : ''">
                    <a v-if="page !== '...'" @click.prevent="fetchPage(page)"
                        class="ais-Pagination-link px-2 py-1 border rounded" href="#">
                        {{ page }}
                    </a>
                    <span v-else class="ais-Pagination-link px-2 py-1">...</span>
                </li>

                <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                    <span @click="fetchNextPage" :disabled="pagination.page === totalPages"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &rsaquo;
                    </span>
                </li>
                <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                    <span @click="fetchPage(totalPages)" :disabled="pagination.page === totalPages"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &raquo;
                    </span>
                </li>
            </ul>
            <div class="hidden">Page {{ pagination.page }} of {{ totalPages }}</div>
        </div>
        <div v-if="!this.pods.length" class="p-6 text-center">
            No pods available.
        </div>
    </div>

</template>

<script>
import { GetContainerById } from "@/services/container.service";
import { Options, Vue } from "vue-class-component";
import { RefreshIcon } from "@heroicons/vue/solid";
import { sortBy } from "lodash";

@Options({
    components: {
        RefreshIcon,
    },
    data() {
        return {
            id: this.$route.params.id,
            container: null,
            pods: [],
            loading: true,
            pagination: {
                page: 1,
                size: 12,
                totalRecords: 0,
            },
            videoSegment: null,
        };
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.loading = true;
            this.container = (
                await GetContainerById(this.id, this.pagination.page, this.pagination.size)
            ).data;
            this.pods = sortBy(
                this.container.relationships.pods,
                (x) => x.attributes.name
            );
            this.pods = this.pods.map((pod) => {
                if (!pod.attributes?.topic_logo_image) {
                    pod.attributes.topic_logo_image = require("@/assets/images/logo-text.svg");
                }

                if (!pod.attributes?.topic_profile_image) {
                    pod.attributes.topic_profile_image = require("@/assets/images/learning-pod.png");
                }

                return {
                    ...pod,
                };
            });
            // Store containerid and pod id to local storage for use in create jot
            const jotLocal = {
                containerId: this.container.id,
            };
            localStorage.setItem("jotLocal", JSON.stringify(jotLocal));
            this.pagination.totalRecords =
                this.container.relationships.pods_pagination.total_records;
            this.pagination.totalPages = this.container.relationships.pods_pagination.total_records;

            this.loading = false;
        },

        async fetchPage(pageNumber) {
            if (this.pagination.page != pageNumber) {
                this.pagination.page = pageNumber;
                await this.initComponent();
            }

        },
        async fetchPreviousPage() {
            if (this.pagination.page > 1) {
                this.pagination.page--;
                await this.initComponent();
            }
        },
        async fetchNextPage() {
            if (this.pagination.page < this.totalPages) {
                this.pagination.page++;
                await this.initComponent();
            }
        },
    },
    watch: {
        "$route.params.id": {
            handler(newValue, oldValue) {
                this.pagination.page = 1;
                this.id = newValue;
                this.initComponent();
            },
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(
                this.pagination.totalRecords / this.pagination.size
            );
        },
        paginationPages() {
            const range = [];
            const current = this.pagination.page;
            const total = this.totalPages;

            if (total <= 7) {
                for (let i = 1; i <= total; i++) {
                    range.push(i);
                }
            } else {
                if (current <= 4) {
                    range.push(1, 2, 3, 4, 5, '...', total);
                } else if (current >= total - 3) {
                    range.push(1, '...', total - 4, total - 3, total - 2, total - 1, total);
                } else {
                    range.push(1, '...', current - 1, current, current + 1, current + 2, current + 3, '...', total);
                }
            }
            return range;
        },
    },
    metaInfo() {
        return {
            title: this.container?.attributes.name,
            meta: [
                {
                    name: "description",
                    content: this.pods?.map((x) => x.attributes.name).join(" "),
                },
            ],
        };
    },
})
export default class Container extends Vue { }
</script>