<template>
    <div
        class="
            w-full
            flex
            justify-center
            px-6
            pt-5
            pb-6
            border-2 border-gray-300 border-dashed
            rounded-md
            relative
        "
    >
        <div
            id="dropzone"
            class="absolute w-full h-full top-0 z-10 cursor-pointer"
        ></div>
        <div class="space-y-1 text-center">
            <svg
                class="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
            >
                <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
            <div class="flex text-sm text-gray-600">
                <label
                    for="file-upload"
                    class="
                        relative
                        cursor-pointer
                        bg-white
                        rounded-md
                        font-medium
                        text-blue-600
                        hover:text-indigo-500
                        focus-within:outline-none
                    "
                >
                    <span>Upload a file</span>
                </label>
                <p class="pl-1">or drag and drop</p>
            </div>
            <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        </div>
    </div>
    <div id="previewsContainer" class="flex gap-4 my-4">
        <div
            class="dz-preview dz-file-preview"
            v-for="(image, index) in images"
            :key="image.name"
        >
            <div class="dz-details">
                <img :src="image.url" />
            </div>
            <div class="dz-img-number">
                <span class="ineditor" v-if="!editing[index]" @click="startEditing(index)">{{ image.order_column }}</span>
      <input 
        v-model="editValues[index]" 
        v-if="editing[index]" 
        :ref="'input' + index"
        @blur="endEditing(index)" 
        @keyup.enter="endEditing(index)"
        class="ineditor-input"
      >
            </div>
            <div class="data-dz-remove" v-on:click="this.remove(image)">
                <img src="@/assets/images/close.svg" alt="Close" />
            </div>
        </div>
    </div>
    <div id="previewTemp"></div>
</template>

<script>
import { Vue, Options } from "vue-class-component";
import { Dropzone } from "dropzone";
import { DeleteMedia, UpdateMediaSort } from "@/services/media.service";

@Options({
    data() {
        return {
            editing: new Array(this.images.length).fill(false),
            editValues: this.images.map(field => field.order_column)
        };
    },
    props: ["images"],
    mounted() {
        const myDropZone = new Dropzone("div#dropzone", {
            url: "file/post",
            paramName: "images[]",
            autoProcessQueue: false,
            acceptedFiles: "images/*",
            previewsContainer: "div#previewsContainer",
            previewTemplate: ` <div class="dz-preview dz-file-preview">
            <div class="dz-details">
                <img data-dz-thumbnail />
            </div>
            <div class="data-dz-remove" data-dz-remove>
                <img src="${require("@/assets/images/close.svg")}" alt="Close" />
            </div>
            </div>`,
        });

        myDropZone.on("addedfile", () => {
            this.$emit("onFileChanged", myDropZone.files);
        });

        myDropZone.on("removedfile", () => {
            this.$emit("onFileChanged", myDropZone.files);
        });
    },
    methods: {
        remove(image) {
            const index = this.images.findIndex((x) => x.id == image.id);
            DeleteMedia(image.id).then((resp) => {
                this.images.splice(index, 1);
            });
        },
        startEditing(index) {
            this.editing[index] = true;
            this.$nextTick(() => {
            const input = this.$refs['input' + index];
                if (input) {
                    input.focus();
                }
            });
        },
        endEditing(index) {
            this.editing[index] = false;
            this.images[index].order_column=this.editValues[index];
            const formData = new FormData();
            formData.append("order_column", this.editValues[index]);
            UpdateMediaSort(formData,this.images[index].id);
        }
    },
})
export default class FileUpload extends Vue {}
</script>
<style>
.dz-preview {
    position: relative;
}

.data-dz-remove {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.dz-details {
    width: 120px;
    height: 120px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dz-details img {
    min-width: 120px;
    min-height: 120px;
}
.dz-img-number {
    text-align: center;
    display: inline-block;
    width: 120px;
}
.ineditor{
    border-bottom: 1px dashed #000;
    cursor: pointer;
}
.ineditor-input {
  border: 1px solid #ccc;
  max-width: 100%;
  float: left;
}
</style>
