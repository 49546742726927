<template>
    <div
        class="grid place-items-center bg-overlay bg-no-repeat bg-cover"
        :style="{
            'background-image': `url(${require('@/assets/images/hands.png')}) `,
            height: '400px',
        }"
    >
        <div class="space-y-4 text-center text-white">
            <div class="text-6xl">Amplifying School Choice and Permissionless Education</div>
            <div class="text-xl">
                Redefining alternative education and empowering educators.
            </div>
            <div>
                <a
                    href="https://joticle.com/register"
                    target="_self"
                    class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800"
                    >Join Free!</a
                >
            </div>
        </div>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 space-y-12">
        <div class="space-y-4">
            <div class="text-center font-nunito text-3xl">PARTNERS</div>
            <div
                class="
                    border-t border-b
                    items-center
                    flex
                    justify-items-center
                    place-content-center
                    gap-8
                    flex-wrap
                "
            >
                <!-- <div>
                    <a href="#">
                        <img
                            src="@/assets/images/merriam-webster.svg"
                            alt="Merrium Webster"
                        />
                    </a>
                </div> -->
                <div>
                    <a href="#">
                        <img
                            src="@/assets/images/google-classroom.svg"
                            alt="Google Classroom"
                            class="md:w-32 w-32"
                        />
                    </a>
                </div>
                <div>
                    <a href="#">
                        <img
                            src="@/assets/images/instructure.svg"
                            alt="Instructure"
                            class="md:w-32 w-32"
                        />
                    </a>
                </div>
                <div>
                    <a href="#">
                        <img src="@/assets/images/graphy.svg" 
                        alt="Graphy" 
                        class="md:w-32 w-32"
                        />
                    </a>
                </div>

                <div>
                    <a href="https://ferskened.joticlehubs.com/">
                        <img
                            src="@/assets/images/fers.png"
                            alt="FerskenEd"
                            class="md:w-32 w-32"
                            
                        />
                    </a>
                </div>
                 <div>
                    <a href="https://www.microschoolnews.com/" target="_blank">
                        <img
                            src="@/assets/images/msn.png"
                            alt="Microschool News"
                           class="md:w-32 w-32"
                        />
                    </a>
                </div>
            </div>
        </div>

        <div class="space-y-10 md:space-y-0 md:flex gap-12 items-center">
            <div class="flex-1">
                <section>
                    <iframe
                        width="100%"
                        height="330"
                        src="https://www.youtube.com/embed/S4eyMqfIm9o"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </section>
            </div>
            <div class="flex-1 space-y-4">
                <div class="text-2xl font-bold font-nunito capitalize">
                    Welcome to Joticle!
                </div>
                <div>
                    Welcome to Joticle, where learning is as easy as a click away! Our Topic Pods are your passport to permissionless microlearning experiences. Dive into bite-sized lessons on various subjects without any barriers. Whether you're curious about cooking, coding, or creative writing, our Topic Pods empower you to learn at your own pace, anytime, anywhere. With Joticle, knowledge is at your fingertips, waiting for you to explore. Join us today and embark on a learning journey like never before!
                </div>
            </div>
        </div>

        <!--Checkpoint-->
        <div
            class="
                flex
                justify-between
                bg-no-repeat bg-cover
                rounded-xl
                py-10
                px-10
            "
            :style="{
                'background-image': `url(${require('@/assets/images/blue-bg.svg')}) `,
            }"
        >
            <div class="text-3xl font-nunito text-white">Explore Joticle</div>
            <div>
                <router-link
                    :to="{ path: '/feeds', query: { tag: 'TRENDING' } }"
                    class="
                        bg-white
                        hover:bg-gray-100
                        py-2
                        px-10
                        rounded-xl
                        text-blue-500
                    "
                    >Topic Pods</router-link
                >
                &nbsp;&nbsp;
               <a href="https://www.joticlehubs.com" class="bg-white
                        hover:bg-gray-100
                        py-2
                        px-10
                        rounded-xl
                        text-blue-500">
                  Educator Hubs
                </a>
            </div>
        </div>
        <!-- end checkpoint-->

        <div class="space-y-10 md:space-y-0 md:flex gap-12 items-center" v-for="(story, index) in stories" :key="story.id">
            <template v-if="index % 2 === 0">
                <div class="flex-1 flex justify-center">
                    <img
                        :src="story?.media?.[0].original_url"
                        alt="Story Image"
                    />
                </div>
                <div class="flex-1 space-y-4">
                    <div class="text-2xl font-bold font-nunito capitalize">
                        {{ story.title }}
                    </div>
                    <div v-html="story.content"></div>
                    <div>
                        <a
                            :href="story.button_link"
                            :target="story?.link_type === '1' ? '_blank' : '_self'"
                            class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800"
                            >{{story.button_text}}</a
                        >
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="flex-1 space-y-4">
                    <div class="text-2xl font-bold font-nunito capitalize">
                        {{ story.title }}
                    </div>
                    <div v-html="story.content"></div>
                    <div>
                        <a
                            :href="story.button_link"
                            :target="story?.link_type === '1' ? '_blank' : '_self'"
                            class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800"
                            >{{story.button_text}}</a
                        >
                    </div>
                </div>
                <div class="flex-1 flex justify-center">
                    <img
                        :src="story?.media?.[0].original_url"
                        alt="Story Image"
                    />
                </div>
            </template>
        </div>
        

    </div>

    <div class="md:flex text-center">
        <div
            class="flex-1 bg-cover grid place-items-center py-10 md:py-0"
            :style="{
                'background-image': `url(${require('@/assets/images/vr-man.png')}) `,
                'min-height': '400px',
            }"
        >
            <div class="text-white space-y-4 w-4/5">
                <div class="text-2xl font-bold font-nunito capitalize">
                    Responsible Artificial Intelligence Integration
                </div>
                <div>
                    Building upon our open API framework, we are developing a STEAM education engine designed to revolutionize lifelong learning. By integrating our proprietary CMS/LMS with advanced AI capabilities, we are able to deliver content in unprecedented ways. This initiative not only enhances educational experiences but also ensures the responsible use of artificial intelligence in shaping future learning landscapes.
                </div>

                <div>
                    <a
                        href="https://joticle.com/register/"
                        target="_self"
                        class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800"
                        >Become An Early Adopter!</a
                    >
                </div>
            </div>
        </div>
        <div
            class="flex-1 bg-cover grid place-items-center py-10 md:py-0"
            :style="{
                'background-image': `url(${require('@/assets/images/writers.png')}) `,
                'min-height': '400px',
            }"
        >
            <div class="text-white space-y-4 w-4/5">
                <div class="text-2xl font-bold font-nunito capitalize">
                    A Microlearning Journey
                </div>
                <div>
                 Embark on a microlearning journey with Joticle, where each lesson is crafted for excellence. Our platform combines expert feedback with advanced analytics to ensure high-quality content and maximize student engagement. Dive into a learning experience that’s both impactful and measurable. Join us at Joticle, where every step in education is a step towards success.
                </div>
                <div>
                    <a
                        href="https://joticle.com/register"
                        target="_self"
                        class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800"
                        >Start Your Free Journey!</a
                    >
                </div>
            </div>
        </div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 space-y-12">
        <div class="space-y-10 md:space-y-0 md:flex gap-12 items-center">
            <div class="flex-1 space-y-4">
                <div class="text-2xl font-bold font-nunito capitalize">
                    WeFunder
                </div>
                <div>
                    Whether it is bringing real-world learning experiences to
                    students, like we did with the UC Davis Codelab student
                    project, or working with teachers to enhance the strengths
                    of EdTech and weed out the pain points, Joticle is a
                    community-driven platform with a mission to reimagine
                    alternative education.
                </div>
                <div>
                    With that, we are thrilled to invite our community to invest
                    in our mission, we want to share this journey with you.
                </div>
                <div>
                    Check out our Wefunder pitch to see the details and invest:
                </div>
                <div>
                    <a
                        href="https://wefunder.com/joticle"
                        target="_self"
                        class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800"
                        >Invest In Joticle!</a
                    >
                </div>
                <div>We are just getting started!</div>
            </div>
            <div class="flex-1">
                <div
                    class="
                        border border-dashed
                        rounded-xl
                        py-4
                        md:flex
                        items-center
                        gap-12
                    "
                >
                    <div class="flex-shrink-0">
                        <img
                            src="@/assets/images/megaphone.svg"
                            alt="MegaPhone"
                            style="width: 100px"
                        />
                    </div>
                    <div class="font-lora p-10 md:pr-10">
                        We are 'testing the waters' to gauge investor interest
                        in an offering under Regulation Crowdfunding. No money
                        or other consideration is being solicited. If sent, it
                        will not be accepted. No offer to buy securities will be
                        accepted. No part of the purchase price will be received
                        until a Form C is filed and only through Wefunder's
                        platform. Any indication of interest involves no
                        obligation or commitment of any kind.
                    </div>
                </div>
            </div>
        </div>

        <!-- <div
            class="
                shadow-2xl
                p-10
                rounded-xl
                md:flex
                items-center
                gap-10
                space-y-10
                md:space-y-0
            "
        >
            <div class="md:w-2/5 flex justify-center">
                <img
                    src="@/assets/images/envelope-people.png"
                    alt="Envelope People"
                />
            </div>
            <div class="flex-grow space-y-6">
                <div class="text-2xl font-bold font-nunito capitalize">
                    Stay in the know about Joticle updates
                </div>
                <form action="#" class="space-y-4">
                    <div>
                        <input
                            type="text"
                            placeholder="Name"
                            class="reach-us-form-item"
                        />
                    </div>
                    <div>
                        <input
                            type="email"
                            placeholder="Email"
                            class="reach-us-form-item"
                        />
                    </div>
                    <div>
                        <button
                            class="
                                w-full
                                border-blue-500 border
                                rounded-xl
                                py-2
                            "
                        >
                            Get Free Email Updates
                        </button>
                    </div>
                </form>
            </div>
        </div> -->

        <div
            class="
                flex
                justify-between
                bg-no-repeat bg-cover
                rounded-xl
                py-10
                px-10
            "
            :style="{
                'background-image': `url(${require('@/assets/images/blue-bg.svg')}) `,
            }"
        >
            <div class="text-3xl font-nunito text-white">Explore Joticle</div>
            <div>
                <router-link
                    :to="{ path: '/feeds', query: { tag: 'TRENDING' } }"
                    class="
                        bg-white
                        hover:bg-gray-100
                        py-2
                        px-10
                        rounded-xl
                        text-blue-500
                    "
                    >Topic Pods</router-link
                >
                &nbsp;&nbsp;
               <a href="https://www.joticlehubs.com" class="bg-white
                        hover:bg-gray-100
                        py-2
                        px-10
                        rounded-xl
                        text-blue-500">
                  Educator Hubs
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { GetContent } from "@/services/content.service";

@Options({
    components: {},
    data() {
        return {
            pageName: 'home',
            stories: []
        };
    },
    mounted() {
        this.fetchHomeContent();
    },
    methods: {
        fetchHomeContent() {
            GetContent(this.pageName)
                .then((resp) => {
                    this.stories = resp.data;
                })
                .catch((err) => {
                    console.log(err)
                });
        },
    },
    metaInfo() {
        return {
            title: "Home",
        };
    },
})   
export default class Home extends Vue {}
</script>

<style></style>
