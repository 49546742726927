<template>
    <div class="text-xs text-gray-400 font-bold">
        <router-link
            v-for="(crumb, index) in this.crumbs"
            :key="crumb.id"
            :to="crumb.url"
        >
            {{ crumb.title }}
            <span v-if="index !== this.crumbs.length - 1"> / </span>
        </router-link>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
    props: ["crumbs"],
})
export default class BreadCrumbs extends Vue {}
</script>
