<template>
    <div class="flex items-center">
        <div class="flex-1">
            <a v-on:click="this.back" class="cursor-pointer">
                <ArrowLeftIcon class="w-8" />
            </a>
        </div>
        <div class="flex-2 flex justify-center">
            <img :src="this.localImgSrc" :alt="this.title" class="w-2/4" />
        </div>
        <div class="flex-1"></div>
    </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";
import { ArrowLeftIcon } from "@heroicons/vue/outline";

@Options({
    components: {
        ArrowLeftIcon,
    },
    props: ["title", "imgSrc", "backStep"],
    data() {
        return {
            localImgSrc: require("@/assets/images/logo-text.svg"),
        };
    },
    mounted() {
        if (this.imgSrc) {
            this.localImgSrc = this.imgSrc;
        }
    },
    methods: {
        back() {
            this.$router.go(this.backStep ? this.backStep : -1);
        },
    },
})
export default class SubHeader extends Vue {}
</script>

<style></style>
