<template>
    <div class="slider-container" style="width: 600px;"> <!-- Adjust width as per your requirement -->
       <div @click="closePopup" class="flex justify-end">
        <button class="hover:text-gray-500 focus:outline-none m-2">
            <img class="w-6 h-6" src="@/assets/images/x.svg" />
        </button>
       </div>
    <div class="slider relative h-59 overflow-hidden">
      <div :key="currentSlide" class="slide">
          <img :src="slides[currentSlide].url" :alt="slides[currentSlide].name" class="object-cover w-full h-59">
          {{ slides[currentSlide].order_column }}
      </div>
      <ul class="absolute inset-x-0 bottom-0 flex justify-center space-x-2 pb-2">
        <li v-for="(slide, index) in slides" :key="index" @click="jumpSlide(index)"
            :class="{ 'bg-gray-800': index === currentSlide, 'bg-gray-300': index !== currentSlide }"
            class="h-2 w-2 rounded-full cursor-pointer">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";

@Options({
   props: {
        slides: Array,
        selectedSlide: Number  // Define currentSlide as a prop
    },
    data() {
        return {
            currentSlide: 0
        };
    },
    mounted() {
        this.currentSlide = this.selectedSlide;
    },
    methods: {
        jumpSlide(index) {
            this.currentSlide = index;
        },
        closePopup() {
            this.$emit('closePopup', true);
        },
    },
})
export default class Modal extends Vue {}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>