<template>
    <div class="space-y-6">
        <div>
            <div class="text-xl text-center">Following</div>
        </div>

        <div class="">
            <div class="mb-6">

                <div class="mb-6">
                    <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-2">

                        <div v-for="(author, index) in this.follows" :key="author.id"
                            class="bg-white mb-4 px-4 shadow p-6 rounded-lg">
                            <div class="flex-1 space-y-4">
                                <div class="flex items-start space-x-5">
                                    <router-link class="flex items-center space-x-5" :to="{ path: `/author/${author.id}` }">
                                        <div class="flex-shrink-0">
                                            <div class="relative">
                                                <img class="h-16 w-16 rounded-full" :src="author.attributes.avatar"
                                                    alt="User author" />
                                                <span class="absolute inset-0 shadow-inner rounded-full"
                                                    aria-hidden="true" />
                                            </div>
                                        </div>
                                    </router-link>
                                    <div class="space-y-1">
                                        <router-link class="flex items-center space-x-5" :to="{ path: `/author/${author.id}` }">
                                        <h1 class="text-1xl font-bold text-gray-900">
                                            {{ author.attributes.name }}
                                        </h1>
                                    </router-link>
                                        <p class="text-sm font-medium text-gray-500">
                                            Member since
                                            {{ author.attributes.created_at }}
                                        </p>
                                        <div v-if="!author.attributes.is_private">
                                            <div class="flex gap-2 w-50">
                                                <a v-if="author.attributes.twitter" :href="'//' + author.attributes.twitter"
                                                    target="_blank">
                                                    <img src="@/assets/images/twitter.svg" alt="Twitter" class="w-6" />
                                                </a>
                                                <a v-if="author.attributes.facebook" :href="'//' + author.attributes.facebook"
                                                    target="_blank">
                                                    <img src="@/assets/images/facebook.svg" alt="Facebook" class="w-6" />
                                                </a>
                                                <a v-if="author.attributes.linkedin" :href="'//' + author.attributes.linkedin"
                                                    target="_blank">
                                                    <img src="@/assets/images/linkedin.svg" alt="Linkedin" class="w-6" />
                                                </a>
                                                <a v-if="author.attributes.pinterest" :href="'//' + author.attributes.pinterest"
                                                    target="_blank">
                                                    <img src="@/assets/images/pinterest.svg" alt="Pinterest" class="w-6" />
                                                </a>
                                                <a v-if="author.attributes.instagram" :href="'//' + author.attributes.instagram"
                                                    target="_blank">
                                                    <img src="@/assets/images/instagram.svg" alt="Instagram" class="w-6" />
                                                </a>
                                                <a v-if="author.attributes.storefront_url" :href="'//' + author.attributes.storefront_url
                                                    " target="_blank">
                                                    <img src="@/assets/images/storefront.svg" alt="Storefront" class="w-6" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="
                                            text-white
                                            bg-blue-500
                                            rounded-full
                                            py-1
                                            px-3
                                            focus:outline-none
                                        " v-on:click="this.unFollow(author, index)">
                                            <span v-if="!author.attributes.is_following">
                                                Follow</span>
                                            <span v-if="author.attributes.is_following">
                                                Unfollow</span>
                                        </button>
                                <div v-if="author.attributes.is_private" class="text-center text-2xl py-4">
                                    This account is private.
                                </div>
                                <div v-if="!author.attributes.is_private">
                                    <div class="py-2">
                                        {{ author.attributes.bio }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!this.follows.length && this.isReady"
            class="flex items-center justify-center space-y-6 flex-col p-6">
            <div>
                <img src="@/assets/images/no-followers.svg" alt="No jots" />
            </div>
            <div>Not following anyone.</div>
        </div>
    </div>
</template>

<script>
import { GetLocalToken } from "@/services/auth.service";
import { GetCurrentFollows, UnfollowUser } from "@/services/user.service";
import { Options, Vue } from "vue-class-component";

@Options({
    data() {
        return {
            follows: [],
            isReady: false,
        };
    },
    async mounted() {
        const localToken = GetLocalToken();
        if (!localToken?.access_token) {
            this.$router.push("/");
        }

        this.fetchFollows();
    },
    methods: {
        async unFollow(author, index) {
            await UnfollowUser(author.id);
            this.follows.splice(index, 1);
        },
        async fetchFollows() {
            this.follows = (await GetCurrentFollows()).data;
            this.isReady = true;
        },
    },
    metaInfo() {
        return {
            title: "Following",
        };
    },
})
export default class Following extends Vue { }
</script>
