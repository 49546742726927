<template>

    <div class="tooltip">
        <span class="tooltiptext">{{ capitalizeFirstLetter(content) }}</span>
    </div>
    
    
</template>

<script>
import { Vue, Options, setup } from "vue-class-component";

@Options({
    components: {},
    props: {
        content:String
    },
    data() {
        return {
            tooltipContent: '',
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }
    },
})
export default class Tooltip extends Vue {
}
</script>
<style>
/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
  
    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -72px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>
