<template>
    <div>Search...</div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
    mounted: () => {
        console.info("we are ready for search");
    },
    metaInfo() {
        return {
            title: "Search",
        };
    },
})
export default class Search extends Vue {}
</script>
