<template>
    <div class="flex items-center mb-6" v-if="tags.length > 0">
        <div
            v-for="tag in tags"
            :key="tag.id"
            class="
                rounded-full
                py-1
                px-6
                bg-blue-500
                text-sm text-gray-200
                mr-2
            "
        >
            {{ tag.attributes.name }}
        </div>
    </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";

@Options({
    components: {},
    props: ["tags"],
})
export default class Tags extends Vue {}
</script>

<style></style>
