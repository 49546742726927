import { HttpClient } from "@/core/http-client";

export const DeleteMedia = (id: number) => {
    return HttpClient().delete("/api/v1/media/" + id);
};

export const UpdateMediaSort = (formData: any,id: number) => {
    return HttpClient().post("/api/v1/media/" +id+'/order-column', formData, {
        headers: {
            "content-type": "multipart/form-data",
        },
    });
};