<template>
    <div v-for="section in this.localSections" :key="section.id" class="divide-y divide-gray-200">
        <div :class="`py-3  hover:text-white pl-${10 * (this.level + 1)}  ${section.expanded && 'bg-green-500 text-white'
        }   ${this.level === 0 ? 'hover:bg-greenn-100' : 'hover:bg-blue-400'
        }`">
            <template v-if="!section?.relationships?.children.length">
                <router-link :to="{
        path: `/section/${section.attributes.slug}`,
    }" class="block">
                    {{ section?.attributes?.name }}
                </router-link>
            </template>
            <div v-if="section?.relationships?.children.length" :class="`cursor-pointer`" v-on:click="() => {
        section.expanded = !section.expanded;
    }
        ">
                {{ section?.attributes?.name }}

                <div class="float-right pr-10">
                    <ChevronUpIcon class="w-6 cursor-pointer" v-if="section.expanded" />
                    <ChevronDownIcon class="w-6 cursor-pointer" v-if="!section.expanded" />
                </div>
            </div>
        </div>
        <template v-if="section?.relationships?.children.length">
            <div :class="`divide-y divide-gray-200 ${section.expanded ? 'visible' : 'hidden'
        }`">
                <RecursiveSectionList :sections="section.relationships.children" :level="this.level + 1" />
            </div>
        </template>
    </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/vue/outline";

@Options({
    components: { ChevronUpIcon, ChevronDownIcon },
    props: ["sections", "level", "container"],
    data() {
        return {
            localSections: [],
        };
    },
    watch: {
        "sections": {
            immediate: true, // This ensures the watcher is triggered immediately
            handler(newVal, oldVal) {
                this.localSections = newVal.map((section) => {
                    return {
                        ...section,
                        expanded: false,
                    };
                });
            }
        }
    },
})
export default class RecursiveSectionList extends Vue { }
</script>

<style></style>
