<template>
    <div class="space-y-6">
        <div class="text-xl text-center">My Posted Jots</div>

        <div class="grid sm:grid-cols-1 md:grid-cols-1">
            <div class="bg-white mb-4 px-4 shadow p-6 rounded-lg">
                <div class="flex flex-wrap justify-center space-y-2 sm:space-y-0 sm:space-x-4">

                    <button v-for="filter in filters" :key="filter.id" @click="applyFilter(filter.id)"
                    :class="[
                                'w-full sm:w-auto px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 hover:bg-blue-600 hover:text-white',
                                {'bg-blue-600 text-white': this.level === filter.id},
                                {'bg-gray-200': this.level != filter.id}
                            ]">
                        {{filter.title}}
                    </button>
                </div>
            </div>
        </div>    
        <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
            <div v-for="jot in personalJots" :key="jot.id" class="bg-white mb-4 px-4 shadow p-6 rounded-lg">
                <article :aria-labelledby="'pod-title-' + jot.id">
                    <router-link :to="{ path: '/jot/' + jot.attributes.slug }">
                        <div>
                            <div class="
                                    aspect-w-4 aspect-h-3
                                    w-full
                                    bg-no-repeat bg-center bg-cover
                                    rounded-lg
                                " :style="`background-image: url(${jot.attributes.topic_profile_image})`"></div>
                        </div>
                        <div class="mt-2">
                            <div class="text-gray-400 text-xs">
                                {{
                                    jot.relationships.container.attributes.name
                                }}
                            </div>
                            <div class="text-gray-500">
                                {{ jot.relationships.pod.attributes.name }}
                            </div>
                            <div :id="'pod-title-' + jot.id" class="text-sm text-gray-900 font-light">
                                {{ jot.attributes.name }}
                            </div>
                        </div>
                    </router-link>
                    <router-link :to="{ path: '/jot/edit/' + jot.id }">
                        <div class="text-sm hover:text-blue-400">Edit</div>
                    </router-link>
                </article>
            </div>
        </div>
        <!-- Pagination controls -->
        <div class="ais-Pagination ais-Pagination--noRefinement flex justify-center" v-if="this.personalJots.length">
            <ul class="ais-Pagination-list flex space-x-2">
                <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                    <span @click="fetchPage(1)" :disabled="pagination.page === 1"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &laquo;
                    </span>
                </li>
                <li class="ais-Pagination-item ais-Pagination-item--previousPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === 1 }">
                    <span @click="fetchPreviousPage" :disabled="pagination.page === 1"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &lsaquo;
                    </span>
                </li>

                <li v-for="page in paginationPages" :key="page"
                    class="ais-Pagination-item ais-Pagination-item--page"
                    :class="page === pagination.page ? 'ais-Pagination-item--selected' : ''">
                    <a v-if="page !== '...'" @click.prevent="fetchPage(page)"
                        class="ais-Pagination-link px-2 py-1 border rounded" href="#">
                        {{ page }}
                    </a>
                    <span v-else class="ais-Pagination-link px-2 py-1">...</span>
                </li>

                <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                    <span @click="fetchNextPage" :disabled="pagination.page === totalPages"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &rsaquo;
                    </span>
                </li>
                <li class="ais-Pagination-item ais-Pagination-item--nextPage"
                    :class="{ 'ais-Pagination-item--disabled': pagination.page === totalPages }">
                    <span @click="fetchPage(totalPages)" :disabled="pagination.page === totalPages"
                        class="ais-Pagination-link px-2 py-1 border rounded">
                        &raquo;
                    </span>
                </li>
            </ul>
            <div class="hidden">Page {{ pagination.page }} of {{ totalPages }}</div>
        </div>
        <div v-if="!this.personalJots.length && !this.isBusy"
            class="flex items-center justify-center space-y-6 flex-col">
            <div>
                <img src="@/assets/images/no-jots.svg" alt="No jots" />
            </div>
            <div>Feel free to create your first jot.</div>
            <div>
                <router-link :to="{ path: '/jot/create' }" class="
                        inline-flex
                        items-center
                        justify-center
                        px-4
                        py-2
                        border border-transparent
                        text-sm
                        font-medium
                        rounded-md
                        shadow-sm
                        text-white
                        bg-blue-600
                        hover:bg-blue-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-offset-gray-100
                        focus:ring-blue-500
                    ">
                    Create a Jot
                </router-link>
            </div>
        </div>

        <div class="my-8 justify-center flex" v-if="this.isBusy">
            <div class="animate-spin w-9">
                <RefreshIcon class="flip" />
            </div>
        </div>

        <div class="my-8 justify-center flex" v-if="this.hasReachedBottom && this.personalJots.length"></div>
    </div>
</template>

<script>
import { uniqBy } from "lodash";
import { Options, Vue } from "vue-class-component";
import { RefreshIcon } from "@heroicons/vue/solid";
import { GetUserJots } from "@/services/jot.service";
import { orderBy } from "lodash";

@Options({
    components: {
        RefreshIcon,
    },
    data() {
        return {
            personalJots: [],
            isBusy: false,
            hasReachedBottom: false,

            loading: true,
            pagination: {
                page: 1,
                size: 12,
                totalRecords: 0,
            },
            filters: this.$globalVariable.jotLevels,
            level:0,
        };
    },
    mounted() {
        this.fetchJots();

        // window.addEventListener("scroll", () => {
        //     if (
        //         window.innerHeight + window.scrollY >=
        //             document.body.offsetHeight &&
        //         !this.hasReachedBottom
        //     ) {
        //         this.fetchJots();
        //     }
        // });
    },
    methods: {
        async fetchJots() {
            if (this.isBusy) {
                return;
            }
            this.isBusy = true;
            this.personalJots = [];
            const jotsResults = (
                await GetUserJots({
                    page: this.pagination.page,
                    size: this.pagination.size,
                    dir: "asc",
                    order_by: "created_at",
                    level: this.level,
                })
            );
            const jots = jotsResults.data;
            this.pagination.totalRecords = jotsResults?.options?.pagination?.total_records;
            this.personalJots.push(
                ...jots.map((jot) => {
                    let topic_profile_image = jot.attributes.images[0]?.url;
                    if (!topic_profile_image) {
                        if (jot.attributes.content_type === "story") {
                            topic_profile_image = require("@/assets/images/jot-text.png");
                        } else if (jot.attributes.content_type === "video") {
                            topic_profile_image = require("@/assets/images/jot-video.png");
                        } else if (jot.attributes.content_type === "audio") {
                            topic_profile_image = require("@/assets/images/jot-audio.png");
                        }
                    }
                    return {
                        ...jot,
                        attributes: {
                            ...jot.attributes,
                            topic_profile_image,
                        },
                    };
                })
            );

            const unsortedPersonalJots = uniqBy(this.personalJots, (x) => x.id);
            this.personalJots = orderBy(
                unsortedPersonalJots,
                "attributes.created_at",
                "desc"
            );
            this.hasReachedBottom = jots.length < this.pagination.size;
            this.isBusy = false;
        },

        async fetchPage(pageNumber) {
            if (this.pagination.page != pageNumber) {
                this.pagination.page = pageNumber;
                await this.fetchJots();
            }

        },
        async fetchPreviousPage() {
            if (this.pagination.page > 1) {
                this.pagination.page--;
                await this.fetchJots();
            }
        },
        async fetchNextPage() {
            if (this.pagination.page < this.totalPages) {
                this.pagination.page++;
                await this.fetchJots();
            }
        },
        async applyFilter(filter_id) {
            this.level = filter_id;
            this.pagination.page = 1;
            await this.fetchJots();
        },
        
    },
    computed: {
        totalPages() {
            return Math.ceil(
                this.pagination.totalRecords / this.pagination.size
            );
        },
        paginationPages() {
            const range = [];
            const current = this.pagination.page;
            const total = this.totalPages;

            if (total <= 7) {
                for (let i = 1; i <= total; i++) {
                    range.push(i);
                }
            } else {
                if (current <= 4) {
                    range.push(1, 2, 3, 4, 5, '...', total);
                } else if (current >= total - 3) {
                    range.push(1, '...', total - 4, total - 3, total - 2, total - 1, total);
                } else {
                    range.push(1, '...', current - 1, current, current + 1, current + 2, current + 3, '...', total);
                }
            }
            return range;
        },
    },
    metaInfo() {
        return {
            title: "My Jots",
        };
    },
})
export default class MyJots extends Vue { }
</script>
