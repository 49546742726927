<template>
    <footer class="bg-white mt-10">
        <div
            class="
                max-w-7xl
                mx-auto
                py-12
                px-4
                sm:px-6
                md:flex md:items-center md:justify-between
                lg:px-8
            "
        >
            <div class="flex justify-center space-x-6 md:order-2">
                <router-link
                    v-for="item in this.navigation"
                    :key="item.name"
                    :to="{ path: item.href }"
                    class="text-gray-400 hover:text-gray-500"
                >
                    {{ item.name }}
                </router-link>
            </div>
            <div class="mt-8 md:mt-0 md:order-1">
                <p class="text-center text-base text-gray-400">
                    &copy; {{ new Date().getFullYear() }} Joticle.com. All
                    rights reserved.
                </p>
            </div>
        </div>
    </footer>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
    data() {
        return {
            navigation: [
                {
                    name: "Terms and Conditions",
                    href: "/list-item/3350",
                },
                {
                    name: "Privacy Policy",
                    href: "/list-item/2280",
                },
                {
                    name: "Cookie Policy",
                    href: "/list-item/2283",
                },
            ],
        };
    },
})
export default class Footer extends Vue {}
</script>
