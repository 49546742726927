<template>
    <select class="">
        <slot></slot>
    </select>
</template>

<script>
import { Options, Vue } from "vue-class-component";
const $ = window.$;
@Options({
    props: ["options", "modelValue"],
    mounted() {
        const select2Item = $(this.$el)
            // init select2
            .select2({
                data: this.options,
                placeholder: "Select One",
            });

        select2Item
            .val(this.modelValue)
            .trigger("change")
            .on("change", (ev) => {
                this.$emit("update:modelValue", ev.target.value);
            });
    },
    watch: {
        modelValue(value) {
            // update value
            $(this.$el).val(value).trigger("change");
        },
        options(options) {
            // update options
            $(this.$el)
                .empty()
                .append("<option disabled selected></option>")
                .select2({ data: options });
        },
    },
    unmounted() {
        $(this.$el).off().select2("destroy");
    },
})
export default class Select2 extends Vue {}
</script>
