<template>
    <div class="space-y-6">
        <div class="text-xl text-center">Inspired Jots</div>

        <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
            <div
                v-for="jot in personalJots"
                :key="jot.id"
                class="bg-white mb-4 px-4 shadow p-6 rounded-lg"
            >
                <article :aria-labelledby="'pod-title-' + jot.id">
                    <router-link :to="{ path: '/jot/' + jot.attributes.slug }">
                        <div>
                            <div
                                class="
                                    aspect-w-4 aspect-h-3
                                    w-full
                                    bg-no-repeat bg-center bg-cover
                                    rounded-lg
                                "
                                :style="`background-image: url(${jot.attributes.topic_profile_image})`"
                            ></div>
                        </div>
                        <div class="mt-2">
                            <!-- <div class="text-gray-400 text-xs">
                                {{
                                    jot.relationships.container.attributes.name
                                }}
                            </div> -->
                            <div class="text-gray-500">
                                <strong>{{ jot.relationships.pod.attributes.name }}</strong>
                            </div>
                            <div
                                :id="'pod-title-' + jot.id"
                                class="text-sm text-gray-900 font-light"
                            >
                                {{ jot.attributes.name }}
                            </div>
                        </div>
                    </router-link>
                </article>
            </div>
        </div>

        <div
            v-if="!this.personalJots.length && !this.isBusy"
            class="flex items-center justify-center space-y-6 flex-col"
        >
            <div>
                <img src="@/assets/images/no-fav-jots.svg" alt="No jots" />
            </div>
            <div>No favorite jots.</div>
        </div>

        <div class="my-8 justify-center flex" v-if="this.isBusy">
            <div class="animate-spin w-9">
                <RefreshIcon class="flip" />
            </div>
        </div>

        <div
            class="my-8 justify-center flex"
            v-if="this.hasReachedBottom && this.personalJots.length"
        >
            <div></div>
        </div>
    </div>
</template>

<script>
import { GetLikedJots } from "@/services/user.service";
import { uniqBy } from "lodash";
import { Options, Vue } from "vue-class-component";
import { RefreshIcon } from "@heroicons/vue/solid";
import { isUserBlocked } from "@/utils";

@Options({
    components: {
        RefreshIcon,
    },
    data() {
        return {
            personalJots: [],
            page: 0,
            size: 99,
            isBusy: false,
            hasReachedBottom: false,
        };
    },
    mounted() {
        this.fetchJots();

        window.addEventListener("scroll", () => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.offsetHeight &&
                !this.hasReachedBottom
            ) {
                this.fetchJots();
            }
        });
    },
    methods: {
        async fetchJots() {
            if (this.isBusy) {
                return;
            }
            this.isBusy = true;

            let jots = (
                await GetLikedJots({
                    page: this.page,
                    size: this.size,
                    dir: "desc",
                    order_by: "created_at",
                })
            ).data;

            jots = jots.filter(
                (jot) => !isUserBlocked(jot?.relationships?.author?.id)
            );

            this.page += 1;
            this.personalJots.push(
                ...jots.map((jot) => {
                    let topic_profile_image = jot.attributes.images[0]?.url;

                    if (!topic_profile_image) {
                        if (jot.attributes.content_type === "story") {
                            topic_profile_image = require("@/assets/images/jot-text.png");
                        } else if (jot.attributes.content_type === "video") {
                            topic_profile_image = require("@/assets/images/jot-video.png");
                        } else if (jot.attributes.content_type === "audio") {
                            topic_profile_image = require("@/assets/images/jot-audio.png");
                        }
                    }

                    return {
                        ...jot,
                        attributes: {
                            ...jot.attributes,
                            topic_profile_image,
                        },
                    };
                })
            );

            this.personalJots = uniqBy(this.personalJots, (x) => x.id);

            this.hasReachedBottom = jots.length < this.size;
            this.isBusy = false;
        },
    },
    metaInfo() {
        return {
            title: "Liked Jots",
        };
    },
})
export default class LikedJots extends Vue {}
</script>
