<template>
    <div class="min-h-screen bg-gray-100">
        <Header></Header>

        <div class="py-5">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <main>
                    <router-view />
                </main>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import LeftSideBar from "@/components/LeftSideBar.vue";
import RightSideBar from "@/components/RightSideBar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

@Options({
    components: {
        LeftSideBar,
        RightSideBar,
        Header,
        Footer,
    },
})
export default class AppLayout extends Vue {}
</script>
