<template>
    <metainfo>
        <template v-slot:title="{ content }">{{
            content ? `${content} | Joticle` : `Joticle`
        }}</template>

        <template v-slot:metaInfo>
        <meta property="og:title" :content="jotTitle" />
        <meta property="og:description" :content="jotDescription" />
        <meta property="og:image" :content="jotImageUrl" />
        </template>

    </metainfo>
    <router-view />
</template>

<style lang="scss"></style>
