<template>
    <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
        <div class="md:grid md:grid-cols-3 md:gap-6">
            <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                    Password
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                    This information will be required to access your profile so
                    be careful what you set as your password.
                </p>
            </div>
            <div class="mt-5 md:mt-0 md:col-span-2 space-y-4">
                <div class="grid grid-cols-3 gap-6">
                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="first-name"
                            class="block text-sm font-medium text-gray-700"
                            >Current Password</label
                        >
                        <input
                            type="password"
                            class="
                                mt-1
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border-gray-300
                                rounded-md
                            "
                            v-model="this.formData.current_password"
                        />
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="email-address"
                            class="block text-sm font-medium text-gray-700"
                            >New Password</label
                        >
                        <input
                            type="password"
                            autocomplete="newpassword"
                            class="
                                mt-1
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border-gray-300
                                rounded-md
                            "
                            v-model="this.formData.password"
                        />
                    </div>

                    <div class="col-span-6 sm:col-span-3">
                        <label
                            for="email-address"
                            class="block text-sm font-medium text-gray-700"
                            >Confirm Password</label
                        >
                        <input
                            type="password"
                            autocomplete="newpassword"
                            class="
                                mt-1
                                focus:ring-indigo-500 focus:border-indigo-500
                                block
                                w-full
                                shadow-sm
                                sm:text-sm
                                border-gray-300
                                rounded-md
                            "
                            v-model="this.formData.password_confirmation"
                        />
                    </div>
                </div>

                <div class="col-span-6 sm:col-span-3">
                    <button
                        class="
                            w-2/4
                            inline-flex
                            items-center
                            justify-center
                            px-4
                            py-2
                            border border-transparent
                            text-sm
                            font-medium
                            rounded-md
                            shadow-sm
                            text-white
                            bg-blue-600
                            hover:bg-blue-700
                            focus:outline-none
                            focus:ring-2
                            focus:ring-offset-2
                            focus:ring-offset-gray-100
                            focus:ring-blue-500
                        "
                        v-on:click="this.updateUserPassword"
                    >
                        Update Password
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { UpdateUserPassword } from "@/services/user.service";
import { Vue, Options, setup } from "vue-class-component";
import { useToast } from "vue-toastification";

@Options({
    components: {},
    props: [],
    data() {
        return {
            formData: {
                current_password: null,
                password: null,
                password_confirmation: null,
            },
        };
    },
    methods: {
        updateUserPassword() {
            UpdateUserPassword(this.formData)
                .then(() => {
                    this.toast.success("Password updated successfully.");
                })
                .catch((err) => {
                    this.toast.error(err.message);
                });
        },
    },
})
export default class PasswordForm extends Vue {
    toast = setup(() => useToast());
}
</script>

<style></style>
