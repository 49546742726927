<template>
    <div v-if="this.section">
        <div class="bg-white mb-10 px-4 shadow sm:p-6 sm:rounded-lg">
            <SubHeader :title="this.section.attributes.name" class="mb-6" :imgSrc="this.section.relationships.pod.attributes.topic_logo_image
                " />

            <BreadCrumbs :crumbs="[
                {
                    id: this.section.relationships.container.id,
                    title: this.section.relationships.container.attributes
                        .name,
                    url:
                        '/container/' +
                        this.section.relationships.container.attributes
                            .slug,
                },
                {
                    id: this.section.relationships.pod.id,
                    title: this.section.relationships.pod.attributes.name,
                    url:
                        '/pod/' +
                        this.section.relationships.pod.attributes.slug,
                },
            ]" />

            <div class="text-3xl mb-4 font-bold">
                {{ this.section.attributes.name }}
            </div>
            <p class="mb-4" v-html="this.section.attributes.info"></p>
            <!-- <Tags :tags="this.section.relationships.tags" /> -->

            <div class="divide-y divide-gray-200">

            </div>
        </div>

        <div class="bg-white shadow sm:rounded-lg" v-if="this.section?.relationships?.list_items?.length">
            {{ this.section?.relationships.length }}
            <div class="mb-6">
                <div class="text-xl sm:p-2 text-center bg-blue text-white">Sub Sections</div>

                <!-- <router-link v-for="childSection in this.section.relationships.children" :key="childSection.id" class="
                    py-3
                    flex
                    px-7
                    hover:bg-gray-100
                    cursor-pointer
                    -mx-6
                " :to="{ path: '/section/' + childSection.attributes.slug }">
                    {{ childSection.attributes.name }}
                </router-link> -->

                <template v-if="!this.section.relationships.children.length">
                    <router-link v-for="list_item in this.section.relationships.list_items" :key="list_item.id" class="
                        border-b border-black
                        p-3  
                        flex
                        hover:bg-greenn-100 hover:text-white
                        cursor-pointer
                    " :to="{
                        path: `/list-item/${list_item.attributes.slug}`,
                        query: { tag: 'HOT' },
                    }">
                        {{ list_item.attributes.name }}
                    </router-link>
                    <div v-if="this.section.relationships.list_items.length == 0" class="text-center p-6"></div>
                </template>
            </div>
        </div>
        <div class="mt-6">
            <router-link :to="{ path: '/jot/create' }" class="
                    w-full
                    inline-flex
                    items-center
                    justify-center
                    px-4
                    py-3
                    border border-transparent
                    text-sm
                    font-medium
                    rounded-md
                    shadow-sm
                    text-white
                    bg-blue-600
                    hover:bg-blue-700
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-offset-gray-100
                    focus:ring-blue-500
                ">
                Create a Jot
            </router-link>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { GetSectionById } from "@/services/section.service";
import SubHeader from "@/components/SubHeader.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Tags from "@/components/Tags.vue";
import { sortBy } from "lodash";
@Options({
    components: {
        SubHeader,
        Tags,
        BreadCrumbs,
    },
    data() {
        return {
            id: this.$route.params.id,
            section: null,
        };
    },
    mounted() {
        this.fetchSection();
    },
    methods: {
        fetchSection() {
            GetSectionById(this.id).then((resp) => {
                this.section = resp.data;

                this.section.relationships.list_items = sortBy(
                    this.section.relationships.list_items,
                    (x) => x.attributes.name
                );

                this.section.relationships.children = sortBy(
                    this.section.relationships.children,
                    (x) => x.attributes.name
                );

                // Store containerid and pod id to local storage for use in create jot
                const jotLocal = {
                    containerId: this.section.relationships.container.id,
                    podId: this.section.relationships.pod.id,
                    sectionId: this.section.id,
                };
                localStorage.setItem("jotLocal", JSON.stringify(jotLocal));
            });
        },
    },
    watch: {
        "$route.params.id": function (id) {
            if (this.$route.name === "Section") {
                this.id = id;
                this.fetchSection();
            }
        },
    },
    metaInfo() {
        return {
            title: this.section?.attributes.name,
            meta: [
                {
                    name: "description",
                    content: this.section?.attributes.description,
                },
            ],
        };
    },
})
export default class Section extends Vue { }
</script>

<style></style>
