<template>
    <footer class="bg-gray-900 text-white mt-10 py-16 relative">
        <button
            class="absolute right-0 bottom-0 md:bottom-auto"
            v-on:click="this.scrollTop"
        >
            <img src="@/assets/images/up-button.svg" alt="Scroll Up" />
        </button>

        <div
            class="
                max-w-7xl
                mx-auto
                px-4
                sm:px-6
                lg:px-8
                md:flex
                justify-between
                space-y-6
                md:space-y-0
            "
        >
            <div class="space-y-3">
                <div>
                    <router-link :to="{ path: '/' }">
                        <img
                            src="@/assets/images/joticle-footer-logo.svg"
                            alt="Footer Logo"
                        />
                    </router-link>
                </div>
                <div class="text-gray-400">
                    &copy; {{ new Date().getFullYear() }} Joticle.com. All
                    rights reserved.
                </div>
            </div>

            <div>
                <div class="font-bold text-xl mb-4">Links</div>
                <div class="space-y-3 text-gray-400">
                    <div>
                        <router-link :to="{ path: '/feeds?tag=TRENDING' }">
                            Topic Pods
                        </router-link>
                    </div>
                    <div>
                        <router-link :to="{ path: '/about' }">
                            About
                        </router-link>
                    </div>
                    <div>
                        <router-link :to="{ path: '/investor-relations' }">
                            Investor Relations
                        </router-link>
                    </div>
                </div>
            </div>
            <div>
                <div class="font-bold text-xl mb-4">Links</div>
                <div class="space-y-3 text-gray-400">
                    <div>
                        <router-link :to="{ path: '/list-item/terms-of-use' }">
                            Terms and Conditions
                        </router-link>
                    </div>
                    <div>
                        <router-link
                            :to="{ path: '/list-item/privacy-policy' }"
                        >
                            Privacy Policy
                        </router-link>
                    </div>
                    <div>
                        <router-link :to="{ path: '/list-item/cookie-policy' }">
                            Cookie Policy
                        </router-link>
                    </div>
                </div>
            </div>
            <div>
                <div class="font-bold text-xl mb-4">Socials</div>
                <div class="gap-4 flex">
                    <a href="https://www.facebook.com/joticle" target="_blank">
                        <img
                            src="@/assets/images/footer-fb.svg"
                            alt="Facebook"
                        />
                    </a>
                    <a href="https://twitter.com/joticle" target="_blank">
                        <img
                            src="@/assets/images/footer-twitter.svg"
                            alt="Twitter"
                        />
                    </a>
                    <a href="https://instagram.com/joticle" target="_blank">
                        <img
                            src="@/assets/images/instagram.png"
                            alt="Instagram"
                            width="20"
                        />
                    </a>
                    <a
                        href="https://www.linkedin.com/company/18385385/admin/"
                        target="_blank"
                    >
                        <img
                            src="@/assets/images/footer-linkedin.svg"
                            alt="Linkedin"
                        />
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { Options, Vue } from "vue-class-component";
@Options({
    data() {
        return {};
    },
    methods: {
        scrollTop() {
            window?.scrollTo(0, 0);
        },
    },
})
export default class FooterDark extends Vue {}
</script>
