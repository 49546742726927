import { GetLocalToken, Logout } from "@/services/auth.service";
import axios, { AxiosInstance } from "axios";

export const HttpClient = (): AxiosInstance => {
    const localToken = GetLocalToken();

    const headers: any = {
        // "Content-Type": "application/json",
        // Authorization: "",
    };

    if (localToken) {
        headers["Authorization"] = "Bearer " + localToken.access_token;
    }

    const instance = axios.create({
        baseURL: process.env.VUE_APP_API_ENDPOINT,
        headers,
    });

    instance.interceptors.response.use(
        (response) => {
            // console.log("🚀 ~ HTTP Response", response);
            return response.data;
        },
        (error) => {
            if (error?.response?.data?.errors) {
                let errorMessage = Object.values(
                    error.response.data.errors
                ).join("\n");

                if (error.response.data.errors.messages) {
                    errorMessage = Object.values(
                        error.response.data.errors.messages
                    ).join("\n");
                }

                throw new Error(errorMessage);
            }

            if (error?.response?.status === 401) {
                Logout();
            }

            throw error;
        }
    );

    return instance;
};

export interface IHTTPDataResponse<T> {
    data: T;
}

export interface IPaginationPayload {
    page?: number;
    size?: number;
    dir?: string;
    order_by?: string;
}
