<template>
    <div class="relative mx-auto py-12">
        <div class="relative">
            <h1
                class="
                    text-3xl
                    font-extrabold
                    text-gray-900
                    sm:text-5xl sm:leading-none sm:tracking-tight
                    lg:text-6xl
                "
            >
                Subscribe to Joticle today
            </h1>
            <p class="mt-6 text-xl text-gray-500">
                Choose an affordable plan that’s packed with the best features
                for creating Jots and engaging with your audience.
            </p>
        </div>
    </div>

    <section class="relative pb-36" aria-labelledby="pricing-heading">
        <h2 id="pricing-heading" class="sr-only">Pricing</h2>

        <!-- Tiers -->
        <div
            class="
                max-w-2xl
                mx-auto
                space-y-12
                lg:max-w-7xl lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-8
            "
        >
            <div
                v-for="tier in this.pricing.tiers"
                :key="tier.title"
                class="
                    relative
                    p-8
                    bg-white
                    border border-gray-200
                    rounded-2xl
                    shadow-sm
                    flex flex-col
                "
            >
                <div class="flex-1">
                    <h3 class="text-xl font-semibold text-gray-900">
                        {{ tier.title }}
                    </h3>
                    <p
                        v-if="tier.mostPopular"
                        class="
                            absolute
                            top-0
                            py-1.5
                            px-4
                            bg-blue-500
                            rounded-full
                            text-xs
                            font-semibold
                            uppercase
                            tracking-wide
                            text-white
                            transform
                            -translate-y-1/2
                        "
                    >
                        Most popular
                    </p>
                    <p class="mt-4 flex items-baseline text-gray-900">
                        <span class="text-5xl font-extrabold tracking-tight"
                            >${{ tier.price }}</span
                        >
                        <span class="ml-1 text-xl font-semibold">{{
                            tier.frequency
                        }}</span>
                    </p>
                    <p class="mt-6 text-gray-500">{{ tier.description }}</p>

                    <!-- Feature list -->
                    <ul role="list" class="mt-6 space-y-6">
                        <li
                            v-for="feature in tier.features"
                            :key="feature"
                            class="flex"
                        >
                            <CheckIcon
                                class="flex-shrink-0 w-6 h-6 text-blue-500"
                                aria-hidden="true"
                            />
                            <span class="ml-3 text-gray-500">{{
                                feature
                            }}</span>
                        </li>
                    </ul>
                </div>

                <a
                    href="#"
                    :class="[
                        tier.mostPopular
                            ? 'bg-blue-500 text-white hover:bg-blue-600'
                            : 'bg-blue-50 text-blue-700 hover:bg-blue-100',
                        'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium',
                    ]"
                    >{{ tier.cta }}</a
                >
            </div>
        </div>
    </section>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { CheckIcon } from "@heroicons/vue/outline";
@Options({
    components: {
        CheckIcon,
    },
    data() {
        return {
            pricing: {
                tiers: [
                    {
                        title: "Monthly",
                        price: 1.99,
                        frequency: "/month",
                        description:
                            "Everything you need to get the best from Joticle for one low monthly price.",
                        features: [
                            "Create Jots",
                            "Promote Jots",
                            "No Ads",
                            "Unlimited Searches",
                        ],
                        cta: "Monthly billing",
                        mostPopular: false,
                    },
                    {
                        title: "Annually",
                        price: 5.99,
                        frequency: "/year",
                        description:
                            "Everything you need to get the best from Joticle for one low annual price.",
                        features: [
                            "Create Jots",
                            "Promote Jots",
                            "No Ads",
                            "Unlimited Searches",
                        ],
                        cta: "Annual billing",
                        mostPopular: true,
                    },
                    {
                        title: "Lifetime",
                        price: 9.99,
                        frequency: "/forever",
                        description:
                            "Everything you need to get the best from Joticle for one small payment. ",
                        features: [
                            "Create Jots",
                            "Promote Jots",
                            "No Ads",
                            "Unlimited Searches",
                        ],
                        cta: "Lifetime Membership",
                        mostPopular: false,
                    },
                ],
            },
        };
    },
    metaInfo() {
        return {
            title: "Subscriptions",
        };
    },
})
export default class Subscription extends Vue {}
</script>
