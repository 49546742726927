<template>
    <div class="my-8 justify-center flex" v-if="!this.isReady">
        <div class="animate-spin w-9">
            <RefreshIcon class="flip" />
        </div>
    </div>
    <div
        class="bg-white mb-10 px-4 shadow sm:p-6 sm:rounded-lg"
        v-if="this.isReady"
    >
        <div class="text-2xl mb-4 font-bold">
            <span v-if="!this.id">Create </span>
            <span v-if="this.id">Update </span>
            Jot
        </div>
        <div class="space-y-6">
            <RadioGroup v-model="this.formData.content_type">
                <RadioGroupLabel class="sr-only"> Server size </RadioGroupLabel>
                <div class="flex items-center gap-4">
                    <RadioGroupOption
                        as="template"
                        v-for="plan in plans"
                        :key="plan.name"
                        :value="plan"
                        v-slot="{ active, checked }"
                    >
                        <div
                            :class="[
                                active
                                    ? 'ring-1 ring-offset-2 ring-blue-500'
                                    : '',
                                'relative block rounded-lg border border-gray-300 hover:border-greenn-100 bg-white shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none flex-1 ',
                            ]"
                        >
                            <div class="flex items-center justify-center">
                                <div>
                                    <img
                                        class="block h-12 w-auto"
                                        :src="plan.icon"
                                        :alt="plan.name"
                                    />
                                </div>

                                <div class="text-sm pl-2">
                                    <RadioGroupLabel
                                        as="p"
                                        class="font-medium text-gray-900"
                                    >
                                        {{ plan.name }}
                                    </RadioGroupLabel>
                                </div>
                            </div>
                            <div
                                :class="[
                                    checked
                                        ? 'border-blue-500 border-2'
                                        : 'border-transparent',
                                    'absolute -inset-px rounded-lg pointer-events-none',
                                ]"
                                aria-hidden="true"
                            />
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>

            <div class="flex gap-4">
                <div class="w-2/4">
                    <label class="block text-sm font-medium text-gray-700"
                        >Pick Your Focus</label
                    >
                    <select2
                        :options="this.containers"
                        v-model="this.formData.container"
                    >
                    </select2>
                </div>
                <div class="w-2/4">
                    <label class="flex items-center text-sm font-medium text-gray-700">
                        Pick Your Learning Pod
                        <div v-if="podLoader" class="ml-2 flex space-x-2">
                            <div class="loader-dot"></div>
                            <div class="loader-dot"></div>
                            <div class="loader-dot"></div>
                        </div>
                    </label>                    
                    <select2
                        :options="this.pods"
                        v-model="this.formData.learning_pod"
                    >
                    </select2>
                </div>
            </div>
            <div class="flex gap-4">
                <div class="w-2/4">
                    <label class="flex items-center text-sm font-medium text-gray-700">
                        Pick Your Section
                        <div v-if="sectionLoader" class="ml-2 flex space-x-2">
                            <div class="loader-dot"></div>
                            <div class="loader-dot"></div>
                            <div class="loader-dot"></div>
                        </div>
                    </label>   
                    
                    <select2
                        :options="this.sections"
                        v-model="this.formData.section"
                    >
                    </select2>
                </div>
                <div class="w-2/4">
                    <label class="flex items-center text-sm font-medium text-gray-700">
                        Pick Your Item
                        <div v-if="listItemLoader" class="ml-2 flex space-x-2">
                            <div class="loader-dot"></div>
                            <div class="loader-dot"></div>
                            <div class="loader-dot"></div>
                        </div>
                    </label>
                    <select2
                        :options="this.listItems"
                        v-model="this.formData.list_item_id"
                    >
                    </select2>
                </div>
            </div>

            <div>
                <label class="block text-sm font-medium text-gray-700">
                    Jot Title / Name
                </label>
                <div class="mt-1">
                    <input
                        type="text"
                        class="
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                        "
                        v-model="this.formData.name"
                    />
                </div>
            </div>

            <div v-if="this.formData.content_type.name !== 'Story'">
                <label class="block text-sm font-medium text-gray-700">
                    Source URL
                </label>
                <div class="mt-1">
                    <input
                        type="url"
                        class="
                            shadow-sm
                            focus:ring-blue-500 focus:border-blue-500
                            block
                            w-full
                            sm:text-sm
                            border-gray-300
                            rounded-md
                        "
                        v-model="this.formData.content_url"
                    />
                </div>
            </div>

            <div>
                <label class="block text-sm font-medium text-gray-700">
                    Jot Content
                </label>
                <editor
                    :api-key="this.TINY_MCE_KEY"
                    :init="{
                        height: 300,
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                        ],
                        //undo redo | blocks | bold italic forecolor | alignleft aligncenter | link image media table mergetags | alignright alignjustify | bullist numlist outdent indent | removeformat | code | help
                        toolbar: '',
                        paste_as_text: true,
                        //images_upload_url: this.TINY_MCE_POST_URL+'media/create',
                        //images_upload_base_path: this.TINY_MCE_POST_URL,
                        automatic_uploads: true,
                        images_upload_handler: imageUploadHandler,
                        content_style:
                            'body { font-family:Arial; font-size:11px }',
                    }"
                    v-model="this.formData.content"
                />
            </div>
            <div>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                    <FileUpload
                        @onFileChanged="this.onFileChanged"
                        :images="this.images"
                    />
                </div>
            </div>

            <div>
                <button
                    class="
                        w-full
                        px-4
                        py-3
                        border border-transparent
                        text-sm
                        font-medium
                        rounded-md
                        shadow-sm
                        text-white
                        bg-blue-600
                        hover:bg-blue-700
                        focus:outline-none
                        text-center
                        block
                        disabled:bg-grey-200 disabled:opacity-50
                        flex
                        align-center
                        justify-center
                        gap-2
                    "
                    v-on:click="this.onSubmit()"
                    :disabled="this.submitting"
                >
                    <span v-if="!this.id">Share the Jot </span>
                    <span v-if="this.id">Save Changes </span>

                    <div class="animate-spin w-5" v-if="this.submitting">
                        <RefreshIcon class="flip" />
                    </div>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { Options, Vue, setup } from "vue-class-component";
import {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
} from "@headlessui/vue";
import {
    CreateJot,
    GetJotById,
    JotTypes,
    UpdateJot,
    UploadMedia,
} from "@/services/jot.service";
import Select2 from "@/components/Select2.vue";
import FileUpload from "@/components/FileUpload.vue";
import Editor from "@tinymce/tinymce-vue";
import { GetContainerById, GetContainers } from "@/services/container.service";
import { GetPodById } from "@/services/pod.service";
import { GetSectionById } from "@/services/section.service";
import { useToast } from "vue-toastification";
import { RefreshIcon } from "@heroicons/vue/solid";
import { GetLocalToken } from "@/services/auth.service";
import { sortBy } from "lodash";

@Options({
    components: {
        RadioGroup,
        RadioGroupDescription,
        RadioGroupLabel,
        RadioGroupOption,
        Select2,
        Editor,
        RefreshIcon,
        FileUpload,
    },
    data() {
        return {
            isReady: false,

            TINY_MCE_KEY: process.env.VUE_APP_TINYMCE_KEY,
            TINY_MCE_POST_URL: process.env.VUE_APP_API_ENDPOINT,

            id: this.$route.params.id,
            jot: null,

            plans: JotTypes,
            containers: [],
            pods: [],
            sections: [],
            listItems: [],

            images: [],
            formData: {
                content_type: JotTypes[0],
                container: 0,
                learning_pod: 0,
                section: 0,
                list_item_id: 0,
                name: "",
                content_url: "",
                content: "",
                images: [],
            },
            localPodId: 0,
            localSectionId: 0,
            submitting: false,

            podLoader:false,
            sectionLoader:false,
            listItemLoader:false,
        };
    },
    watch: {
        "formData.container": {
            handler: async function (newValue, oldValue) {
                if (newValue && Number(newValue) != Number(oldValue)) {
                    this.pods = [];
                    this.podLoader = true;
                    const { data: container } = await GetContainerById(
                        newValue
                    );

                    const unsortedPods = container.relationships.pods.map(
                        (pod) => {
                            return {
                                ...pod,
                                text: pod.attributes.name,
                            };
                        }
                    );
                    this.pods = sortBy(unsortedPods, "text");
                    this.podLoader = false;
                    this.formData.learning_pod = this.localPodId;
                    this.formData.section = 0;
                    this.formData.list_item_id = 0;

                    window.dispatchEvent(new Event("ContainerSetCompleted"));
                }
            },
        },
        "formData.learning_pod": {
            handler: async function (newValue, oldValue) {
                if (newValue && Number(newValue) != Number(oldValue)) {
                    this.sections = [];
                    this.sectionLoader = true;
                    const { data: pod } = await GetPodById(newValue);
                    
                    const unsortedSections = pod.relationships.sections.map(
                        (section) => {
                            return {
                                ...section,
                                text: section.attributes.name,
                            };
                        }
                    );
                    this.sections = sortBy(unsortedSections, "text");
                    this.sectionLoader = false;
                    this.formData.section = this.localSectionId;
                    this.formData.list_item_id = 0;

                    window.dispatchEvent(new Event("LearningPodSetCompleted"));
                }
            },
        },
        "formData.section": {
            handler: async function (newValue, oldValue) {
                if (newValue && Number(newValue) != Number(oldValue)) {
                    this.listItems = [];
                    this.listItemLoader = true;
                    const { data: section } = await GetSectionById(newValue);

                    const unsortedListItems =
                        section.relationships.list_items.map((listItem) => {
                            return {
                                ...listItem,
                                text: listItem.attributes.name,
                            };
                        });
                        
                        this.listItems = sortBy(unsortedListItems, "text");
                        this.listItemLoader = false;
                        
                        const jotLocalListItem = JSON.parse(localStorage.getItem("jotLocalListItem"));
                        if (jotLocalListItem) {
                            const item = this.listItems.find(item => item.id === jotLocalListItem.list_item_slug);
                            this.formData.list_item_id = item.id;
                        }else{
                            this.formData.list_item_id = 0;
                        }
                        window.dispatchEvent(new Event("SectionSetCompleted"));
                }
            },
        },
    },

    async mounted() {
        const localToken = GetLocalToken();
        if (!localToken?.access_token) {
            this.$router.push("/");
        }

        // Load local jot info
        const jotLocal = JSON.parse(localStorage.getItem("jotLocal"));
        if (jotLocal) {
            this.formData.container = jotLocal.containerId;
            this.localPodId = jotLocal.podId;
            this.localSectionId = jotLocal.sectionId;
        }
        // end

        const { data: containers } = await GetContainers();
        const unsortedContainers = containers.map((container) => {
            return {
                ...container,
                text: container.attributes.name,
            };
        });
        this.containers = sortBy(unsortedContainers, "text");

        if (this.id) {
            this.submitting = true;
            const { data: jot } = await GetJotById(this.id);

            this.formData.content_type = JotTypes.find(
                (x) => x.name.toLowerCase() === jot.attributes.content_type
            );

            this.formData.container = jot.relationships.container.id;

            window.addEventListener("ContainerSetCompleted", () => {
                this.formData.learning_pod = jot.relationships.pod.id;
                window.removeEventListener("ContainerSetCompleted", null);
            });

            window.addEventListener("LearningPodSetCompleted", () => {
                this.formData.section = jot.relationships.section.id;
                window.removeEventListener("LearningPodSetCompleted", null);
            });

            window.addEventListener("SectionSetCompleted", () => {
                this.formData.list_item_id = jot.relationships.list_item.id;
                this.submitting = false;
                window.removeEventListener("SectionSetCompleted", null);
            });

            this.formData.name = jot.attributes.name;
            this.formData.content_url = jot.attributes.content_url;
            this.formData.content = jot.attributes.content;

            this.images = jot.attributes.images;
        }

        this.isReady = true;
    },

    methods: {
        onSubmit() {
            if (this.submitting) {
                return;
            }

            if (!this.formData.list_item_id) {
                this.toast.error("Please select list item to submit.");
                return;
            }

            if (this.formData.content.replace(/<[^>]*>?/gm, "").length < 40) {
                this.toast.error("Jot content must be at least 40 characters.");
                return;
            }

            this.submitting = true;
            const payload = {
                ...this.formData,
                content_type: this.formData.content_type.name.toLowerCase(),
            };

            delete payload.container;
            delete payload.learning_pod;
            delete payload.section;

            if (payload.content_type === "story") {
                delete payload.content_url;
            }

            let request;
            if (this.id) {
                request = UpdateJot(this.id, payload);
            } else {
                request = CreateJot(payload);
            }

            request
                .then((resp) => {
                    this.toast.success("Jot saved successfully.");
                    this.submitting = false;

                    const listItemId = resp.data?.relationships?.list_item?.id;
                    if (listItemId) {
                        this.$router.push("/list-item/" + listItemId);
                        return;
                    }

                    this.$router.push("/?tag=PERSONAL");
                })
                .catch((err) => {
                    this.toast.error(err.message);
                    this.submitting = false;
                });
        },
        onFileChanged(files) {
            this.formData.images = files;
        },
        // Tiny mce file upload
        imageUploadHandler(blobInfo, success, failure) {
            const mediaData = new FormData();
            mediaData.append('file', blobInfo.blob(), blobInfo.filename());
            UploadMedia(mediaData)
                .then((resp) => {
                    success(resp.data.imageUrl);
                })
                .catch((err) => {
                    this.toast.error(err.message);
                });
        },
    },
    metaInfo() {
        return {
            title: "Create Jot",
        };
    },
})
export default class JotForm extends Vue {
    toast = setup(() => useToast());
}
</script>

<style>
/* Loader styles */
.loader-dot {
    width: 12px;
    height: 12px;
    background-color: #4B5563; /* Adjust color as needed */
    border-radius: 50%;
    animation: pulse 1s ease-in-out infinite;
  }
  @keyframes pulse {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
</style>
