<template>
    <div
        class="grid place-items-center bg-overlay"
        :style="{
            'background-image': `url(${require('@/assets/images/hands.png')}) `,
            height: '250px',
        }"
    >
        <div class="space-y-4 text-center text-white">
            <div class="text-4xl">About Joticle</div>
            <div>Home / About</div>
        </div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 space-y-10">
        <section>
            <iframe
                width="100%"
                height="600"
                src="https://www.youtube.com/embed/-zHqywdF6qY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </section>

        <div class="space-y-4 w-4/5 mx-auto">
            <div class="font-nunito font-bold text-3xl text-center">
                Welcome Joticle
            </div>
            <div>
                Joticle is not a tutorial site. We are curating information best
                suited for learning a topic and building it into learning pods
                for a growing topic community.
            </div>
            <div>
                Currently, Joticle reflects the Research and Discovery aspects
                of our platform and houses over 3500 topics, with more
                onboarding. It supports microlearning and offers free access to
                learning for EVERYBODY without any hidden charges, paywalls, ad
                saturation, outdated content, and inconsistent results.
            </div>
            <div>
                Once we complete our Teachers First initiative Joticle will be
                the first-of-its-kind, topic-driven social learning and research
                platform that is driven by teachers, not technologists. Our
                solution not only empowers teachers to manage digital learning
                from a single platform but helps them win back significant time.
                Finally, with Joticle teachers can go back to being teachers
                instead of fighting tedious technology silos during valuable
                class hours. Our aim is to pave the way for future-focused
                education across the lifelong learning spectrum within the
                following niches—Elementary through to Junior High, High school,
                and University, Career, Hobby, and Post-Retirement.
            </div>
        </div>

        <div
            class="
                flex
                justify-between
                bg-no-repeat bg-cover
                rounded-xl
                py-10
                px-10
            "
            :style="{
                'background-image': `url(${require('@/assets/images/blue-bg.svg')}) `,
            }"
        >
            <div class="text-3xl font-nunito text-white">Explore Joticle</div>
            <div>
                <router-link
                    :to="{ path: '/feeds', query: { tag: 'TRENDING' } }"
                    class="
                        bg-white
                        hover:bg-gray-100
                        py-2
                        px-10
                        rounded-xl
                        text-blue-500
                    "
                    >Explore Joticle!</router-link
                >
            </div>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
    components: {},
    metaInfo() {
        return {
            title: "About",
        };
    },
})
export default class About extends Vue {}
</script>

<style></style>
