import { HttpClient, IHTTPDataResponse } from "@/core/http-client";
import {
    IGetTokenPayload,
    ITokenResponse,
    IRegisterPayload,
    IUser,
} from "@/interfaces/auth.interface";
import { GetCurrentUser } from "./user.service";

export const GetToken = (payload: IGetTokenPayload) => {
    const data: any = {
        ...payload,
        grant_type: "password",
        client_id: "2",
        client_secret: "jI0c3R26TQBVLCZQjqRA3tR20f79KUSYrpLr0vBR",
        scope: "",
    };

    return HttpClient()
        .post<ITokenResponse>("/oauth/token", data)
        .then((resp) => {
            localStorage.setItem("token", JSON.stringify(resp));
            GetCurrentUser();
        });
};

export const RegisterNewUser = (payload: IRegisterPayload) => {
    return HttpClient().post<IHTTPDataResponse<IUser>>(
        "/api/v1/register",
        payload
    );
};

export const ResetPassword = (email: string) => {
    return HttpClient().post<IHTTPDataResponse<any>>("/api/v1/password/email", {
        email,
    });
};

export const UpdatePassword = (payload: any) => {
    return HttpClient().post<IHTTPDataResponse<any>>(
        "/api/v1/password/reset",
        payload
    );
};

export const GetLocalToken = () => {
    return JSON.parse(localStorage.getItem("token") || "{}") as ITokenResponse;
};

export const Logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    console.info("Local storage cleared.");
    return;
};
