<template>
    <div class="hidden xl:block xl:col-span-4">

        <div class="sticky top-4 space-y-6">
            <section>
                <div v-if="videoSegment?.type">
                    <div class="wistia_responsive_wrapper w-100">
                        <div class="text-xl sm:p-2 text-center bg-blue text-white">
                            <a :href="videoSegment?.attributes?.link"
                            v-if="videoSegment?.attributes?.link !== ''"
                            :target="videoSegment?.attributes?.link_type === '1' ? '_blank' : '_self'">
                                {{videoSegment?.attributes.title}}
                                <svg v-if="videoSegment?.attributes?.link !== ''" class="inline" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#FFFFFF" fill-rule="evenodd" clip-rule="evenodd">
                                    <path d="M14.851 11.923c-.179-.641-.521-1.246-1.025-1.749-1.562-1.562-4.095-1.563-5.657 0l-4.998 4.998c-1.562 1.563-1.563 4.095 0 5.657 1.562 1.563 4.096 1.561 5.656 0l3.842-3.841.333.009c.404 0 .802-.04 1.189-.117l-4.657 4.656c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-1.952-1.951-1.952-5.12 0-7.071l4.998-4.998c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464.493.493.861 1.063 1.105 1.672l-.787.784zm-5.703.147c.178.643.521 1.25 1.026 1.756 1.562 1.563 4.096 1.561 5.656 0l4.999-4.998c1.563-1.562 1.563-4.095 0-5.657-1.562-1.562-4.095-1.563-5.657 0l-3.841 3.841-.333-.009c-.404 0-.802.04-1.189.117l4.656-4.656c.975-.976 2.256-1.464 3.536-1.464 1.279 0 2.56.488 3.535 1.464 1.951 1.951 1.951 5.119 0 7.071l-4.999 4.998c-.975.976-2.255 1.464-3.535 1.464-1.28 0-2.56-.488-3.535-1.464-.494-.495-.863-1.067-1.107-1.678l.788-.785z"/>
                                </svg>
                            </a>
                        </div>
                        <div v-if="videoSegment?.attributes?.type==2">
                            <div class="wistia_embed wistia_async_jzi3toizhp videoFoam=true">
                                <div>
                                    <img :src="videoSegment?.attributes?.video" class="w-100" alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;">
                                </div>
                            </div>
                        </div>
                        <div style="width: 100%;height: 100%" v-if="videoSegment?.attributes?.type==1">
                            <iframe style="width: 100%;height: 200px;" :src="videoSegment?.attributes?.video" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>

                <div v-if="!videoSegment?.type">
                    <div class="wistia_responsive_wrapper w-100">
                        <div class=" wistia_embed wistia_async_jzi3toizhp videoFoam=true w-100">
                            <div class="wistia_swatch" style="
                                    height: 100%;
                                    transition: opacity 200ms;
                                    width: 100%;
                                ">
                                <img src="https://joticlesites.wistia.com/medias/jzi3toizhp" style="
                                        filter: blur(5px);
                                        height: 100%;
                                        object-fit: contain;
                                        width: 100%;
                                    " alt="" aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section v-for="infoSegment in infoSegments" :key="infoSegment.id">
                <div class="bg-white rounded-lg shadow">
                    <div class="text-xl sm:p-2 text-center bg-blue text-white" v-if="infoSegment?.attributes?.title">
                        {{ infoSegment.attributes.title }}
                    </div>
                    <div class="p-6">
                        <div class="flex items-center gap-1">
                            <div class="flex-2" style="flex-shrink: 0;">
                                <img :src="infoSegment?.attributes?.image.original_url" alt="Logo" v-if="infoSegment?.attributes?.image" style="height:auto;width:80px;object-fit:contain;"/>
                                <img src="@/assets/images/joticle-small.png" alt="Logo" v-if="!infoSegment?.attributes?.image" style="height:auto;width:80px;object-fit:contain;"/>
                            </div>
                            <div class="flex-2">
                                <p style="word-break: break-word;" v-if="infoSegment?.attributes?.info" v-html="formatInfo(infoSegment.attributes.info)"></p>
                                <p v-if="!infoSegment?.attributes?.info">Learn about what Joticle is and our mission</p>
                            </div>
                        </div>
                        <div class="mt-6">
                            <a :href="infoSegment?.attributes?.link"
                            v-if="infoSegment?.attributes?.link"
                            :target="infoSegment?.attributes?.link_type === '1' ? '_blank' : '_self'"
                            class="
                                    w-full
                                    block
                                    text-center
                                    px-4
                                    py-2
                                    border border-gray-300
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    rounded-md
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                "
                            >
                                {{infoSegment?.attributes.btn_info}}
                            </a>
                            <router-link :to="{ path: '/pod/joticle' }" v-if="!infoSegment?.attributes?.link" class="
                                    w-full
                                    block
                                    text-center
                                    px-4
                                    py-2
                                    border border-gray-300
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    rounded-md
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                ">
                                View the Joticle Company Pod
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="!infoSegments.length">
                <div class="bg-white rounded-lg shadow">
                    <div class="p-6">
                        <div class="flex items-center gap-1">
                            <div class="flex-2" style="flex-shrink: 0;">
                                <img src="@/assets/images/joticle-small.png" alt="Logo" style="height:auto;width:80px;object-fit:contain;"/>
                            </div>
                            <div class="flex-2">
                                <p>Learn about what Joticle is and our mission</p>
                            </div>
                        </div>
                        <div class="mt-6">
                            <router-link :to="{ path: '/pod/joticle' }" class="
                                    w-full
                                    block
                                    text-center
                                    px-4
                                    py-2
                                    border border-gray-300
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    rounded-md
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                ">
                                View the Joticle Company Pod
                            </router-link>
                        </div>
                    </div>
                </div>
            </section>

            <section v-for="ProductSegment in ProductSegments" :key="ProductSegment.id">
                <div class="bg-white rounded-lg shadow">
                    <div class="text-xl sm:p-2 text-center bg-blue text-white" v-if="ProductSegment?.attributes?.title">
                        {{ ProductSegment.attributes.title }}
                    </div>
                    <div class="p-6">
                        <div class="flex items-center gap-1">
                            <div class="flex-2" style="flex-shrink: 0;">
                                <img :src="ProductSegment?.attributes?.image.original_url" alt="Logo" v-if="ProductSegment?.attributes?.image" style="height:auto;width:80px;object-fit:contain;"/>
                                <img src="@/assets/images/joticle-small.png" alt="Logo" v-if="!ProductSegment?.attributes?.image" style="height:auto;width:80px;object-fit:contain;"/>
                            </div>
                            <div class="flex-2">
                                <p style="word-break: break-word;" v-if="ProductSegment?.attributes?.info" v-html="formatInfo(ProductSegment.attributes.info)"></p>
                                <p v-if="!ProductSegment?.attributes?.info">Learn about what Joticle is and our mission</p>
                            </div>
                        </div>
                        <div class="mt-6">
                            <a :href="ProductSegment?.attributes?.link"
                            v-if="ProductSegment?.attributes?.link"
                            :target="ProductSegment?.attributes?.link_type === '1' ? '_blank' : '_self'"
                            class="
                                    w-full
                                    block
                                    text-center
                                    px-4
                                    py-2
                                    border border-gray-300
                                    shadow-sm
                                    text-sm
                                    font-medium
                                    rounded-md
                                    text-gray-700
                                    bg-white
                                    hover:bg-gray-50
                                "
                            >
                                {{ProductSegment?.attributes.btn_info}}
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <!-- <section>
                <div class="bg-white rounded-lg shadow">
                    <div
                        id="banner-ad-1"
                        style="width: 100%; height: 280px"
                        class="p-4 flex justify-center"
                    ></div>
                </div>
            </section>

            <section>
                <div class="bg-white rounded-lg shadow">
                    <div
                        id="banner-ad-2"
                        style="width: 100%; height: 280px"
                        class="p-4 flex justify-center"
                    ></div>
                </div>
            </section> -->
        </div>
    </div>
</template>

<script>
import { Options, setup, Vue } from "vue-class-component";
import { GetPodRightBar } from "@/services/pod.service";
import { GetJots } from "@/services/jot.service";
import { GetContainerSideBar } from "@/services/container.service";
import { ChatAltIcon, PlusIcon } from "@heroicons/vue/solid";
import { flatMap, uniqBy } from "lodash";
import { isUserBlocked } from "@/utils";
import RecursiveSectionList from "@/components/RecursiveSectionList.vue";
import { useToast } from "vue-toastification";

const $ = window.$;

@Options({
    components: {
        PlusIcon,
        ChatAltIcon,
        RecursiveSectionList
    },
    data() {
        return {
            id: this.$route.params.id,
            pod: {
                relationships: {
                    sections: [],
                    container: {
                        attributes: {
                            name: ""
                        }
                    }
                }
            },
            jots: [],
            submitting: false,
            is_default: false,
            videoSegment: null,
            infoSegments: [],
            ProductSegments: [],
        };
    },

    mounted() {
        $(document).ready(function () {
            let pathname = location.pathname;
            window.addEventListener("click", function () {
                if (location.pathname != pathname) {
                    pathname = location.pathname;
                    addAds();
                }
            });
            function addAds() {
                $("#banner-ad-1").html(
                    `<ins class="adsbygoogle" style="display: inline-block; width: 350px; height: 250px" data-ad-client="ca-pub-4386620569212817" data-ad-slot="5619297922"></ins>`
                );
                (window.adsbygoogle = window.adsbygoogle || []).push({});
                setTimeout(() => {
                    $("#banner-ad-2").html(
                        `<ins class="adsbygoogle" style="display: inline-block; width: 350px; height: 250px" data-ad-client="ca-pub-4386620569212817" data-ad-slot="5848409930"></ins>`
                    );
                    (window.adsbygoogle = window.adsbygoogle || []).push({});
                }, 500);
            }
            addAds();
        });
        if (this.id) this.fetchPod();
        else this.pod = null;
    },
    watch: {
        '$route': {
            immediate: true, // This ensures the watcher is triggered immediately
            handler(newVal, oldVal) {
                this.id = newVal.params.id ?? null;
                if (this.id){
                    if(newVal.name==='Container'){
                        this.fetchContainer(); // Fetch data when id is available    
                    }else{
                        this.fetchPod(); // Fetch data when id is available    
                    }
                    
                } else{
                    this.pod = null;
                    this.videoSegment = null;
                    this.infoSegments = [];
                    this.ProductSegments = [];
                    
                } 
            }
        }
    },
    methods: {
        fetchPod() {
            GetPodRightBar(this.id,1,1)
                .then((resp) => {
                    
                    this.pod = resp.data;
                    this.videoSegment = resp.data?.relationships?.video;
                    this.infoSegments = resp.data?.relationships?.info;
                    this.ProductSegments = resp.data?.relationships?.product;
                    const sections = this.pod.relationships.sections;
                    const childrenSections = flatMap(
                        sections,
                        (x) => x.relationships.children
                    );

                    const filteredSections = sections.filter((x) => {
                        return (
                            childrenSections.findIndex((y) => y.id == x.id) < 0
                        );
                    });

                    this.pod.relationships.sections = filteredSections;

                    if (!this.pod.attributes.topic_logo_image) {
                        this.pod.attributes.topic_logo_image = require("@/assets/images/logo-text.svg");
                    }

                    if (!this.pod.attributes.topic_profile_image) {
                        this.pod.attributes.topic_profile_image = require("@/assets/images/learning-pod.png");
                    }

                    // Store containerid and pod id to local storage for use  in create jot
                    const jotLocal = {
                        containerId: this.pod.relationships.container.id,
                        podId: this.pod.id,
                    };
                    localStorage.setItem("jotLocal", JSON.stringify(jotLocal));

                })
                .catch((err) => {
                    console.log(err)
                    this.pod = null;
                    // this.$router.go(-1);
                });
        },
        fetchContainer() {
            GetContainerSideBar(this.id, 1,1)
                .then((resp) => {
                    this.videoSegment = resp.data?.relationships?.video;
                    this.infoSegments = resp.data?.relationships?.info;
                    this.ProductSegments = resp.data?.relationships?.product;
                })
                .catch((err) => {
                    console.log(err)
                    this.pod = null;
                    // this.$router.go(-1);
                });
        },
        formatInfo(info) {
            return info.replace(/\n/g, '<br>');
        },
    },
})
export default class RightSideBar extends Vue {
    toast = setup(() => useToast());

}
</script>
<style scoped>
img {
    width: 100%;
}
</style>
