<template>
    <div class="space-y-6" v-if="this.author">
        <div class="bg-white shadow px-4 py-5 space-y-6 sm:rounded-lg sm:p-6">
            <div class="flex gap-6">
                <div>
                    <a v-on:click="this.$router.go(-1)" class="cursor-pointer">
                        <ArrowLeftIcon class="w-8" />
                    </a>
                </div>
                <div class="flex-1 space-y-4">
                    <div class="flex items-start space-x-5">
                        <div class="flex-shrink-0">
                            <div class="relative">
                                <img
                                    class="h-24 w-24 rounded-full"
                                    :src="this.author.attributes.avatar"
                                    alt="User author"
                                />
                                <span
                                    class="
                                        absolute
                                        inset-0
                                        shadow-inner
                                        rounded-full
                                    "
                                    aria-hidden="true"
                                />
                            </div>
                        </div>

                        <div class="space-y-1">
                            <h1 class="text-2xl font-bold text-gray-900">
                                {{ this.author.attributes.name }}
                            </h1>

                            <p class="text-sm font-medium text-gray-500">
                                Member since
                                {{ this.author.attributes.created_at }}
                            </p>

                            <button
                                class="
                                    text-white
                                    bg-blue-500
                                    rounded-full
                                    py-1
                                    px-3
                                    focus:outline-none
                                "
                                v-on:click="this.toggleFollow"
                            >
                                <span
                                    v-if="!this.author.attributes.is_following"
                                >
                                    Follow</span
                                >
                                <span
                                    v-if="this.author.attributes.is_following"
                                >
                                    Unfollow</span
                                >
                            </button>

                            <span
                                v-if="
                                    author.attributes.name !== 'Joticle Expert'
                                "
                            >
                                <button
                                    class="
                                        text-white
                                        bg-green-400
                                        rounded-full
                                        ml-5
                                        py-1
                                        px-3
                                        w-20
                                        focus:outline-none
                                    "
                                    v-if="isBlocked"
                                    @click="toggleBlockUser"
                                >
                                    Unblock
                                </button>
                                <button
                                    class="
                                        text-white
                                        bg-red-400
                                        rounded-full
                                        ml-5
                                        py-1
                                        px-3
                                        w-20
                                        focus:outline-none
                                    "
                                    v-else
                                    @click="toggleBlockUser"
                                >
                                    Block
                                </button>
                            </span>
                        </div>
                    </div>

                    <div
                        v-if="this.author.attributes.is_private"
                        class="text-center text-2xl py-4"
                    >
                        This account is private.
                    </div>

                    <div v-if="!this.author.attributes.is_private">
                        <div class="flex gap-3">
                            <a
                                v-if="this.author.attributes.twitter"
                                :href="'//' + this.author.attributes.twitter"
                                target="_blank"
                            >
                                <img
                                    src="@/assets/images/twitter.svg"
                                    alt="Twitter"
                                    class="w-8"
                                />
                            </a>
                            <a
                                v-if="this.author.attributes.facebook"
                                :href="'//' + this.author.attributes.facebook"
                                target="_blank"
                            >
                                <img
                                    src="@/assets/images/facebook.svg"
                                    alt="Facebook"
                                    class="w-8"
                                />
                            </a>
                            <a
                                v-if="this.author.attributes.linkedin"
                                :href="'//' + this.author.attributes.linkedin"
                                target="_blank"
                            >
                                <img
                                    src="@/assets/images/linkedin.svg"
                                    alt="Linkedin"
                                    class="w-8"
                                />
                            </a>
                            <a
                                v-if="this.author.attributes.pinterest"
                                :href="'//' + this.author.attributes.pinterest"
                                target="_blank"
                            >
                                <img
                                    src="@/assets/images/pinterest.svg"
                                    alt="Pinterest"
                                    class="w-8"
                                />
                            </a>
                            <a
                                v-if="this.author.attributes.instagram"
                                :href="'//' + this.author.attributes.instagram"
                                target="_blank"
                            >
                                <img
                                    src="@/assets/images/instagram.svg"
                                    alt="Instagram"
                                    class="w-8"
                                />
                            </a>
                            <a
                                v-if="this.author.attributes.storefront_url"
                                :href="
                                    '//' + this.author.attributes.storefront_url
                                "
                                target="_blank"
                            >
                                <img
                                    src="@/assets/images/storefront.svg"
                                    alt="Storefront"
                                    class="w-8"
                                />
                            </a>
                        </div>

                        <div class="py-2">
                            {{ this.author.attributes.bio }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Achievements
            v-if="achievements.length"
            :achievements="achievements"
            type="author"
        />

        <div class="text-left text-2xl">
            {{ this.author.attributes.name }}'s Jots
        </div>

        <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
            <div
                v-for="jot in personalJots"
                :key="jot.id"
                class="bg-white mb-4 px-4 shadow p-6 rounded-lg"
            >
                <article :aria-labelledby="'pod-title-' + jot.id">
                    <router-link :to="{ path: '/jot/' + jot.attributes.slug }">
                        <div>
                            <div
                                class="
                                    aspect-w-4 aspect-h-3
                                    w-full
                                    bg-no-repeat bg-center bg-cover
                                    rounded-lg
                                "
                                :style="`background-image: url(${jot.attributes.topic_profile_image})`"
                            ></div>
                        </div>
                        <div class="mt-2">
                            <div class="text-gray-400 text-xs">
                                {{
                                    jot.relationships.container.attributes.name
                                }}
                            </div>
                            <div class="text-gray-500">
                                {{ jot.relationships.pod.attributes.name }}
                            </div>
                            <div
                                :id="'pod-title-' + jot.id"
                                class="text-gray-900 font-light text-sm"
                            >
                                {{ jot.attributes.name }}
                            </div>
                        </div>
                    </router-link>
                    <router-link :to="{ path: '/jot/edit/' + jot.id }">
                        <div class="text-sm hover:text-blue-400">Edit</div>
                    </router-link>
                </article>
            </div>
        </div>
        <div class="my-8 justify-center flex" v-if="isBusy">
            <div class="animate-spin w-9">
                <RefreshIcon class="flip" />
            </div>
        </div>

        <div class="my-8 justify-center flex" v-if="hasReachedBottom">
            <div>End of list.</div>
        </div>
    </div>
</template>

<script>
import {
    FollowUser,
    GetCurrentUser,
    GetJotsByAuthor,
    GetUserById,
    UnfollowUser,
    GetUserAchievementsById,
} from "@/services/user.service";
import { Options, Vue } from "vue-class-component";
import * as moment from "moment";
import { uniqBy } from "lodash";
import { RefreshIcon } from "@heroicons/vue/solid";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import Achievements from "@/components/Achievements.vue";
import { isUserBlocked } from "@/utils";

@Options({
    components: {
        RefreshIcon,
        ArrowLeftIcon,
        Achievements,
    },
    data() {
        return {
            id: this.$route.params.id,
            author: null,
            currentUser: null,
            achievements: [],
            personalJots: [],
            page: 1,
            size: 12,
            isBusy: false,
            hasReachedBottom: false,
            isBlocked: false,
        };
    },
    async mounted() {
        try {
            this.fetchAuthorInfo();
            this.fetchAuthorJots();
            this.currentUser = (await GetCurrentUser()).data;
            this.fetchUserAchievementsById();
            this.isBlocked = isUserBlocked(this.author.id);
        } catch (error) {
            console.error("🚀 ~ err", error);
        }

        window.addEventListener("scroll", (ev) => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.offsetHeight &&
                !this.hasReachedBottom
            ) {
                this.fetchAuthorJots();
            }
        });
    },
    methods: {
        toggleBlockUser() {
            let blockedUsers = localStorage.getItem("blockedUsers");
            blockedUsers = blockedUsers ? JSON.parse(blockedUsers) : [];
            if (this.author.attributes.name === "Joticle Expert") {
                return;
            }
            if (blockedUsers && blockedUsers.includes(this.author.id)) {
                blockedUsers = blockedUsers.filter(
                    (userId) => userId !== this.author.id
                );
            } else {
                blockedUsers.push(this.author.id);
            }
            localStorage.setItem("blockedUsers", JSON.stringify(blockedUsers));
            this.isBlocked = !this.isBlocked;
        },
        async fetchUserAchievementsById() {
            this.achievements = (await GetUserAchievementsById(this.id)).data;
        },
        async fetchAuthorInfo() {
            this.author = (await GetUserById(this.id)).data;
            this.author.attributes.created_at = moment(
                this.author.attributes.created_at
            ).format("MM/DD/YYYY");
        },
        async fetchAuthorJots() {
            if (this.isBusy) {
                return;
            }
            this.isBusy = true;

            let jots = (
                await GetJotsByAuthor(this.id, {
                    page: this.page,
                    size: this.size,
                    dir: "asc",
                    order_by: "created_at",
                })
            ).data;
            jots = jots.filter(
                (jot) => !isUserBlocked(jot.relationships.author.id)
            );

            this.page += 1;
            this.personalJots.push(
                ...jots.map((jot) => {
                    let topic_profile_image = jot.attributes.images[0]?.url;

                    if (!topic_profile_image) {
                        if (jot.attributes.content_type === "story") {
                            topic_profile_image = require("@/assets/images/jot-text.png");
                        } else if (jot.attributes.content_type === "video") {
                            topic_profile_image = require("@/assets/images/jot-video.png");
                        } else if (jot.attributes.content_type === "audio") {
                            topic_profile_image = require("@/assets/images/jot-audio.png");
                        }
                    }

                    return {
                        ...jot,
                        attributes: {
                            ...jot.attributes,
                            topic_profile_image,
                        },
                    };
                })
            );

            this.personalJots = uniqBy(this.personalJots, (x) => x.id);

            this.hasReachedBottom = jots.length < this.size;
            this.isBusy = false;
        },
        async toggleFollow() {
            if (this.author.id == this.currentUser.id) {
                return;
            }
            if (this.author.attributes.is_following) {
                await UnfollowUser(this.author.id);
            } else {
                await FollowUser(this.author.id);
            }

            this.fetchAuthorInfo();
        },
    },
    metaInfo() {
        return {
            title: this.author?.attributes?.name,
        };
    },
})
export default class Author extends Vue {}
</script>
