import { HttpClient, IPaginationPayload } from "@/core/http-client";
import { IContainer } from "@/interfaces/container.interface";

export const GetContainers = (
    pagination: IPaginationPayload = {
        size: 99999,
    }
) => {
    
    return new Promise((resolve, reject) => {
        HttpClient()
            .get<IContainer[]>("/api/v1/containers", {
                params: {
                    ...pagination,
                },
            })
            .then((resp) => {
                resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const GetContainerById = (id: number,page: number,size: number) => {
    return HttpClient().get<IContainer>("/api/v1/containers/" + id, {
        params: {
            include: "pods",
            page:page,
            size:size
        },
    });
};
export const GetContainerSideBar = (id: number,page: number,size: number) => {
    return HttpClient().get<IContainer>("/api/v1/containers/" + id, {
        params: {
            include: "pods",
            page:page,
            size:size
        },
    });
};