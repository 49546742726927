<template>
    <div class="hidden lg:block lg:col-span-3 xl:col-span-2">
        <nav aria-label="Sidebar" class="sticky top-4 divide-y divide-gray-300">

            <div class="pb-4 space-y-1">
                <router-link v-for="item in navigation" :key="item.name"
                    :to="{ path: item.href, query: { tag: item.query } }" :class="[
                        item.current
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50',
                        'group flex items-center px-3 py-2 text-sm font-medium rounded-md',
                        item.name === 'Create Jot' &&
                        'shadow-md ::before ::after',
                    ]" :aria-current="item.current ? 'page' : undefined">
                    <component :is="item.icon" :class="[
                        item.current ? 'text-gray-500' : 'text-gray-400',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-blue-500',
                    ]" aria-hidden="true" />
                    <span class="truncate">
                        {{ item.name }}
                    </span>
                </router-link>
            </div>

            <div class="pt-5">
                <div class="
                        px-3
                        text-xs
                        font-semibold
                        text-gray-500
                        uppercase
                        tracking-wider
                        pb-3
                    ">
                    Core Learning
                </div>
                <div class="pb-4 space-y-1">
                    <router-link v-for="coreCategory in NavCoreLearning" :key="coreCategory.id"
                        :to="{ path: '/container/' + coreCategory.attributes.slug }" class="
                            group
                            flex
                            items-center
                            px-3
                            py-2
                            text-sm
                            font-medium
                            text-gray-600
                            rounded-md
                            hover:text-gray-900 hover:bg-green-100
                        ">
                        <span class="truncate">
                            <span v-html="formattedCoreLearning(coreCategory.attributes.name)"></span>
                            <!-- <span class="font-bold">S</span>cience -->
                        </span>
                    </router-link>
                </div>
            </div>

            <div class="pt-5">
                <div class="
                        px-3
                        text-xs
                        font-semibold
                        text-gray-500
                        uppercase
                        tracking-wider
                        pb-3
                    ">
                    Categories
                </div>
                <div class="pb-4 space-y-1">
                    <router-link v-for="category in NavCategories" :key="category.id"
                        :to="{ path: '/container/' + category.attributes.slug }"
                        class="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-green-100">
                        <span class="truncate">
                            {{ category.attributes.name }}
                        </span>
                    </router-link>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import {
    FireIcon,
    HomeIcon,
    TrendingUpIcon,
    UserGroupIcon,
    HeartIcon,
    PlusIcon,
    DocumentTextIcon,
    CheckCircleIcon,
} from "@heroicons/vue/outline";
import { GetLocalToken } from "@/services/auth.service";
import { GetContainers } from "@/services/container.service";
@Options({
    components: {
        FireIcon,
        HomeIcon,
        TrendingUpIcon,
        UserGroupIcon,
        HeartIcon,
        PlusIcon,
        DocumentTextIcon,
        CheckCircleIcon,
    },
    data() {
        const localToken = GetLocalToken();
        let navigation = [
            {
                name: "Home",
                href: "/feeds",
                icon: HomeIcon,
                current: false,
            },

            {
                name: "Create Jot",
                href: localToken?.access_token ? "/jot/create" : "/register",
                icon: PlusIcon,
                current: false,
            },

            // {
            //     name: "Popular",
            //     href: "/",
            //     query: "NEW",
            //     icon: FireIcon,
            //     current: false,
            // },
            {
                name: "Trending",
                href: "/feeds",
                query: "TRENDING",
                icon: TrendingUpIcon,
                current: false,
            },
        ];
        if (localToken?.access_token) {
            navigation.splice(
                1,
                0,
                ...[
                    {
                        name: "My Jots",
                        href: "/my-jots",
                        icon: DocumentTextIcon,
                        current: false,
                    },
                ]
            );

            navigation.push(
                ...[
                    {
                        name: "Following",
                        href: "/following",
                        icon: UserGroupIcon,
                        current: false,
                    },
                    {
                        name: "Inspired Jots",
                        href: "/liked-jots",
                        icon: FireIcon,
                        current: false,
                    },
                    // {
                    //     name: "Contributor",
                    //     href: "/subscription",
                    //     icon: CheckCircleIcon,
                    //     current: false,
                    // },
                ]
            );
        }

        return {
            navigation,
            categories: [],
        };
    },
    computed: {
        NavCategories() {
            return this.categories.filter(category => category.attributes.section === 0);
        },
        NavCoreLearning() {
            return this.categories.filter(category => category.attributes.section === 1);
        }
    },
    methods:{
        formattedCoreLearning(catName) {
            if (!catName) return '';
            const firstLetter = catName.charAt(0);
            const restOfString = catName.slice(1);
            const formattedText = `<span class="font-bold">${firstLetter}</span>${restOfString}`;
            return formattedText;
        }
    },
    async mounted() {
        this.categories = (await GetContainers()).data.sort(
            (x) => x.attributes.name
        );
    },
})
export default class LeftSideBar extends Vue { }
</script>
