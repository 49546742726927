<template>
    <div>  
        <div class="text-center pb-6 flex flex-col gap-4 overflow-hidden" v-if="this.isReady">
            <div v-if="this.user">
                <Slider v-if="bannerSlides.length > 0" :slides="bannerSlides"/>
            </div>
        
            <router-link :to="{ path: '/jot/create' }" v-if="this.user && bannerSlides.length == 0">
                <img
                    src="@/assets/images/create-a-jot-banner.png"
                    alt="Create a Jot"
                    class="w-full"
                />
            </router-link>

            <router-link :to="{ path: 'register' }" v-if="!this.user">
                <img
                    src="@/assets/images/join-us-now-banner.png"
                    alt="Join Us Now"
                    class="w-full"
                />
            </router-link>
        </div>
        <div class="px-4 sm:px-0 sticky top-0 z-10">
            <div class="sm:hidden">
                <label for="pod-tabs" class="sr-only">Select a tab</label>
                <select
                    id="pod-tabs"
                    class="
                        block
                        w-full
                        rounded-md
                        border-gray-300
                        text-base
                        font-medium
                        text-gray-900
                        shadow-sm
                        focus:border-blue-500 focus:ring-blue-500
                    "
                >
                    <option
                        v-for="tab in tabs"
                        :key="tab.name"
                        :selected="tab.current"
                    >
                        {{ tab.name }}
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav
                    class="
                        relative
                        z-0
                        rounded-lg
                        shadow
                        flex
                        divide-x divide-gray-200
                    "
                    aria-label="Tabs"
                >
                    <router-link
                        v-for="(tab, tabIdx) in tabs"
                        :key="tab.name"
                        :to="{ path: '/feeds', query: { tag: tab.tag } }"
                        :aria-current="tab.current ? 'page' : undefined"
                        :class="[
                            tab.current
                                ? 'text-gray-900'
                                : 'text-gray-500 hover:text-gray-700',
                            tabIdx === 0 ? 'rounded-l-lg' : '',
                            tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                            'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
                        ]"
                    >
                        <span>{{ tab.name }}</span>
                        <span
                            aria-hidden="true"
                            :class="[
                                tab.current ? 'bg-blue-500' : 'bg-transparent',
                                'absolute inset-x-0 bottom-0 h-0.5',
                            ]"
                        />
                    </router-link>
                </nav>
            </div>
        </div>
        <div class="mt-4">
            <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                <div
                    v-for="pod in pods"
                    :key="pod.id"
                    class="bg-white mb-4 px-4 shadow p-6 rounded-lg"
                >
                    <router-link :to="{ path: '/pod/' + pod.attributes.slug }">
                        <article :aria-labelledby="'pod-title-' + pod.id">
                            <div>
                                <div
                                    class="
                                        w-full
                                        bg-no-repeat bg-center bg-cover
                                        rounded-lg
                                        aspect-w-4 aspect-h-3
                                    "
                                    :style="`background-image: url(${pod.attributes.topic_profile_image})`"
                                ></div>
                            </div>
                            <div class="mt-2">
                                <div class="flex justify-between">
                                    <div class="text-gray-400 text-xs">
                                        {{
                                            pod.relationships.container
                                                .attributes.name
                                        }}
                                    </div>
                                    <div
                                        class="
                                            text-blue-400 text-xs
                                            flex
                                            align-center
                                        "
                                    >
                                        Open Pod
                                        <ChevronRightIcon
                                            class="inline-flex w-4 h-4"
                                        />
                                    </div>
                                </div>
                                <div
                                    :id="'pod-title-' + pod.id"
                                    class="text-l text-gray-900 font-light"
                                >
                                    {{ pod.attributes.name }}
                                </div>
                            </div>
                        </article>
                    </router-link>
                </div>
                <div
                    v-for="jot in personalJots"
                    :key="jot.id"
                    class="bg-white mb-4 px-4 shadow p-6 rounded-lg"
                >
                    <article :aria-labelledby="'pod-title-' + jot.id">
                        <router-link
                            :to="{ path: '/jot/' + jot.attributes.slug }"
                        >
                            <div>
                                <div
                                    class="
                                        aspect-w-4 aspect-h-3
                                        w-full
                                        bg-no-repeat bg-center bg-cover
                                        rounded-lg
                                    "
                                    :style="`background-image: url(${jot.attributes.topic_profile_image})`"
                                ></div>
                            </div>
                            <div class="mt-2" style="min-height: 100px;">
                                <div class="text-gray-400 text-xs" style="display:none">
                                    {{
                                        jot.relationships.container.attributes
                                            .name
                                    }}
                                </div>
                                <div class="text-gray-500 text-xs">
                                    <strong>{{ jot.relationships.pod.attributes.name }}</strong>
                                </div>
                                <div
                                    :id="'pod-title-' + jot.id"
                                    class="text-gray-900 font-light text-sm"
                                >
                                    {{ jot.attributes.name }}
                                </div>
                            </div>
                            <div class="flex items-end justify-end tooltip" v-if="jot?.attributes?.content_type">
                  
                                <svg v-if="jot?.attributes?.content_type === 'video'" xmlns="http://www.w3.org/2000/svg" width="20"
                                    height="20" viewBox="0 0 90 90">
                                    <path
                                    d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0zM62.251 46.633L37.789 60.756c-1.258 0.726-2.829-0.181-2.829-1.633V30.877c0-1.452 1.572-2.36 2.829-1.634l24.461 14.123C63.508 44.092 63.508 45.907 62.251 46.633z"
                                    fill="#000" />
                                </svg>
                                <svg v-if="jot?.attributes?.content_type === 'story'" xmlns="http://www.w3.org/2000/svg" width="20"
                                  height="20" viewBox="0 0 80 80">
                                  <path
                                    d="M77.474 17.28L61.526 1.332C60.668 0.473 59.525 0 58.311 0H15.742c-2.508 0-4.548 2.04-4.548 4.548v80.904c0 2.508 2.04 4.548 4.548 4.548h58.516c2.508 0 4.549-2.04 4.549-4.548V20.496C78.807 19.281 78.333 18.138 77.474 17.28zM61.073 5.121l12.611 12.612H62.35c-0.704 0-1.276-0.573-1.276-1.277V5.121zM15.742 3h42.332v13.456c0 2.358 1.918 4.277 4.276 4.277h13.457v33.2H14.194V4.548C14.194 3.694 14.888 3 15.742 3zm58.516 80H15.742c-0.854 0-1.548-0.694-1.548-1.548V56.934h61.613v28.519C75.807 86.306 75.112 87 74.258 87z"
                                    fill="#000" />
                                  <path
                                    d="M34.604 62.687h-8.816c-0.829 0-1.5 0.672-1.5 1.5s0.671 1.5 1.5 1.5h2.908v15.164c0 0.828 0.671 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.908c0.829 0 1.5-0.672 1.5-1.5S35.432 62.687 34.604 62.687z"
                                    fill="#000" />
                                  <path
                                    d="M46.69 72.519l4.021-7.599c0.387-0.731 0.107-1.64-0.625-2.027c-0.729-0.385-1.639-0.107-2.027 0.625l-3.065 5.794l-3.066-5.794c-0.388-0.732-1.294-1.012-2.027-0.625c-0.732 0.388-1.012 1.296-0.624 2.027l4.02 7.599l-4.02 7.599c-0.388 0.731-0.108 1.64 0.624 2.027c0.224 0.118 0.464 0.174 0.7 0.174c0.538 0 1.058-0.29 1.327-0.799l3.066-5.794l3.065 5.794c0.27 0.509 0.789 0.799 1.327 0.799c0.237 0 0.477-0.056 0.7-0.174c0.732-0.388 1.012-1.296 0.625-2.027L46.69 72.519z"
                                    fill="#000" />
                                  <path
                                    d="M64.212 62.687h-8.815c-0.828 0-1.5 0.672-1.5 1.5s0.672 1.5 1.5 1.5h2.908v15.164c0 0.828 0.672 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.907c0.828 0 1.5-0.672 1.5-1.5S65.04 62.687 64.212 62.687z"
                                    fill="#000" />
                                </svg>
              
                                <svg v-if="jot?.attributes?.content_type === 'audio'" xmlns="http://www.w3.org/2000/svg" width="20"
                                  height="20" viewBox="0 0 80 80">
                                  <path
                                    d="M64.293 90c-0.493 0-0.979-0.183-1.356-0.53L35.986 64.59H6.526c-1.105 0-2-0.896-2-2V27.41c0-1.105 0.896-2 2-2h29.46L62.937 0.53c0.585-0.539 1.433-0.679 2.158-0.362C65.823 0.486 66.293 1.205 66.293 2V88c0 0.794-0.47 1.514-1.198 1.832C64.837 89.946 64.565 90 64.293 90z"
                                    fill="#000" />
                                  <path
                                    d="M76.42 73.908c-0.467 0-0.937-0.163-1.315-0.494c-0.832-0.728-0.916-1.991-0.189-2.822C78.9 66.037 81.474 55.991 81.474 45S78.9 23.963 74.915 19.408c-0.727-0.832-0.643-2.095 0.189-2.822c0.83-0.728 2.095-0.643 2.822 0.188c4.655 5.323 7.547 16.138 7.547 28.225c0 12.087-2.892 22.903-7.547 28.225C77.532 73.677 76.977 73.908 76.42 73.908z"
                                    fill="#000" />
                                  <path
                                    d="M72.02 62.623c-0.321 0-0.647-0.077-0.949-0.241c-0.972-0.525-1.333-1.739-0.808-2.71c1.851-3.421 2.955-8.906 2.955-14.672c0-5.765-1.104-11.25-2.955-14.672c-0.525-0.972-0.164-2.186 0.808-2.711c0.969-0.526 2.186-0.165 2.71 0.808c2.185 4.039 3.438 10.08 3.438 16.575c0 6.495-1.253 12.536-3.438 16.574C73.419 62.243 72.731 62.623 72.02 62.623z"
                                    fill="#000" />
                                </svg>
                                <Tooltip :content="jot?.attributes?.content_type"/>
                            </div>
                        </router-link>
                    </article>
                </div>
            </div>

            <div class="my-8 justify-center flex" v-if="isBusy">
                <div class="animate-spin w-9">
                    <RefreshIcon class="flip" />
                </div>
            </div>

            <div class="my-8 justify-center flex" v-if="hasReachedBottom">
                <div>Click on a category to view more.</div>
            </div>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
    ChatAltIcon,
    CodeIcon,
    DotsVerticalIcon,
    EyeIcon,
    FlagIcon,
    ShareIcon,
    StarIcon,
    ThumbUpIcon,
    RefreshIcon,
    ChevronRightIcon,
} from "@heroicons/vue/solid";
import { GetPods, GetPodsByTag } from "@/services/pod.service";
import Slider from "@/components/Slider.vue";
import { GetJots } from "@/services/jot.service";
import { GetBanners } from "@/services/jot.service";
import { uniqBy } from "lodash";
import { GetCurrentUser } from "@/services/user.service";
import { isUserBlocked } from "@/utils";
import Tooltip from "@/components/Tooltip.vue";
@Options({
    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        //Icons
        DotsVerticalIcon,
        StarIcon,
        CodeIcon,
        FlagIcon,
        ThumbUpIcon,
        ChatAltIcon,
        EyeIcon,
        ShareIcon,
        RefreshIcon,
        ChevronRightIcon,
        Slider,
        Tooltip,
    },
    created() {
        this.fetchUser();
        this.fetchBanners();
    },
    mounted() {
        const tag = this.$route.query.tag;
        if (!tag) {
            this.$router.push("/feeds/?tag=PERSONAL");
        } else {
            this.fetchPodsByTag(tag);
            this.tabs.forEach((tab) => {
                tab.current = tab.tag == tag;
            });
        }
        localStorage.removeItem("jotLocal");
        window.addEventListener("scroll", () => {
            if (
                window.innerHeight + window.scrollY >=
                    document.body.offsetHeight &&
                !this.hasReachedBottom
            ) {
                this.fetchPodsByTag(this.$route.query.tag);
            }
        });
    },
    methods: {    
        
        fetchPodsByTag(tag) {
            if (this.isBusy) {
                return;
            }
            this.isBusy = true;

            let request;

            if (tag === "PERSONAL") {
                GetJots({
                    page: this.page,
                    size: this.size,
                    dir: "desc",
                    order_by: "created_at",
                }).then((resp) => {
                    resp.data = resp.data.filter(
                        (jot) => !isUserBlocked(jot.relationships.author.id)
                    );
                    this.page += 1;
                    this.personalJots.push(
                        ...resp.data.map((jot) => {
                            let topic_profile_image =
                                jot.attributes.images[0]?.url;

                            if (!topic_profile_image) {
                                if (jot.attributes.content_type === "story") {
                                    topic_profile_image = require("@/assets/images/jot-text.png");
                                } else if (
                                    jot.attributes.content_type === "video"
                                ) {
                                    topic_profile_image = require("@/assets/images/jot-video.png");
                                }
                                else if (
                                    jot.attributes.content_type === "audio"
                                ) {
                                    topic_profile_image = require("@/assets/images/jot-audio.png");
                                }
                            }

                            return {
                                ...jot,
                                attributes: {
                                    ...jot.attributes,
                                    topic_profile_image,
                                },
                            };
                        })
                    );

                    this.personalJots = uniqBy(this.personalJots, (x) => x.id);

                    this.hasReachedBottom = resp.data.length < this.size;
                    this.isBusy = false;
                });

                return;
            } else if (tag === "NEW") {
                request = GetPods({
                    page: this.page,
                    size: this.size,
                    order_by: "created_at",
                    dir: "desc",
                });
            } else {
                request = GetPodsByTag(tag, {
                    page: this.page,
                    size: this.size,
                });
            }

            request
                .then((resp) => {
                    if (resp.data.length) {
                        this.page += 1;
                        this.pods.push(
                            ...resp.data.map((pod) => {
                                return {
                                    ...pod,
                                    attributes: {
                                        ...pod.attributes,
                                        topic_profile_image:
                                            pod.attributes
                                                .topic_profile_image ||
                                            require("@/assets/images/learning-pod.png"),
                                    },
                                };
                            })
                        );
                    }

                    this.pods = uniqBy(this.pods, (x) => x.id);

                    this.hasReachedBottom = resp.data.length < this.size;
                    this.isBusy = false;
                })
                .catch((err) => {
                    console.error("🚀 ~ err", err);
                    this.hasReachedBottom = true;
                    this.isBusy = false;
                });
        },
        fetchUser() {
            GetCurrentUser()
                .then((resp) => {
                    this.user = resp.data.attributes;
                    this.isReady = true;

                    if (this.user.default_pod_id) {
                        this.$router.push(
                            "/pod/" + this.user.default_pod_id.toString()
                        );
                    }
                })
                .catch((err) => {
                    console.error("🚀 ~ err", err);
                    this.isReady = true;
                });
        },
        fetchBanners() {
            
            GetBanners()
                .then((resp) => {
                    this.bannerSlides=resp.data;
                })
                .catch((err) => {
                    console.error("🚀 ~ err", err);
                });
        },
    },
    data() {
        const tabs = [
            {
                name: "Trending Pods",
                current: false,
                tag: "TRENDING",
            },
            { name: "New Pods", current: false, tag: "NEW" },
            {
                name: "Latest Jots",
                current: true,
                tag: "PERSONAL",
            },
        ];

        return {
            tabs,
            pods: [],
            personalJots: [],
            page: 1,
            size: 21,
            isBusy: false,
            hasReachedBottom: false,
            user: null,
            isReady: false,
            bannerSlides: []
        };
    },
    watch: {
        "$route.query.tag": function (tag) {
            if (tag) {
                this.page = 1;
                this.pods = [];
                this.personalJots = [];
                this.hasReachedBottom = false;
                this.tabs.forEach((tab) => {
                    tab.current = tab.tag == tag;
                });

                this.fetchPodsByTag(tag);
            }
        },
    },
    metaInfo() {
        return {
            title: this.tabs.find((x) => x.current)?.name,
        };
    },
})
export default class Feed extends Vue {}
</script>
