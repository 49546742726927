<template>
    <div
        class="grid place-items-center bg-overlay"
        :style="{
            'background-image': `url(${require('@/assets/images/hands.png')}) `,
            height: '250px',
        }"
    >
        <div class="space-y-4 text-center text-white">
            <div class="text-4xl">Investor Relations</div>
            <div>Home / Investor Relations</div>
        </div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 space-y-10">
        <section>
            <iframe
                width="100%"
                height="600"
                src="https://www.youtube.com/embed/vX-mMMCJmvM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </section>
        <div class="space-y-4">
            <div class="font-nunito font-bold text-3xl">WeFunder</div>
            <div class="space-y-4">
                <div>
                    Joticle was founded in 2017 by Scott Wilson inspired by the
                    frustrations of learning tools available to research and
                    discover a topic when he was learning a new programming
                    language, accompanied by the insights by co-founder Jeff
                    Stoll we have made a major pivot to a centralized lifelong
                    learning platform for educators, experts, students, and
                    hobbyists.
                </div>

                <div>
                    Currently, we are shaping the early stage of lifelong
                    learning working with elementary and middle school teachers
                    to help reduce the pain points currently found with the lack
                    of centralized tools
                </div>

                <div>
                    2022 is the year we focus on our teacher's first philosophy
                    expanding our academic advisory board, enhancing our
                    web/mobile platform, soon launching our Meta VRIL (Virtual
                    Reality Immersion Library) and also continue the push to
                    make lifelong learning accessible to everybody.
                </div>

                <div>
                    With that, we are thrilled to invite our community to invest
                    in our mission, we want to share this journey with you.
                </div>
                <div>
                    Check out our Wefunder pitch to see the details and invest:
                    <a href="https://wefunder.com/joticle" target="_blank"
                        >https://wefunder.com/joticle</a
                    >
                </div>
                <div>We are just getting started!</div>
            </div>
        </div>

        <div
            class="
                border border-dashed
                rounded-xl
                py-4
                md:flex
                items-center
                gap-12
            "
        >
            <div>
                <img
                    src="@/assets/images/megaphone.svg"
                    alt="MegaPhone"
                    style="width: 250px"
                />
            </div>
            <div class="font-lora p-10 md:pr-10">
                We are 'testing the waters' to gauge investor interest in an
                offering under Regulation Crowdfunding. No money or other
                consideration is being solicited. If sent, it will not be
                accepted. No offer to buy securities will be accepted. No part
                of the purchase price will be received until a Form C is filed
                and only through Wefunder's platform. Any indication of interest
                involves no obligation or commitment of any kind.
            </div>
        </div>

        <!-- <div class="shadow-2xl py-6 px-10 rounded-xl">
            <div class="font-nunito font-bold text-3xl">Reach out to us</div>
            <form action="#" class="space-y-6 mt-4">
                <div class="md:flex space-y-4 md:space-y-0 gap-6">
                    <div class="flex-1">
                        <div>First Name*:</div>
                        <input
                            type="text"
                            placeholder="First Name"
                            class="reach-us-form-item"
                        />
                    </div>
                    <div class="flex-1">
                        <div>Last Name*:</div>
                        <input
                            type="text"
                            placeholder="Last Name"
                            class="reach-us-form-item"
                        />
                    </div>
                </div>
                <div class="md:flex space-y-4 md:space-y-0 gap-6">
                    <div class="flex-1">
                        <div>Company Name*:</div>
                        <input
                            type="text"
                            placeholder="Company Name"
                            class="reach-us-form-item"
                        />
                    </div>
                    <div class="flex-1">
                        <div>Email Address*:</div>
                        <input
                            type="email"
                            placeholder="Email Address"
                            class="reach-us-form-item"
                        />
                    </div>
                </div>
                <div class="md:flex space-y-4 md:space-y-0 gap-6">
                    <div class="flex-1">
                        <div>Phone Number:</div>
                        <input
                            type="tel"
                            placeholder="Phone number"
                            class="reach-us-form-item"
                        />
                    </div>
                    <div class="flex-1">
                        <div>State/Province*:</div>
                        <select class="reach-us-form-item">
                            <option value="#"></option>
                        </select>
                    </div>
                    <div class="flex-1">
                        <div>Country*:</div>
                        <select class="reach-us-form-item">
                            <option value="#"></option>
                        </select>
                    </div>
                </div>
                <div class="space-y-2">
                    <div>Are you an investor?*</div>

                    <div class="md:flex space-y-4 md:space-y-0 gap-6">
                        <label class="flex items-center gap-2">
                            <input type="radio" name="investor" />
                            I am an investor
                        </label>
                        <label class="flex items-center gap-2">
                            <input type="radio" name="investor" />
                            I am not an investor
                        </label>
                    </div>
                </div>

                <div class="md:w-2/4">
                    <div>Comments*</div>
                    <textarea
                        placeholder="Comments"
                        class="reach-us-form-item"
                        rows="5"
                    ></textarea>
                </div>

                <div>
                    <button class="bg-blue-600 py-2 px-8 rounded-xl text-white hover:bg-blue-800 w-full md:w-auto">
                        Submit
                    </button>
                </div>
            </form>
        </div> -->
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
    components: {},
    metaInfo() {
        return {
            title: "Investor Relations",
        };
    },
})
export default class InvestorRelations extends Vue {}
</script>

<style></style>
