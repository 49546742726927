import {
    HttpClient,
    IHTTPDataResponse,
    IPaginationPayload,
} from "@/core/http-client";
import { IPod } from "@/interfaces/pod.interface";
import { AxiosResponse } from "axios";

export const GetContent = (page: string) => {
    return HttpClient().get<IPod>(`/api/v1/content/${page}`, {
        params: {
            include: "",
        },
    });
};
