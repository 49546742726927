<template>
    <div class="slider relative h-59 overflow-hidden" @mouseenter="pauseAutoSlide" @mouseleave="resumeAutoSlide">
        <div :key="currentSlide" class="slide">
            <a :href="slides[currentSlide].link" >
                <img :src="slides[currentSlide].media[0].original_url" :alt="slides[currentSlide].alt_text" class="object-cover w-full h-59">
            </a>
        </div>
        <ul class="absolute inset-x-0 bottom-0 flex justify-center space-x-2 pb-2">
            <li v-for="(slide, index) in slides" :key="index" @click="jumpSlide(index)" :class="{ 'bg-gray-800': index === currentSlide, 'bg-gray-300': index !== currentSlide }" class="h-2 w-2 rounded-full cursor-pointer"></li>
        </ul>
    </div>
</template>

<script>
import { Vue, Options } from "vue-class-component";


@Options({
    props: ["slides"],
    components: {},
    mounted() {
        this.startAutoSlide();
    },
    data() {
        return {
            currentSlide: 0
        };
    },
    methods: {
        jumpSlide(index) {
            this.currentSlide = index;
            this.resetAutoSlide();
        },
        startAutoSlide() {
            this.autoSlideInterval = setInterval(() => {
            this.currentSlide = (this.currentSlide + 1) % this.slides.length;
            }, 2000); // Change 2000 to the desired interval in milliseconds (2 seconds in this case)
        },
        resetAutoSlide() {
            clearInterval(this.autoSlideInterval);
            this.startAutoSlide();
        },
        pauseAutoSlide() {
            clearInterval(this.autoSlideInterval);
        },
        resumeAutoSlide() {
            this.resetAutoSlide();
        },
    },
    beforeDestroy() {
        clearInterval(this.autoSlideInterval);
    },
    watch: {},
})
export default class Modal extends Vue {}
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>