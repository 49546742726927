import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/scss/app.scss";

//custom components
import Header from "@/components/Header.vue";
import LeftSideBar from "@/components/LeftSideBar.vue";
import RightSideBar from "@/components/RightSideBar.vue";
import SubHeader from "@/components/SubHeader.vue";
import Tags from "@/components/Tags.vue";
import RecursiveSectionList from "@/components/RecursiveSectionList.vue";
import GlossaryDetail from "@/components/GlossaryDetail.vue";
import Modal from "@/components/Modal.vue";
import Select2 from "@/components/Select2.vue";
import FileUpload from "@/components/FileUpload.vue";
import ProfileForm from "@/components/ProfileForm.vue";
import PasswordForm from "@/components/PasswordForm.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import Footer from "@/components/Footer.vue";
import FooterDark from "@/components/FooterDark.vue";

//third party components
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
// import YouTube from "vue3-youtube";
import Editor from "@tinymce/tinymce-vue";
import { createMetaManager } from "vue-meta";
import { plugin as vueMetaPlugin } from "vue-meta";

const app = createApp(App);
app.config.globalProperties.$globalVariable = {
    jotLevels:[
        {"id": 0, "title": "All"},
        {"id": 1, "title": "Beginner"},
        {"id": 2, "title": "Intermediate"},
        {"id": 3, "title": "Advanced"},
        {"id": 4, "title": "Expert"}
    ]
};
app.use(router).use(createMetaManager()).mount("#app");
app.use(vueMetaPlugin);

//register global components
const customComponents = [
    Header,
    LeftSideBar,
    RightSideBar,
    SubHeader,
    Tags,
    RecursiveSectionList,
    Modal,
    GlossaryDetail,
    Select2,
    Editor,
    FileUpload,
    ProfileForm,
    PasswordForm,
    BreadCrumbs,
    Footer,
    FooterDark,
];

customComponents.forEach((component) => {
    app.component(component.name, component);
});

//register third party components
app.use(Toast, {
    position: POSITION.TOP_RIGHT,
});

// app.component("YouTube", YouTube);
