<template>
  <div v-if="this.listItem">
    <div class="bg-white mb-6 px-4 shadow sm:p-6 sm:rounded-lg">
      <SubHeader :title="this.listItem.attributes.name" class="mb-6" :imgSrc="this.listItem.relationships.pod.attributes.topic_logo_image
        " :backStep="this.backStep" />

      <BreadCrumbs :crumbs="[
        {
          id: this.listItem.relationships.container.id,
          title: this.listItem.relationships.container.attributes
            .name,
          url:
            '/container/' +
            this.listItem.relationships.container.attributes
              .slug,
        },
        {
          id: this.listItem.relationships.pod.id,
          title: this.listItem.relationships.pod.attributes.name,
          url:
            '/pod/' +
            this.listItem.relationships.pod.attributes.slug,
        },
        ...this.sectionsBreadCrumb,
      ]" />

      <div class="text-3xl mb-4 font-bold">
        {{ this.listItem.attributes.name }}
      </div>

      <!-- <Tags :tags="this.listItem.relationships.tags" /> -->

      <div v-html="this.listItem.attributes.article"></div>
    </div>
    <div>
      <div class="px-4 sm:px-0 sticky top-0 z-10">
        <div class="sm:hidden">
          <label for="pod-tabs" class="sr-only">Select a tab</label>
          <select id="pod-tabs" class="
                            block
                            w-full
                            rounded-md
                            border-gray-300
                            text-base
                            font-medium
                            text-gray-900
                            shadow-sm
                            focus:border-blue-500 focus:ring-blue-500
                        ">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="hidden sm:block">
          <nav class="
                            relative
                            z-0
                            rounded-lg
                            shadow
                            flex
                            divide-x divide-gray-200
                        " aria-label="Tabs">
            <router-link v-for="(tab, tabIdx) in tabs" :key="tab.name" :to="{
              path: `/list-item/${this.attributes.slug}/`,
              query: { tag: tab.tag },
            }" :aria-current="tab.current ? 'page' : undefined" :class="[
                              tab.current
                                ? 'text-gray-900'
                                : 'text-gray-500 hover:text-gray-700',
                              tabIdx === 0 ? 'rounded-l-lg' : '',
                              tabIdx === tabs.length - 1
                                ? 'rounded-r-lg'
                                : '',
                              'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10',
                            ]">
              <span>{{ tab.name }}</span>
              <span aria-hidden="true" :class="[
                tab.current
                  ? 'bg-blue-500'
                  : 'bg-transparent',
                'absolute inset-x-0 bottom-0 h-0.5',
              ]" />
            </router-link>
          </nav>
        </div>
      </div>

      <div class="text-2xl mt-4 mb-8 font-bold">Jots</div>
      <div class="flex justify-end mb-4">
      <button @click="setJotsViewType('list')" class="mr-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7" />
        </svg>
      </button>
      <button @click="setJotsViewType('grid')">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h7v7H3zM14 3h7v7h-7zM3 14h7v7H3zM14 14h7v7h-7z" />
        </svg>
      </button>
    </div>
      
      <div v-if="jotViewType=='list'" class="grid sm:grid-cols-1 md:grid-cols-1">
        <div v-for="jot in this.listItem.relationships.jots" :key="jot.id"
          class="bg-white my-1 px-4 shadow sm:p-6 sm:rounded-lg">
          <article :aria-labelledby="'pod-title-' + jot.id">
            <router-link :to="{ path: '/jot/' + jot.attributes.slug }">
              <div class="flex">
                <div class="w-1/5">
                  <div class="
                    aspect-w-4 aspect-h-3
                    w-full
                    bg-no-repeat bg-center bg-cover
                    rounded-lg
                  " :style="`background-image: url(${jot.attributes.previewImage.url})`"></div>
                </div>
                <div class="w-3/4 ml-2">
                  <div class="mt-2">
                    <!-- <div class="text-gray-500">
                      {{ jot.relationships.pod.attributes.name }}
                    </div> -->
                    <div :id="'pod-title-' + jot.id" class="text-sm text-gray-900 font-light">
                      <strong>{{ jot.attributes.name }}</strong>
                      <p v-html="substringContent(jot.attributes.content)"></p>
                    </div>
                  </div>
                </div>

                <div class="flex items-end justify-end tooltip" v-if="jot?.attributes?.content_type">
                  
                  <svg v-if="jot?.attributes?.content_type === 'video'" xmlns="http://www.w3.org/2000/svg" width="20"
                      height="20" viewBox="0 0 90 90">
                      <path
                      d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0zM62.251 46.633L37.789 60.756c-1.258 0.726-2.829-0.181-2.829-1.633V30.877c0-1.452 1.572-2.36 2.829-1.634l24.461 14.123C63.508 44.092 63.508 45.907 62.251 46.633z"
                      fill="#000" />
                  </svg>
                  <svg v-if="jot?.attributes?.content_type === 'story'" xmlns="http://www.w3.org/2000/svg" width="20"
                    height="20" viewBox="0 0 80 80">
                    <path
                      d="M77.474 17.28L61.526 1.332C60.668 0.473 59.525 0 58.311 0H15.742c-2.508 0-4.548 2.04-4.548 4.548v80.904c0 2.508 2.04 4.548 4.548 4.548h58.516c2.508 0 4.549-2.04 4.549-4.548V20.496C78.807 19.281 78.333 18.138 77.474 17.28zM61.073 5.121l12.611 12.612H62.35c-0.704 0-1.276-0.573-1.276-1.277V5.121zM15.742 3h42.332v13.456c0 2.358 1.918 4.277 4.276 4.277h13.457v33.2H14.194V4.548C14.194 3.694 14.888 3 15.742 3zm58.516 80H15.742c-0.854 0-1.548-0.694-1.548-1.548V56.934h61.613v28.519C75.807 86.306 75.112 87 74.258 87z"
                      fill="#000" />
                    <path
                      d="M34.604 62.687h-8.816c-0.829 0-1.5 0.672-1.5 1.5s0.671 1.5 1.5 1.5h2.908v15.164c0 0.828 0.671 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.908c0.829 0 1.5-0.672 1.5-1.5S35.432 62.687 34.604 62.687z"
                      fill="#000" />
                    <path
                      d="M46.69 72.519l4.021-7.599c0.387-0.731 0.107-1.64-0.625-2.027c-0.729-0.385-1.639-0.107-2.027 0.625l-3.065 5.794l-3.066-5.794c-0.388-0.732-1.294-1.012-2.027-0.625c-0.732 0.388-1.012 1.296-0.624 2.027l4.02 7.599l-4.02 7.599c-0.388 0.731-0.108 1.64 0.624 2.027c0.224 0.118 0.464 0.174 0.7 0.174c0.538 0 1.058-0.29 1.327-0.799l3.066-5.794l3.065 5.794c0.27 0.509 0.789 0.799 1.327 0.799c0.237 0 0.477-0.056 0.7-0.174c0.732-0.388 1.012-1.296 0.625-2.027L46.69 72.519z"
                      fill="#000" />
                    <path
                      d="M64.212 62.687h-8.815c-0.828 0-1.5 0.672-1.5 1.5s0.672 1.5 1.5 1.5h2.908v15.164c0 0.828 0.672 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.907c0.828 0 1.5-0.672 1.5-1.5S65.04 62.687 64.212 62.687z"
                      fill="#000" />
                  </svg>

                  <svg v-if="jot?.attributes?.content_type === 'audio'" xmlns="http://www.w3.org/2000/svg" width="20"
                    height="20" viewBox="0 0 80 80">
                    <path
                      d="M64.293 90c-0.493 0-0.979-0.183-1.356-0.53L35.986 64.59H6.526c-1.105 0-2-0.896-2-2V27.41c0-1.105 0.896-2 2-2h29.46L62.937 0.53c0.585-0.539 1.433-0.679 2.158-0.362C65.823 0.486 66.293 1.205 66.293 2V88c0 0.794-0.47 1.514-1.198 1.832C64.837 89.946 64.565 90 64.293 90z"
                      fill="#000" />
                    <path
                      d="M76.42 73.908c-0.467 0-0.937-0.163-1.315-0.494c-0.832-0.728-0.916-1.991-0.189-2.822C78.9 66.037 81.474 55.991 81.474 45S78.9 23.963 74.915 19.408c-0.727-0.832-0.643-2.095 0.189-2.822c0.83-0.728 2.095-0.643 2.822 0.188c4.655 5.323 7.547 16.138 7.547 28.225c0 12.087-2.892 22.903-7.547 28.225C77.532 73.677 76.977 73.908 76.42 73.908z"
                      fill="#000" />
                    <path
                      d="M72.02 62.623c-0.321 0-0.647-0.077-0.949-0.241c-0.972-0.525-1.333-1.739-0.808-2.71c1.851-3.421 2.955-8.906 2.955-14.672c0-5.765-1.104-11.25-2.955-14.672c-0.525-0.972-0.164-2.186 0.808-2.711c0.969-0.526 2.186-0.165 2.71 0.808c2.185 4.039 3.438 10.08 3.438 16.575c0 6.495-1.253 12.536-3.438 16.574C73.419 62.243 72.731 62.623 72.02 62.623z"
                      fill="#000" />
                  </svg>
                  <Tooltip :content="jot?.attributes?.content_type"/>
              </div>

              </div>
            </router-link>
          </article>
        </div>
      </div>

      <div v-if="jotViewType=='grid'" class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
        <div v-for="jot in this.listItem.relationships.jots" :key="jot.id"
          class="bg-white my-2 px-4 shadow sm:p-6 sm:rounded-lg">
          <article :aria-labelledby="'pod-title-' + jot.id">
            <router-link :to="{ path: '/jot/' + jot.attributes.slug }">

              <div>
                <div class="
                                        aspect-w-4 aspect-h-3
                                        w-full
                                        bg-no-repeat bg-center bg-cover
                                        rounded-lg
                                    " :style="`background-image: url(${jot.attributes.previewImage.url})`"></div>
              </div>
              <div class="mt-2">
                <div class="text-gray-500">
                  {{ jot.relationships.pod.attributes.name }}
                </div>
                <div :id="'pod-title-' + jot.id" class="text-sm text-gray-900 font-light">
                  {{ jot.attributes.name }}
                </div>
              </div>

              <div class="flex items-end justify-end tooltip" v-if="jot?.attributes?.content_type">
                  
                  <svg v-if="jot?.attributes?.content_type === 'video'" xmlns="http://www.w3.org/2000/svg" width="20"
                      height="20" viewBox="0 0 90 90">
                      <path
                      d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0zM62.251 46.633L37.789 60.756c-1.258 0.726-2.829-0.181-2.829-1.633V30.877c0-1.452 1.572-2.36 2.829-1.634l24.461 14.123C63.508 44.092 63.508 45.907 62.251 46.633z"
                      fill="#000" />
                  </svg>
                  <svg v-if="jot?.attributes?.content_type === 'story'" xmlns="http://www.w3.org/2000/svg" width="20"
                    height="20" viewBox="0 0 80 80">
                    <path
                      d="M77.474 17.28L61.526 1.332C60.668 0.473 59.525 0 58.311 0H15.742c-2.508 0-4.548 2.04-4.548 4.548v80.904c0 2.508 2.04 4.548 4.548 4.548h58.516c2.508 0 4.549-2.04 4.549-4.548V20.496C78.807 19.281 78.333 18.138 77.474 17.28zM61.073 5.121l12.611 12.612H62.35c-0.704 0-1.276-0.573-1.276-1.277V5.121zM15.742 3h42.332v13.456c0 2.358 1.918 4.277 4.276 4.277h13.457v33.2H14.194V4.548C14.194 3.694 14.888 3 15.742 3zm58.516 80H15.742c-0.854 0-1.548-0.694-1.548-1.548V56.934h61.613v28.519C75.807 86.306 75.112 87 74.258 87z"
                      fill="#000" />
                    <path
                      d="M34.604 62.687h-8.816c-0.829 0-1.5 0.672-1.5 1.5s0.671 1.5 1.5 1.5h2.908v15.164c0 0.828 0.671 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.908c0.829 0 1.5-0.672 1.5-1.5S35.432 62.687 34.604 62.687z"
                      fill="#000" />
                    <path
                      d="M46.69 72.519l4.021-7.599c0.387-0.731 0.107-1.64-0.625-2.027c-0.729-0.385-1.639-0.107-2.027 0.625l-3.065 5.794l-3.066-5.794c-0.388-0.732-1.294-1.012-2.027-0.625c-0.732 0.388-1.012 1.296-0.624 2.027l4.02 7.599l-4.02 7.599c-0.388 0.731-0.108 1.64 0.624 2.027c0.224 0.118 0.464 0.174 0.7 0.174c0.538 0 1.058-0.29 1.327-0.799l3.066-5.794l3.065 5.794c0.27 0.509 0.789 0.799 1.327 0.799c0.237 0 0.477-0.056 0.7-0.174c0.732-0.388 1.012-1.296 0.625-2.027L46.69 72.519z"
                      fill="#000" />
                    <path
                      d="M64.212 62.687h-8.815c-0.828 0-1.5 0.672-1.5 1.5s0.672 1.5 1.5 1.5h2.908v15.164c0 0.828 0.672 1.5 1.5 1.5s1.5-0.672 1.5-1.5V65.687h2.907c0.828 0 1.5-0.672 1.5-1.5S65.04 62.687 64.212 62.687z"
                      fill="#000" />
                  </svg>

                  <svg v-if="jot?.attributes?.content_type === 'audio'" xmlns="http://www.w3.org/2000/svg" width="20"
                    height="20" viewBox="0 0 80 80">
                    <path
                      d="M64.293 90c-0.493 0-0.979-0.183-1.356-0.53L35.986 64.59H6.526c-1.105 0-2-0.896-2-2V27.41c0-1.105 0.896-2 2-2h29.46L62.937 0.53c0.585-0.539 1.433-0.679 2.158-0.362C65.823 0.486 66.293 1.205 66.293 2V88c0 0.794-0.47 1.514-1.198 1.832C64.837 89.946 64.565 90 64.293 90z"
                      fill="#000" />
                    <path
                      d="M76.42 73.908c-0.467 0-0.937-0.163-1.315-0.494c-0.832-0.728-0.916-1.991-0.189-2.822C78.9 66.037 81.474 55.991 81.474 45S78.9 23.963 74.915 19.408c-0.727-0.832-0.643-2.095 0.189-2.822c0.83-0.728 2.095-0.643 2.822 0.188c4.655 5.323 7.547 16.138 7.547 28.225c0 12.087-2.892 22.903-7.547 28.225C77.532 73.677 76.977 73.908 76.42 73.908z"
                      fill="#000" />
                    <path
                      d="M72.02 62.623c-0.321 0-0.647-0.077-0.949-0.241c-0.972-0.525-1.333-1.739-0.808-2.71c1.851-3.421 2.955-8.906 2.955-14.672c0-5.765-1.104-11.25-2.955-14.672c-0.525-0.972-0.164-2.186 0.808-2.711c0.969-0.526 2.186-0.165 2.71 0.808c2.185 4.039 3.438 10.08 3.438 16.575c0 6.495-1.253 12.536-3.438 16.574C73.419 62.243 72.731 62.623 72.02 62.623z"
                      fill="#000" />
                  </svg>
                  <Tooltip :content="jot?.attributes?.content_type"/>
              </div>
            </router-link>
          </article>
        </div>
      </div>

      <div v-if="this.listItem.relationships.jots.length == 0" class="text-center p-6">
        No jots available.

        <router-link :to="{ path: '/jot/create' }" class="
                        inline-flex
                        items-center
                        justify-center
                        px-4
                        py-3
                        border border-transparent
                        text-sm
                        font-medium
                        rounded-md
                        shadow-sm
                        text-white
                        bg-blue-600
                        hover:bg-blue-600
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-offset-gray-100
                        focus:ring-blue-500
                    ">
          Create a Jot
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { GetListItemById } from "@/services/list-item.service";
import SubHeader from "@/components/SubHeader.vue";
import Tags from "@/components/Tags.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import { isUserBlocked } from "@/utils";
import Tooltip from "@/components/Tooltip.vue";
@Options({
  components: {
    SubHeader,
    Tags,
    BreadCrumbs,
    Tooltip
  },
  data() {
    return {
      id: this.$route.params.id,
      listItem: null,
      tabs: [
        // {
        //     name: "Trending Jots",
        //     current: true,
        //     tag: "TRENDING",
        // },
        // { name: "New Jots", current: false, tag: "NEW" },
        // { name: "Jots", current: false, tag: "PERSONAL" },
      ],
      sectionsBreadCrumb: [],
      backStep: -1,
      showTooltip: false,
      jotViewType: 'list'
    };
  },
  mounted() {
    const tag = this.$route.query.tag;
    if (!tag) {
      this.$router.push(`/list-item/${this.id}/?tag=TRENDING`);
    } else {
      this.tabs.forEach((tab) => {
        tab.current = tab.tag == tag;
      });
    }
    this.fetchListItem();
  },
  methods: {
    fetchListItem() {
      GetListItemById(this.id).then((resp) => {

        if (resp?.data?.id) {
          const jotLocalListItem = {
            list_item_slug: resp.data.id
          };
          localStorage.setItem("jotLocalListItem", JSON.stringify(jotLocalListItem));
        }
        if (resp.data.relationships.jots.length > 0) {
          resp.data.relationships.jots = resp.data.relationships.jots
            .filter(
              (jot) => !isUserBlocked(jot.relationships.author.id)
            )
            .map((jot) => {
              let previewImage = jot.attributes.images[0];
              if (!previewImage) {
                if (jot.attributes.content_type === "story") {
                  previewImage = require("@/assets/images/jot-text.png");
                } else if (
                  jot.attributes.content_type === "video"
                ) {
                  previewImage = require("@/assets/images/jot-video.png");
                } else if (
                  jot.attributes.content_type === "audio"
                ) {
                  previewImage = require("@/assets/images/jot-audio.png");
                }
              }

              jot["attributes"] = {
                ...jot["attributes"],
                previewImage,
              };
              return jot;
            });

        }

        this.listItem = resp.data;

        this.recursiveSectionNames([
          this.listItem.relationships.section,
        ]);
      });
    },
    recursiveSectionNames(sections) {
      sections.forEach((section) => {
        this.sectionsBreadCrumb.push({
          id: section.id,
          title: section.attributes.name,
          url: "/section/" + section.attributes.slug,
        });

        if (sections?.relationships?.children) {
          this.recursiveSectionNames(sections.relationships.children);
        }
      });
    },
    substringContent(content) {
      if (content.length > 65) {
        return content.substring(0, 65) + '...';
      }
      return content;
    },
    setJotsViewType(viewType){
      this.jotViewType = viewType;
    },
  },
  watch: {
    "$route.query.tag": function (tag) {
      if (tag) {
        this.tabs.forEach((tab) => {
          tab.current = tab.tag == tag;
        });
      }
      this.backStep = this.backStep - 1;
    },
    "$route.params.id": function (id) {
      this.id = id;
      this.fetchListItem();
    },
  },
  metaInfo() {
    return {
      title: this.listItem?.attributes.name,
      meta: [
        {
          name: "description",
          content: this.listItem?.attributes.article,
        },
      ],
    };
  },
})
export default class ListItem extends Vue { }
</script>
<style>
</style>
