<template>
    <div v-if="this.jot">
        <div class="bg-white mb-10 px-4 shadow sm:p-6 sm:rounded-lg">
            <SubHeader :title="this.jot.attributes.name" class="mb-6"
                :imgSrc="this.jot.relationships?.pod?.attributes?.topic_logo_image" />

            <BreadCrumbs :crumbs="[
                {
                    id: this.jot.relationships.container.id,
                    title: this.jot.relationships.container.attributes.name,
                    url:
                        '/container/' + this.jot.relationships.container.id,
                },
                {
                    id: this.jot.relationships.pod.id,
                    title: this.jot.relationships.pod.attributes.name,
                    url: '/pod/' + this.jot.relationships.pod.id,
                },
                {
                    id: this.jot.relationships.list_item.id,
                    title: this.jot.relationships.list_item.attributes.name,
                    url:
                        '/list-item/' + this.jot.relationships.list_item.id,
                },
                ...this.sectionsBreadCrumb,
            ]" />

            <div class="text-3xl mb-2 font-bold">
                {{ this.jot.attributes.name }}
            </div>

            <div class="flex gap-3 mb-2 items-center">
                <router-link :to="{ path: '/author/' + this.author.id }">
                    <img class="h-8 w-8 rounded-full" :src="this.author.attributes.avatar" alt="User author" />
                </router-link>
                <router-link :to="{ path: '/author/' + this.author.id }">
                    {{ this.author?.attributes.name }}
                </router-link>
                <div>·</div>
                <div class="">
                    {{ this.createdAtFormatted }}
                </div>
                <div>·</div>
                <div class="cursor-pointer" v-on:click="this.toggleLike">
                    <svg v-if="!this.jot.attributes.has_liked" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor"
                        class="text-gray-400 flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-blue-500">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z">
                        </path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"></path>
                    </svg>
                    <svg v-if="this.jot.attributes.has_liked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                        stroke="currentColor" class="text-gray-400 flex-shrink-0 -ml-1 mr-3 h-6 w-6 text-blue-500">
                        <path stroke-linecap="round" fill="rgba(10, 74, 122)" stroke-linejoin="round" stroke-width="2"
                            d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z">
                        </path>
                        <path stroke-linecap="round" fill="none" stroke-linejoin="round" stroke-width="2"
                            d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"></path>
                    </svg>
                    <!-- <img src="@/assets/images/heart-outline.svg" v-if="!this.jot.attributes.has_liked" />
                    <img src="@/assets/images/heart-solid.svg" v-if="this.jot.attributes.has_liked" /> -->
                </div>
                <ShareModal :jotTitle="jotTitle" />

                <div class="self-end flex-1 text-right" v-if="this.author?.id != this.currentUser?.id">
                    <button class="
                            text-white
                            bg-blue-1000
                            rounded-full
                            py-1
                            px-3
                            focus:outline-none
                        " v-on:click="this.toggleFollow">
                        <span v-if="!this.author?.attributes.is_following">
                            Follow</span>
                        <span v-if="this.author?.attributes.is_following">
                            Unfollow</span>
                    </button>
                </div>
            </div>


            <div class="mb-6" v-if="jot.attributes.content_type == 'story'">
                <div class="grid grid-cols-3 gap-4">
                    <div v-for="(image,index) in jot.attributes.images" :key="image.name"
                        class="flex flex-col items-center justify-center mt-4">
                        <div class="relative">
                            <div class="w-32 h-32 bg-gray-200 flex items-center justify-center overflow-hidden cursor-pointer"
                                @click="openPopup(index)">
                                <img :src="image.url" :alt="image.name" class="object-cover w-full h-full" />
                            </div>
                            <p class="mt-2 text-center">{{ image.order_column }}</p>
                        </div>
                    </div>
                </div>

                <!-- Popup Component -->
                <div v-if="popupOpen" class="fixed inset-0 flex items-center justify-center bg-gray-200 bg-opacity-50 z-50">
                    <div class="bg-white p-4 rounded-lg shadow-lg max-w-3xl">
                        <PopupSlider v-if="this.jot?.attributes?.images.length > 0"
                            :slides="this.jot.attributes.images" :selectedSlide="this.selectedSlide" @closePopup="closePopup" />
                    </div>
                </div>
            </div>

            <div class="mb-6" v-if="this.jot.attributes.content_type == 'video'">
                <!-- <youtube :video-id="this.jot.attributes.content_url.substring(17)" :player-vars="playerVars"
                    ref="youtube" @playing="playing"></youtube> -->
                <!-- <YoutubeVue3 ref="youtube" :videoid="this.jot.attributes.content_url.substring(17)" :autoplay="1" :loop="1"
                    width="100%" :height="320" @ended="onEnded" @paused="onPaused" @played="onPlayed" /> -->
                <!-- <YouTube id="youtube-container" :src="this.jot.attributes.content_url" @ready="onReady" ref="youtube" -->
                <!-- class="w-full important" /> -->
                <iframe id="youtube-vue-player-kKWQ8FZKa" frameborder="0" allowfullscreen=""
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    width="100%" height="320"
                    :src="`https://www.youtube.com/embed/${this.jot.attributes.content_url.substring(17)}?autoplay=1&amp;loop=1&amp;controls=0&amp;modestbranding=1&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fjoticle.com&amp;widgetid=4`"
                    data-gtm-yt-inspected-8="true"></iframe>
            </div>

            <div v-html="this.jot.attributes.content" class="text-content"></div>
        </div>
    </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { GetJotById, LikeJot, UnLikeJot } from "@/services/jot.service";
import SubHeader from "@/components/SubHeader.vue";
import Tags from "@/components/Tags.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import * as moment from "moment";
import {
    FollowUser,
    GetCurrentUser,
    UnfollowUser,
} from "@/services/user.service";
import { isUserBlocked } from "@/utils";
import { useMeta } from "vue-meta";
import ShareModal from "@/components/ShareModal";
import PopupSlider from "@/components/PopupSlider.vue";
@Options({
    components: {
        SubHeader,
        Tags,
        BreadCrumbs,
        ShareModal,
        PopupSlider,
    },
    data() {
        return {
            id: this.$route.params.id,
            jot: null,
            createdAtFormatted: "",
            sectionsBreadCrumb: [],
            author: null,
            currentUser: null,
            playerVars: {
                autoplay: 1
            },
            jotTitle: '',
            jotDescription: '',
            jotImageUrl: '',
            // Popup images
            popupOpen: false,
            selectedSlide:0,
        };
    },
    computed: {
        player() {
            return this.$refs.youtube.player;
        }
    },
    mounted() {
        this.fetchJot();
    },
    methods: {
        openPopup(index) {
            this.popupOpen = true;
            this.selectedSlide=index;
        },
        closePopup() {
            this.popupOpen = false;
        },
        layVideo() {
            this.player.playVideo();
        },
        playing() {
            console.log("we are watching!!!");
        },
        formatExternalLink(link) {
            link.target = "_blank";
            link.text = "External Link";
            link.classList.add("red");
            const svg = document.createElement("div");
            svg.innerHTML = `<span class="externalLink"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg></span>`;
            link.innerHTML = link.innerHTML + svg.innerHTML;
        },
        fetchJot() {
            this.sectionsBreadCrumb = [];
            GetJotById(this.id).then(async (resp) => {
                if (isUserBlocked(resp.data.relationships.author.id)) {
                    return;
                }
                this.jot = resp.data;
                this.jotTitle = resp.data.attributes.name;
                this.jotDescription = '#test';
                this.jotImageUrl = resp.data.attributes.avatar;


                this.createdAtFormatted = moment(
                    this.jot.attributes.created_at
                ).format("MMMM D");

                const jotElement = document.createElement("div");
                if (this.jot.attributes.type === "pdf") {
                    const linkElement = document.createElement("a");
                    linkElement.href = this.jot.attributes.content;
                    this.formatExternalLink(linkElement);
                    jotElement.appendChild(linkElement);
                } else {
                    this.jot.attributes.content =
                        this.jot.attributes.content.replace(
                            /<\/p>/g,
                            "</p><br />"
                        );
                    jotElement.innerHTML = this.jot.attributes.content;
                    const links = jotElement.getElementsByTagName("a");

                    for (var i = 0; i < links.length; i++) {
                        if (
                            links[i].text.includes("joticle.com") ||
                            links[i].href.includes("joticle.com")
                        ) {
                            links[i].target = "_self";
                            links[i].classList.add("light-blue");
                            if (links[i].text.includes("http")) {
                                const splitted = links[i]
                                    .toString()
                                    .split("//");
                                links[i].text = splitted[1];
                            }
                        } else {
                            this.formatExternalLink(links[i]);
                        }
                    }
                }

                this.jot.attributes.content = jotElement.innerHTML.toString();
                this.recursiveSectionNames([this.jot.relationships.section]);
                this.author = this.jot.relationships.author;
                this.currentUser = (await GetCurrentUser()).data;
            });
        },
        onReady() {
            this.$refs.youtube.playVideo();
        },
        recursiveSectionNames(sections) {
            sections.forEach((section) => {
                this.sectionsBreadCrumb.push({
                    id: section.id,
                    title: section.attributes.name,
                    url: "/section/" + section.id,
                });

                if (sections?.relationships?.children) {
                    this.recursiveSectionNames(sections.relationships.children);
                }
            });
        },
        async toggleFollow() {
            if (this.author.id == this.currentUser.id) {
                return;
            }
            if (this.author.attributes.is_following) {
                await UnfollowUser(this.author.id);
            } else {
                await FollowUser(this.author.id);
            }

            this.fetchJot();
        },
        async toggleLike() {
            if (this.jot.attributes.has_liked) {
                await UnLikeJot(this.jot.id);
            } else {
                await LikeJot(this.jot.id);
            }

            this.fetchJot();
        },
        applyConfig() {
            this.play = Object.assign(this.play, this.temp)
        },
        playCurrentVideo() {
            this.$refs.youtube.player.playVideo();
        },
        stopCurrentVideo() {
            this.$refs.youtube.player.stopVideo();
        },
        pauseCurrentVideo() {
            this.$refs.youtube.player.pauseVideo();
        },
        onEnded() {
            console.log("## OnEnded")
        },
        onPaused() {
            console.log("## OnPaused")
        },
        onPlayed() {
            console.log("## OnPlayed")
        },
    },
    metaInfo() {
        return {
            title: this.jot?.attributes.name,
            meta: [
                {
                    property: 'og:title',
                    content: this.jotTitle
                },
                {
                    property: 'og:description',
                    content: '#feedtest'
                },
                {
                    property: 'og:image',
                    content: '/img/joticle-logo.75153414.png'
                },
                {
                    name: 'description', // or property: 'og:description' if you want it to be Open Graph
                    content: this.sectionsBreadCrumb.map(x => x.title).join(', ')
                }
            ]
        };
    },
})
export default class Jot extends Vue { }
</script>

<style>
.w-full.important {
    width: 100% !important;
}

.externalLink>svg {
    display: inline;
    width: 15px;
    height: 15px;
    color: #52525b;
    margin-left: 1px;
}

.text-content * {
    background-color: transparent !important;
    color: var(--black);
}

.red {
    color: rgb(230, 68, 68);
}

.light-blue {
    color: rgb(114, 114, 223);
}
</style>