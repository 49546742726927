import { HttpClient } from "@/core/http-client";
import { ISection } from "@/interfaces/section.interface";

export const GetSections = () => {
    return new Promise((resolve, reject) => {
        HttpClient()
            .get<ISection[]>("/api/v1/sections")
            .then((resp) => {
                resp.data = resp.data.filter((x) => x.attributes.is_active);
                resolve(resp);
            })
            .catch((err) => {
                reject(err);
            });
    });
};

export const GetSectionById = (id: number) => {
    return HttpClient().get<ISection>("/api/v1/sections/" + id, {
        params: {
            include: "list_items,children,pod,container",
        },
    });
};
