<template>
    <TransitionRoot as="template" :show="this.localShow">
        <Dialog
            as="div"
            static
            class="fixed z-10 inset-0 overflow-y-auto"
            :show="this.localShow"
        >
            <div
                class="
                    flex
                    items-end
                    justify-center
                    min-h-screen
                    pt-4
                    px-4
                    pb-20
                    text-center
                    sm:block sm:p-0
                "
            >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay
                        class="
                            fixed
                            inset-0
                            bg-gray-500 bg-opacity-75
                            transition-opacity
                        "
                        @click="this.closeIt"
                    />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span
                    class="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="
                            inline-block
                            align-bottom
                            bg-white
                            rounded-lg
                            px-4
                            pt-5
                            pb-4
                            text-left
                            overflow-hidden
                            shadow-xl
                            transform
                            transition-all
                            sm:my-8
                            sm:align-middle
                            sm:max-w-4xl
                            sm:w-full
                            sm:p-6
                        "
                    >
                        <div class="absolute top-0 right-0 pt-4 pr-4">
                            <button
                                type="button"
                                class="
                                    bg-white
                                    rounded-md
                                    text-gray-400
                                    hover:text-gray-500
                                    focus:outline-none
                                "
                                @click="this.closeIt"
                            >
                                <span class="sr-only">Close</span>
                                <XIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div class="w-full">
                            <GlossaryDetail
                                :podId="this.podId"
                                :glossaryId="this.glossaryId"
                            />
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Vue, Options } from "vue-class-component";
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { ExclamationIcon, XIcon } from "@heroicons/vue/outline";
import GlossaryDetail from "./GlossaryDetail.vue";

@Options({
    props: ["show", "podId", "glossaryId"],
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
        XIcon,
        GlossaryDetail,
    },
    data() {
        return {
            localShow: false,
        };
    },
    methods: {
        closeIt() {
            this.localShow = false;
            this.$emit("onClose");
        },
    },
    watch: {
        show: function (show) {
            this.localShow = show;
        },
    },
})
export default class Modal extends Vue {}
</script>
